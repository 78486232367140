import {ref} from 'vue';
import productService from '@/service/product.js';

export const platformType = ref(null);

export const updatePlatformType = function () {
  return productService.getPlatformType().then(result => {
    if (result.platformType === 'saas') {
      platformType.value = 'saas';
    } else {
      platformType.value = 'standard';
    }
  });
};
import {ref} from 'vue';
import productService from '../service/product';

const develop = 'development' === import.meta.env.VITE_NODE_ENV;

let host = window.location.href;
host = host.substring(0, host.indexOf('/', 8));

const server = host + '/configuration/v2/api-docs';

const wxSysCode = 'kfConfiguration';

const contextPath = '/designer/';

let servicePath = ref('/cloud');

let sourceUrl=ref(null)

const updateServicePath = function () {
  productService.getContextPath().then(res => {
    if (res) {
      servicePath.value = res;
    }
  });
};

export const getSourceUrl=function (){
  let url=null;
  if(sourceUrl.value){
    url=sourceUrl.value
  }else{
    url=localStorage.getItem('KC_SOURCEURL')
  }
  return url
}
export default {
  develop,
  server,
  wxSysCode,
  contextPath,
  servicePath,
  updateServicePath,
  sourceUrl
};

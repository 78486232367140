const zh_CN = {
  language: {
    zh_CN: '简体中文',
    zh_TW: '繁体中文',
    en: 'English',
  },
  'Search': '搜索',
  'TronV4': '工创4',
  'TronMarket':'工创市场',
  'UsernameCannotBeEmpty': '用户名不能为空',
  'AreYouSureYouWantToSwitchLanguagesAndReload':'确定要切换语言并重新加载吗？',
  'None': '无',
  '#MinutesAgo': '分钟之前',
  '&Send': '并发送',
  '1Camera': '1画面',
  '1Day': '1天',
  '2dComponent': '二维组件',
  '2dConfigurationTool': '二维组态工具',
  '2dEditingMode': '二维编辑模式',
  '30Days': '30天',
  '3d': '三维',
  '3dComponent': '三维组件',
  '3dConfigurationTool': '三维组态工具',
  '3dDesigner': '三维设计器',
  '3dDeviceConnectionNodes': '三维设备连接节点',
  '3dEditingMode': '三维编辑模式',
  '3dScene': '三维场景',
  '3dSpace': '三维空间',
  '7Days': '7天',
  'About': '关于',
  'AboutUs': '关于我们',
  'Absolutely': '绝对',
  'AbsolutePosition': '绝对定位',
  'Access': '接入',
  'AccessHasBeenRestricted': '访问已被限制',
  'AccessToData': '获取数据',
  'Account': '账号',
  'AccountApplication': '账号申请',
  'AccountName': '账户名',
  'AccountPassword': '账户密码',
  'AccountStatus': '账户状态',
  'AChangeOfLayoutWouldResultInAPartialDeformationOfThe': '改变布局将导致部分组件变形，该操作无法恢复，请确认是否继续？',
  'Actions': '动作',
  'ActiveStatus': '运行状态',
  'Add': '新增',
  'AddAll': '全部添加',
  'AddCurrentCategory': '添加当前分类',
  'AddDataSource': '添加数据源',
  'Added': '已添加',
  'AddFieldsAsCreated': '添加字段为已创建',
  'AddFolder': '添加文件夹',
  'AddGroupToObjectModel': '添加组到物模型',
  'AddModel': '添加模型',
  'AddNewItem': '新增枚举行',
  'AddPage': '新增页面',
  'AddRequestQueue': '加入请求队列',
  'AddToCurrentCategory': '添加到当前分类',
  'Administrator': '管理员',
  'AfterConfirmation': '确定后',
  'AfterLoadingImageHide': '加载画面隐藏后',
  'AirConditioning': '空调',
  'AirConditioning001': '空调001',
  'AirconditioningEquipmentCategory': '空调设备类别',
  'AirconditioningSystems': '空调系统',
  'AirSensor5': '空气传感器5',
  'AirSensors': '空气传感器',
  'AirSensors1': '空气传感器1',
  'Alarm': '告警',
  'Alarm2': '警告',
  'AlarmBubbles': '告警气泡',
  'AlertList': '告警列表',
  'AlignBothEnds': '两端对齐',
  'AlignHorizontally': '水平对齐',
  'AlignItToTheLeft': '靠左对齐',
  'Alignment': '对齐',
  'AlignUp': '上对齐',
  'All': '全部',
  'All2': '所有人',
  'AllAboard': '全部上架',
  'AllowUsersToClick': '允许用户点击',
  'AllowUsersToExit': '允许用户退出',
  'AllPublishefdHistoricalData': '全部的发布历史数据',
  'AllRemovedFromAboard': '全部下架',
  'Already': '已',
  'AlreadyLoggedInOnADifferentBrowser/window': '或者是您在其他浏览器窗口登录了系统',
  'AlreadyOnTheSystem': '已经登出系统',
  'AlreadyRegistered': '已经被注册了',
  'AlreadyTheBottomLayer': '已经是最下面的图层',
  'AlreadyTheTopLayer': '已经是最上面的图层',
  'AModifiedModelWouldRemoveRawAreaDataAndWouldAModified': '修改模式会清除原始区域数据，是否确认修改现有区域模式?',
  'Analysis': '解析',
  'AnchorPosition': '锚点位置',
  'And': '和',
  'AndInitializesTheCameraPositionAs': '并初始化机位为',
  'AndToTheTop': '并置顶',
  'ANewcomersGuide': '新手引导',
  'Angle': '角度',
  'AnHourAgo': '小时之前',
  'Animation': '动画',
  'AnimationCycle': '动画循环',
  'AnimationTime': '动画时长',
  'Anomalous': '异常',
  'ApiInterfaceDocument': 'API接口文档',
  'Appearances': '方头',
  'ApplicationNotSupported': '不支持应用',
  'Applications': '申请',
  'ApplyMapRelationship': '应用映射关系',
  'AreaOfThePlatform': '地台范围',
  'AreaTime': '区域为时',
  'AreChangesToExistingRegionalModelsConfirmed': '是否确认修改现有区域模式?',
  'AreYouAlreadyLoggedIn': '是否登录',
  'AreYouSure': '确定删除?',
  'AreYouSureItsDeleted': '是否确定删除?',
  'AreYouSureYouWantToDeleteIt': '确定删除',
  'AreYouSureYouWantToDeleteItDeleteItAndFindItInTheWastebin': '是否确定删除?删除后可以在回收站中找到，我们将为您保留',
  'AreYouSureYouWantToDeleteItYouCanFindItInTheTrashWhen': '是否确定删除?删除后可以在回收站中找到',
  'AreYouSureYouWantToDeleteThatState': '确定删除该状态吗',
  'AreYouSureYouWantToDeleteThatState1': '确定删除该状态吗？',
  'AreYouSureYouWantToDeleteTheEvent': '确认删除该事件吗',
  'AreYouSureYouWantToDeleteThePerspective': '确定删除视角吗?',
  'AreYouSureYouWantToDeleteThePerspective1': '确定删除视角吗',
  'AreYouSureYouWantToDeleteTheScene': '确定删除场景吗?',
  'AreYouSureYouWantToDeleteTheScene1': '确定删除场景吗',
  'AreYouSureYouWantToDeleteThis': '是否确定删除此内容',
  'ArrangeLayout': '排列布局',
  'Array': '数组',
  'As': '为',
  'Ash': '浅灰',
  'AssociatedDevices': '关联设备',
  'Attribute2': '属性2',
  'Audio': '音频',
  'Author': '作者',
  'Auto': '自动',
  'Autoadapt': '自动调整',
  'AutoConnection': '自动连接',
  'Autocreate': '自动创建',
  'AutoGeneratePartialEnd': '自动生成部分结束',
  'AutoGeneratePartStart': '自动生成部分开始',
  'AutoLayout': '自动布局',
  'AutomaticRowWrapping': '自动换行',
  'AutoPlay': '自动播放',
  'Autosave': '自动保存',
  'Available': '找到可用的',
  'AvailableForViewingUnderThisItem': '可至该项目下进行查看',
  'AxisLength': '轴长度',
  'Back': '返回',
  'Background': '背景',
  'BackgroundColour': '背景颜色',
  'BackgroundDisplay': '背景显示',
  'BackgroundPictures': '背景图片',
  'BackgroundShadow': '背景阴影',
  'BackstageProcessing': '后台处理',
  'BackToTheFrontPage': '回到首页',
  'Backwards': '向后',
  'Balance': '平衡',
  'BalanceMode': '平衡模式',
  'Baseboard': '底板',
  'BaseboardColour': '底板颜色',
  'BaseProperties': '基础属性',
  'Because': '由于',
  'Beginning': '开头',
  'Beijing': '北京',
  'BeijingKingfisherTechnologyCoLtd': '北京翠鸟智擎科技有限公司',
  'BestPerformance': '性能最优',
  'Between': '之间',
  'BeyondScroll': '超出滚动',
  'BigNumber': '大号',
  'BindingEquipment': '绑定的设备',
  'Biscuits': '饼图',
  'Black': '黑色',
  'BlankEdges': '描边粗细',
  'Blinking': '闪烁',
  'Blue': '蓝色',
  'Blue01': '蓝色01',
  'Blue02': '蓝色02',
  'Blue03': '蓝色03',
  'Blue04': '蓝色04',
  'Blue05': '蓝色05',
  'BlueGray04': '蓝灰色04',
  'Bluegrey': '蓝灰色',
  'BlueGrey01': '蓝灰色01',
  'BlueGrey02': '蓝灰色02',
  'BlueGrey03': '蓝灰色03',
  'BlueGrey05': '蓝灰色05',
  'BlueprintForTheEditingModel': '编辑物模型蓝图',
  'BlueprintList': '蓝图列表',
  'BlueprintSave': '蓝图保存',
  'Blur': '模糊',
  'Bold': '黑体',
  'Bold2': '加粗',
  'Bold3': '粗',
  'Boolean': '布尔型',
  'BooleanTurns': '布尔转数字',
  'BooleanTypeTransferNumber': '布尔类型转数字',
  'Border': '有边框',
  'BorderCard': '边框卡片',
  'BorderLineColour': '边框线颜色',
  'Borders': '边框',
  'Bottom': '底部',
  'Bottom2': '居底',
  'BottomAlignment': '下对齐',
  'BottomBorder': '下边框',
  'BottomExternalBorder': '下部外侧边框',
  'BottomLeft': '下左',
  'BreakLines': '折线',
  'Brightness': '亮度',
  'BrowseMode': '浏览模式',
  'Bubbles': '气泡',
  'Building': '楼栋',
  'BuildingName': '建筑名称',
  'Busy': '繁忙',
  'Button': '按钮',
  'Button1': '按钮1',
  'Button2': '按钮2',
  'Button3': '按钮3',
  'ButtonColour': '按钮颜色',
  'ButtonGroup': '按钮组',
  'ButtonLabel': '按钮标签',
  'ButtonText': '按钮文本',
  'Bytes': '个字节',
  'CalculatingEventHandling': '计算事件处理',
  'Camera': '摄像头',
  'Camera1': '摄像头1',
  'Camera10': '摄像头10',
  'Camera11': '摄像头11',
  'Camera12': '摄像头12',
  'Camera13': '摄像头13',
  'Camera14': '摄像头14',
  'Camera15': '摄像头15',
  'Camera16': '摄像头16',
  'Camera17': '摄像头17',
  'Camera18': '摄像头18',
  'Camera19': '摄像头19',
  'Camera2': '摄像头2',
  'Camera20': '摄像头20',
  'Camera3': '摄像头3',
  'Camera4': '摄像头4',
  'Camera5': '摄像头5',
  'Camera6': '摄像头6',
  'Camera7': '摄像头7',
  'Camera8': '摄像头8',
  'Camera9': '摄像头9',
  'CanBeMovedTo': '可移至',
  'CanBeMovedToTheSceneForViewing': '可移至场景中进行查看',
  'Cancel': '取消',
  'Cancel2': '消',
  'Cannot': '不可以',
  'CannotBeDropped': '不能丢下',
  'CannotBeEmpty': '不能为空',
  'CannotGroupComponentsThatAreAlreadyGrouped': '不能对已打组的组件进行打组',
  'CanNotOpenMessage': '发送消息',
  'CanNotOpenMessage2': '辅助信息',
  'CanNotOpenMessage3': '微信登录',
  'CanNotOpenMessage4': '选中无状态',
  'Canvas': '画布',
  'Capable': '可抓取',
  'CapitalLetters': '大写字母',
  'Card': '卡片',
  'CategoryName': '分类名称',
  'CategoryOfLightEquipment': '灯光设备类别',
  'CenterHorizontally': '水平居中',
  'CenterRight': '右中',
  'Centred': '居中',
  'CentredInRows': '行居中',
  'CentreVertically': '垂直居中',
  'CentreZoom': '中心缩放',
  'Chair': '椅',
  'Chamfer': '倒角',
  'Change': '变化',
  'ChangeTheGrid': '改变格子',
  'ChangingTheLayoutWillCauseSomeComponentsToDeform': '改变布局将导致部分组件变形',
  'Character': '字符',
  'CharacterConnection': '字符连接',
  'CheckDown': '下进行查看',
  'CheckItOutPlease': '请检查',
  'CheckNetworkConnectionsPlease': '请检查网络连接',
  'CipherTextHasBeenCorrupted': '密文已被破坏',
  'Circle': '圆形',
  'CircleGradient': '圆形渐变',
  'CircleHead': '圆头',
  'Circulation': '送风',
  'City': '城市',
  'Classification': '分类',
  'Clear': '下架',
  'Clear2': '清空',
  'ClearExistingData': '清空原有数据',
  'ClearTimer': '清除定时器',
  'Click': '点击',
  'Clickable': '可点击',
  'ClickCopy': '点击复制',
  'ClickEvent': '点击事件',
  'ClickFocus': '点击聚焦',
  'ClickOkAfterProcessing': '处理完毕后点击确定',
  'ClickOnTheButton': '点击按钮',
  'ClickOnTheModel': '点击模型',
  'ClickToDisplay': '点击显示',
  'ClickToDisplayTheGrid': '点击显示网格,',
  'ClickToEditScene': '点击编辑场景',
  'ClickToShowTheGrid': '点击显示网格',
  'Client': '客户端',
  'CloningFailedComponentNotFound': '克隆失败，构件未找到',
  'Close': '关闭',
  'Close2': '关',
  'CloseAllFloatingWindows': '关闭所有浮窗',
  'Closed': '已结束',
  'CloseFocus': '关闭聚焦',
  'CloseText': '关闭文本',
  'Cloud': '云',
  'CollapseButton': '折叠按钮',
  'Collection': '集合',
  'CollectionComponent': '收藏组件',
  'ColorLight': '彩光',
  'ColourInput': '颜色输入',
  'Colours': '颜色',
  'ColourSettings': '颜色设定',
  'ColourSettings2': '颜色设置',
  'ColourTemperature': '色温',
  'ColourTemperatureSettings': '色温设置',
  'ColumnCentre': '列居中',
  'Comma': '，',
  'CompanyName': '公司名称',
  'CompanyName2': '企业名称',
  'Comparison': '比较',
  'Completed': '完成',
  'Completed2': '已完成',
  'Component': '组件',
  'Component2': '组件的',
  'ComponentAddedSuccessfully': '组件添加成功',
  'ComponentBrowser': '组件浏览器',
  'ComponentCloneSuccessfully': '构件克隆成功',
  'ComponentCollection': '组件收藏',
  'ComponentCreatedSuccessfully': '创建组件成功',
  'ComponentDeleted': '构件已删除',
  'ComponentDisplay': '组件显示',
  'ComponentDoesNotSupportPreview': '组件不支持预览',
  'ComponentDoesNotSupportSharing': '组件不支持分享',
  'ComponentEdit': '组件编辑',
  'ComponentList': '组件列表',
  'ComponentListData': '组件列表数据',
  'ComponentMarket': '组件市场',
  'ComponentMarket2': '翠鸟云组件市场',
  'ComponentName': '组件名称',
  'ComponentNotFound': '构件未找到',
  'ComponentProperties': '组件属性',
  'Condition': '条件',
  'ConfigurationTool': '翠鸟云二维组态工具',
  'Configure': '配置',
  'Configured': '已配置',
  'ConfigureEntry': '配置项',
  'ConfigureInProjectScript': '请在项目脚本中配置',
  'Confirm': '确定',
  'Confirm2': '确认',
  'Confirm3': '确',
  'Confirm4': '定',
  'ConfirmationOfChangesInExistingRegionalModalities': '是否确认修改现有区域模式',
  'ConfirmChanges': '确认修改',
  'ConfirmExportScene': '确认导出场景',
  'ConfirmThatTheNewPasswordDoesNotMatchTheNewPassword': '确认新密码与新密码不一致',
  'ConfirmThatTheNewPasswordIsNotEmpty': '确认新密码不能为空',
  'ConfirmTheDeletionOfTheEvent': '确认删除该事件吗？',
  'Connected': '已连接',
  'ConnectedSuccessfully': '连接成功',
  'Connection': '连接',
  'ConnectionClosed': '连接关闭',
  'ConnectionDisconnected': '连接断开',
  'ConnectionError': '连接错误',
  'ConnectionName': '连接名称',
  'Consolidated': '综合',
  'ConstantArray': '常量数组',
  'ConstantFile': '常量文件',
  'ConstantNumber': '常量数字',
  'ConstantString': '常量字符串',
  'Construction': '建筑',
  'ConstructionSelection': '建筑选择',
  'ContactUs': '联系我们',
  'Contents': '表内容',
  'Continuous': '连续',
  'Control': '控制',
  'ControlBar': '控制栏',
  'Controllable': '可控制',
  'ControlStatus': '控制状态',
  'ControlSwitches': '控制开关',
  'ConvertToNumbers': '转换为数字',
  'Copy': '.复制品',
  'Copy2': '复制',
  'Copy3': '复制品',
  'Copy4': '克隆',
  'Copy5': '副本',
  'Copyable': '可复制',
  'CopyComponent': '复制组件',
  'CopyFailedcannotFindTheWidget': '克隆失败',
  'CopyLink': '复制链接',
  'CopyMapRelationship': '复制映射关系',
  'CopyPanel': '复制面板',
  'CopySuccessful': '复制成功',
  'CouldNotMoveUp': '无法上移',
  'Cover': '封面',
  'CoverChart': '封面图',
  'Create': '创建',
  'CreateAGroup': '创建一个分组',
  'CreateANewSession': '创建新的会话',
  'CreateAProject': '创建一个项目',
  'CreateClassification': '创建分类',
  'CreateCopy': '创建副本',
  'Created': '创建时间',
  'CreateDataSource': '创建数据源',
  'CreatedDeviceData': '已创建设备数据',
  'CreateFieldsAsNewModel': '创建字段为新模型',
  'CreateGroup': '创建分组',
  'CreateItem': '创建项目',
  'CreateOrder': '创建顺序',
  'CreatePage': '创建页面',
  'CreatePlayer': '创建播放器',
  'CreateRequestAgent': '创建请求代理',
  'CreateScene': '创建场景',
  'CreateSheet': '创建工单',
  'CreateSuccessful': '创建成功',
  'Creativity': '工创',
  'CropContents': '裁剪内容',
  'Cross': '交叉',
  'Crossstand': '横排',
  'Current': '当前的',
  'Current2': '当前',
  'CurrentBrightness': '当前亮度',
  'CurrentColourType': '当前颜色类型',
  'CurrentComponentData': '当前组件数据',
  'CurrentlySelected': '当前选中',
  'CurrentlySelectedDevice': '当前选中的设备',
  'CurrentPage': '当前页面',
  'CurrentStatus': '当前状态',
  'CurrentVersion': '当前版本',
  'Curtain001': '窗帘001',
  'CurtainDirection': '窗帘方向',
  'CurtainPosition': '窗帘位置',
  'Curtains': '窗帘',
  'CurtainSpeed': '窗帘速度',
  'Curve': '曲线图',
  'Curve2': '曲线',
  'Custom': '自定义',
  'Cut': '剪切',
  'Cut2': '裁切',
  'CutComponent': '剪切组件',
  'CutTheHorn': '切角',
  'CylindricalChart': '柱状图',
  'Dangerous': '危险',
  'Dark': '暗黑',
  'DarkAsh': '深灰',
  'DarkBlue': '深蓝',
  'DarkGrey04': '深灰04',
  'DarkGrey05': '深灰05',
  'Dashboard': '仪表盘',
  'Data': '数据',
  'Data1': '数据1',
  'DataArray': '数据数组',
  'DataComponent': '数据组件',
  'DataConfiguration': '数据配置',
  'DataConfigurationList': '数据配置列表',
  'DataConnection': '数据连接',
  'DataConnectionFailed': '数据连接失败',
  'DataDevice': '数据设备',
  'DataDeviceComponent': '数据设备组件',
  'DataEnumeration': '数据枚举',
  'DataError': '数据错误',
  'DataFormat': '数据格式',
  'DataFormatMatchingSuccessfully': '数据格式匹配成功',
  'DataMap': '数据映射',
  'DatamapSubscription': '数据映射订阅',
  'DataNotAvailable': '暂无数据',
  'DataObjectives': '数据目标',
  'DataProperties': '数据属性',
  'DataPropertiesConfigurationList': '数据属性配置列表',
  'DataReceived': '收到数据',
  'DataSourceAccessList': '数据源接入列表',
  'DataSourceCannotBeEmpty': '数据源数据不能为空',
  'DataSourceCreatedSuccessfully': '数据源创建成功',
  'DataSourceCreationError': '数据源创建错误',
  'DataSourceDeletedSuccessfully': '数据源删除成功',
  'DataSourceDeletingFailed': '数据源删除失败',
  'DataSourceError': '数据源错误',
  'DataSourceList': '数据源列表',
  'DataSourceName': '数据源名称',
  'DataSources': '数据源',
  'DataSourceStandard': '数据源标准',
  'DataSourceUpdateSuccessful': '数据源更新成功',
  'DataType': '的数据类型',
  'DataType2': '数据类型',
  'DataValue': '数据值',
  'Date': '日期',
  'DateRange': '日期范围',
  'DateSelection': '日期选择',
  'Day': '天。',
  'Day1': '日',
  'DaysAgo': '天之前',
  'Decimal': '小数',
  'DecimalPlaces': '小数位数',
  'DeepGray03': '深灰03',
  'DeepGrey01': '深灰01',
  'DeepGrey02': '深灰02',
  'Default': '默认',
  'DefaultCamera': '默认相机',
  'DefaultComponent': '默认组件',
  'DefaultComponentWhenTheObjectModelIsNotAssociatedWith': '物模型没有关联三维组件时,默认组件',
  'DefaultDevice': '默认设备',
  'DefaultEvents': '默认事件',
  'DefaultFloor': '默认楼层',
  'DefaultLabel': '默认label',
  'DefaultLabel1': '默认LABEL',
  'DefaultLayout': '默认布局',
  'DefaultModel': '默认模型',
  'DefaultName': '默认名称',
  'DefaultProperties': '默认属性',
  'DefaultResourceList': '默认资源列表',
  'DefaultStatusPoint': '默认状态点',
  'DefaultValue': '默认值',
  'Degrees': '度',
  'Dehumidification': '除湿',
  'Delete': '删除',
  'Deleted': '已删除',
  'DeleteItAndFindItInTheWastebin': '删除后可以在回收站中找到',
  'DeletePermanently': '永久删除',
  'DeleteSuccess': '删除成功',
  'DeleteTheRow': '删除该行',
  'DeletionFailed': '删除失败',
  'Depends': '而定',
  'DeploymentDocument': '部署文档',
  'Descending': '为降序',
  'Description': '描述',
  'DescriptionColour': '描述颜色',
  'Design': '设计',
  'DestructionOf3dComponents': '销毁三维组件',
  'Details': '详情',
  'DeterminesThatAllContentsAreEmptied': '是否确定清空所有内容',
  'DeterminesToDeleteItems': '确定删除项目',
  'DeterminesWhetherThisReleaseLinkShouldBePlacedNext': '是否确定下架此发布链接',
  'DetermineWhetherToDeleteTheDataService': '是否确定删除该条数据服务',
  'DetermineWhetherToRestore': '是否确定恢复',
  'Device': '个设备',
  'DeviceAlarm': '设备报警',
  'DeviceClassification': '设备分类',
  'DeviceData': '设备数据',
  'DeviceId': '设备id',
  'DeviceLabel': '设备标签',
  'DeviceList': '场景设备列表',
  'DeviceList2': '设备列表',
  'DeviceManagementPage': '设备管理页',
  'DeviceName': '设备名称',
  'DeviceNumber': '设备编号',
  'DeviceNumberToBeFilledIn': '需填入设备编号',
  'DevicePanel': '设备面板',
  'DeviceProperties': '设备属性',
  'DeviceRepository': '设备库样',
  'DeviceStatus': '设备状态',
  'DeviceTime': '设备为时',
  'DeviceType': '设备类型',
  'DeviceTypeA': '设备类型A',
  'DeviceTypeArray': '设备类型数组',
  'DeviceTypeArrayQueryFailed': '设备类型数组查询失败',
  'DeviceTypeB': '设备类型B',
  'DeviceTypeC': '设备类型C',
  'DeviceTypeD': '设备类型D',
  'DeviceTypeE': '设备类型E',
  'DeviceTypeF': '设备类型F',
  'DeviceTypeG': '设备类型G',
  'DifferentTypesOfEquipment': '设备类型不同',
  'DifferentTypesOfEquipmentAreNotSupportedForApplication': '设备类型不同,不支持应用',
  'Digital': '数字人',
  'Dimensions': '尺寸',
  'DirectionLight1': '方向光.1',
  'DirectlyAbove': '正上方',
  'DirectlyBelow': '正下方',
  'Disable': '停用',
  'Disable2': '禁用',
  'DisableGround': '禁用地面',
  'DisaggregatedData': '分类数据',
  'Disconnect': '断开连接',
  'Disconnected': '未连接',
  'Discontinue': '已下架',
  'Dismantling': '解组',
  'DismantlingFailed': '解组失败',
  'DismantlingSucceeded': '解组成功',
  'DismissGroupToDefault': '解散分组到默认',
  'DisplayOnBothSides': '两侧显示',
  'DistortionOfCanvas': '画布偏移',
  'Document': '文档',
  'Documentation': '文件',
  'DoesNotSupportFullscreen': '不支持全屏',
  'DoNotAutomaticallyAdjust': '不自动调整',
  'DoNotScroll': '从不滚动',
  'DoNotShowBottom': '不显示底部',
  'DoNotShowHead': '不显示头部',
  'DoNotShowLeft': '不显示左侧',
  'DoNotShowOutside': '不显示外侧',
  'DoNotShowRight': '不显示右侧',
  'Door': '门',
  'DottedLine': '虚线',
  'Double': '双线',
  'Doubleclick': '双击',
  'Down': '下',
  'Down2': '居下',
  'Down3': '向下',
  'Download': '下载',
  'DownloadSuccessful': '下载成功',
  'Draw': '绘制',
  'DrawsideColours': '描边颜色',
  'DrawTheEdge': '描边',
  'DrinkingWaterMachine': '饮水机',
  'Duration': '有效期',
  'Edit': '编辑',
  'EditIn': '编辑于',
  'EditMode': '编辑模式',
  'EditModeRotation': '编辑模式旋转',
  'EditNotSupported': '不支持编辑',
  'Editor': '编辑者',
  'EditorEditMode': '编辑器编辑模式',
  'EditorPreviewMode': '编辑器预览模式',
  'EditPage': '编辑页面',
  'EditScript': '编辑脚本',
  'Eight': '8位',
  'EightCameras': '8画面',
  'Elevator': '电梯',
  'EllipseGradient': '椭圆渐变',
  'EmailFormatError': '邮箱格式错误',
  'Emergency': '紧急',
  'Empty': '空',
  'EmptyCanvas': '清空画布',
  'EmptyModel': '清空物模型',
  'EmptyScene': '空场景',
  'EmptyWastebin': '清空回收站',
  'EmptyWastebinAttemptFailed': '回收站清空失败',
  'Enable': '启用',
  'EnableGround': '启用地面',
  'Encoding': '编码',
  'EndName': '端点名称',
  'EndOfThePage': '页尾',
  'EndpointAlignment': '终点对齐',
  'EndSession': '结束会话',
  'Enter': '进入',
  'EnterAccountName': '填入账户名',
  'EnterAccountPassword': '填入账户密码',
  'EnterANewPassword': '输入新密码',
  'EnterTheItemCodePlease': '请输入项目编码',
  'EnterTheOriginalPassword': '输入原密码',
  'Environment': '环境',
  'EqualTo': '等于',
  'Equipment': '设备',
  'Equipment1': '设备1',
  'Equipment2': '设备2',
  'Equipment3': '设备3',
  'Equipment4': '设备4',
  'Equipment5': '设备5',
  'Equipment6': '设备6',
  'Equipment7': '设备7',
  'Equivalence': '等距',
  'EquivalentZoom': '等比缩放',
  'Error': '出错',
  'Error2': '错误',
  'ErrorEnteringInformation': '输入信息有错误',
  'ErrorFetchingDeviceMapData': '获取设备映射数据出错',
  'ErrorFetchingTheLatestScenarioScriptParameter': '获取最新的场景脚本参数出错',
  'ErrorFindingVersionScriptParameter': '查找版本脚本参数出错',
  'ErrorInConfiguringCorrespondingDataProvider': '获取相应数据供应商配置错误',
  'ErrorSavingSceneScript': '保存场景脚本出错',
  'EventAs': '事件为',
  'EventBehaviorLibraryHasBeenLoaded': '事件行为组件库已经加载',
  'EventHandlingError': '事件处理错误',
  'Events': '事件',
  'Events2': '的事件',
  'EventsAfterTheSceneIsLoaded': '场景加载后事件',
  'EventScript': '事件脚本',
  'ExampleMatrix': '示例矩阵',
  'ExitLogin': '退出登录',
  'ExpandPanel': '展开面板',
  'Export': '导出',
  'ExportScene': '导出场景',
  'ExteriorShadows': '外阴影',
  'External': '外部',
  'ExternalPageInitialization': '外部页面初始化',
  'ExternalPageLoadError': '外部页面加载错误',
  'ExternalPageLoadingCompleted': '外部页面加载完成',
  'ExternalPages': '外部网页',
  'Failed': '失败',
  'FailedAndDelete': '失败并删除',
  'FailedToDeletePage': '删除页面失败',
  'FailedToLoadPageScripts': '加载页面脚本失败',
  'FailedToLoadServiceScript': '加载服务脚本失败',
  'FailedToOpenPostPage': '发布网页打开失败',
  'FailedToSaveResource': '保存资源失败',
  'FailPleaseContactAdministrator': '失败，请联系管理员',
  'Fault': '故障',
  'FaultState': '故障状态',
  'FavouriteFolder': '收藏夹',
  'Fetching': '正在抓取',
  'FetchModelData': '获取模型数据...',
  'Fields': '字段',
  'File': '个',
  'FileDeleteSuccessful': '文件删除成功',
  'FileName': '文件名称',
  'FileName2': '文件名',
  'FileReadingFailed': '文件读取失败',
  'FileRestorationSuccess': '文件恢复成功',
  'FileSize': '文件大小',
  'FileType': '类型的文件',
  'Fill': '填充',
  'FillingLengthOn': '填写长度在',
  'FillItUp': '铺满',
  'FillMode': '填充模式',
  'FillWith': '填充方式',
  'Fine': '细体',
  'Fine2': '细',
  'Finer': '更细',
  'First': '首个',
  'FitToWindow': '适合窗口',
  'Five': '五',
  'Fixed': '固定',
  'FixedPanel': '固定面板',
  'FixedPosition': '固定位置',
  'FixedWidth': '固定宽度',
  'Float': '浮点',
  'FloatType': '浮点型',
  'Floor': '楼层',
  'FloorList': '楼层列表',
  'Fluid': '流式',
  'FocusZoom': '聚焦缩放',
  'FolderExists': '文件夹已存在',
  'FollowPanel': '跟随面板',
  'Fonts': '字体',
  'FontSize': '字号',
  'Footer': '页脚',
  'FooterBackground': '页脚背景',
  'ForBrowsersThatDoNotSupportGradients': '对于不支持渐变的浏览器',
  'ForLargerFileProcessingNeedsPleaseContactUs': '需要更大的文件处理请联系我们',
  'Form': '表单',
  'Format': '格式',
  'FormatSelection': '格式化选中',
  'Formatting': '格式化',
  'FormRule': '表单规则',
  'Formula': '公式',
  'ForScene': '中南',
  'Forward': '向前',
  'Foundation': '基础',
  'Four': '四',
  'FourCameras': '4画面',
  'FourearlyBorders': '四边外侧边框',
  'FrameRate': '帧率',
  'FrequencyOfIncomingAndOutgoing': '收发频率',
  'Friday': '星期五',
  'Front': '前',
  'FrontView': '前视图',
  'FullScreen': '全屏',
  'FullScreenControl': '全屏控制',
  'FullscreenModeDisabled': '全屏模式被禁用',
  'FunctionTypeError': '函数类型错误',
  'Gallery': '图片库',
  'GearLayout': '格子布局',
  'General': '常规',
  'GeneralModel': '总模型',
  'GenerateConnections': '生成连接',
  'GenerateLinkOpen': '生成链接打开',
  'GetAllThat': '获取的所有的',
  'GetAnExampleObjectForAPageComponent': '获取页面组件实例对象',
  'GetSpaceList': '获取空间列表',
  'GlobalData': '全局数据',
  'GlobalEvents': '全局事件',
  'GlobalMessage': '全局消息',
  'GoBackToTheHomepage': '返回主页',
  'GoBackToTheMainPage': '返回主页面',
  'GoBackToTheNextLevel': '返回上一级',
  'GoToDefinition': '转到定义',
  'GoToQuote': '转到引用',
  'GoToSymbol': '转到符号...',
  'GoToSymbol1': '转到符号',
  'GradientGradient': '径向渐变',
  'GreaterThan': '大于',
  'GreaterThanOrEqualTo': '大于等于',
  'Green': '绿色',
  'Green01': '绿色01',
  'Green02': '绿色02',
  'Green03': '绿色03',
  'Green04': '绿色04',
  'Green05': '绿色05',
  'GridAlignment': '网格吸附',
  'GridDisplay': '网格显示',
  'GridSize': '网格尺寸',
  'Ground': '地面',
  'GroundAreaAndEncircledBox': '地面区域和包围盒',
  'GroundGeneration': '地台生成',
  'GroundTemplate': '地面模板',
  'Group': '分组',
  'GroupAs': '分组为',
  'GroupedComponentsNotSupportedForAdditions': '已打组组件不支持添选',
  'Grouping': '打组',
  'GroupingFailed': '打组失败',
  'GroupingSuccessful': '打组成功',
  'GroupName': '分组名称',
  'GroupName2': '组名称',
  'GroupNameCannotBeUsed': '分组名称不能以',
  'GroupNameRepeat': '组名重复',
  'GroupRename': '分组重命名',
  'Guide': '辅助线',
  'HandleEach': '处理每个',
  'Has': '有',
  'Head': '头部',
  'Header': '表头',
  'Header2': '页头',
  'HeaderBackground': '页头背景',
  'Heating': '制热',
  'Height': '高度',
  'Height2': '长',
  'HeightChanges': '高度可改变',
  'HeightFill': '高度填充',
  'Help': '帮助',
  'HelpDocument': '帮助文档',
  'Hide': '隐藏',
  'HideDate': '隐藏日期',
  'HideGrid': '隐藏网格',
  'High': '高',
  'HighSpeed': '高速',
  'HighWind': '高风',
  'Hint': '提示',
  'History': '历史记录',
  'HitCache': '命中缓存',
  'HomePage': '主页',
  'HomePage2': '首页',
  'HomepageNotCurrentlySet': '当前暂未设置主页',
  'HomepageNotCurrentlySetCannotBePublished': '当前暂未设置主页，无法发布',
  'Horizontal': '横向',
  'Horizontal2': '水平',
  'HorizontalDispersal': '水平分散',
  'HorizontalPosition': '水平位置',
  'HorizontalTiling': '横向平铺',
  'Hours': '小时',
  'Hours2': '时',
  'Hue': '色调',
  'HueBand': '色调带',
  'Huge': '超大',
  'Icon': '图标',
  'IconButton': '图标按钮',
  'IconLibrary': '图标库',
  'IfParametersAreNotSetProperly': '如果参数设置不当',
  'IfTheValueIs': '如果值为',
  'IgnoreMap': '忽略映射',
  'IgnoreProcess': '忽略处理',
  'Image': '头像图片',
  'Image2': '画面',
  'Import': '导入',
  'ImportComponent': '导入组件',
  'ImportDirectly': '直接导入',
  'ImportModel': '导入模型',
  'Index': '索引',
  'IndicatorCard': '指标卡',
  'IndicatorCard1': '指标卡1',
  'IndicatorCard2': '指标卡2',
  'IndicatorCard3': '指标卡3',
  'Indicators': '指标',
  'IndividualIndicators': '单个指标',
  'Information': '信息',
  'InformationAnomaly': '信息异常',
  'InformationError': '信息错误',
  'InitialCamera': '初始相机',
  'InitialCamera2': '初始摄像机',
  'InitialEvent': '初始事件',
  'Initialization': '初始化',
  'InitializeComponent': '初始化组件',
  'InitializeScriptManager': '初始化脚本管理器',
  'InitializeTo': '初始化为',
  'InitializeToComponentEditorMode': '初始化为组件编辑器模式',
  'InitializeToHomepageMode': '初始化为首页模式',
  'InitializeToSceneEditorMode': '初始化为场景编辑器模式',
  'InitializeToSpaceMode': '初始化为空间模式',
  'InitialPosition': '初始位置',
  'InitialPosition2': '初始机位',
  'Inkgreen': '墨绿',
  'InplaceCopy': '原位复制',
  'InsideShadows': '内阴影',
  'InsortTheGrid': '吸附网格',
  'InsufficientPermissions': '权限不足',
  'Integer': '整数',
  'Integer2': '整型',
  'Interaction': '交互',
  'IntercolumnLines': '列间线条',
  'Interface': '界面',
  'InterfaceCreatedSuccessfully': '界面创建成功',
  'InterfaceDocument': '接口文档',
  'InterfaceSubscriptions': '界面订阅',
  'Interline': '行间线条',
  'Internal': '内部',
  'Interval': '区间',
  'InTheProcessOfBeingReleased': '发布中',
  'InvokeRequestQueueSuccessCallback': '调用请求队列成功回调',
  'IsItTheHomePage': '是否是主页',
  'IssuanceOfConnectionAccessIssues': '发布连接访问次数问题',
  'IsThereUnsavedContentIsItSaved': '有未保存的内容，是否保存？',
  'Italicize': '斜体',
  'Italics': '楷体',
  'ItCanOnlyBeOpenedOnTheHomepage': '只能在主页开启',
  'Item': '项目',
  'ItemModelList': '物模型列表',
  'ItllIncreaseYourWaitingTimeConsiderably': '会大幅增加您的等待时间',
  'ItsAlreadyTheBottomLayerItCantMoveDown': '已经是最下面的图层，无法下移',
  'ItsBeenModifiedPleaseReland': '修改成功，请重新登陆',
  'ItsFromTheStation': '地台的',
  'ItsTheTopLayerItCantBeMovedUp': '已经是最上面的图层，无法上移',
  'ItsTimeForTheFloor': '楼层为时',
  'ItsYours': '，由其',
  'IveGotAHeartbeat': '收到心跳',
  'JqueryKit': 'JQuery工具包',
  'Jump': '跳转',
  'Jump2': '弹跳',
  'JumpAddress': '跳转地址',
  'JumpToDatamapPage': '跳转到数据映射页面',
  'KeepAnEyeOnYourMailbox': '后续请随时关注您的邮箱',
  'KeepDecimals': '保留小数',
  'KeySeries': '按键系列',
  'Kingfisher': '翠鸟',
  'KingfisherCloud': '翠鸟云',
  'Label': '标签',
  'Label1': '标签1',
  'LabelHeight': '标签高度',
  'LadderDiagram': '阶梯图',
  'Large': '大',
  'LargeDiagrams': '大图',
  'LastModified': '距上次修改',
  'LastPage': '末页',
  'Layout': '布局',
  'Layout01': '布局01',
  'Layout02': '布局02',
  'Layout03': '布局03',
  'Layout04': '布局04',
  'Layout05': '布局05',
  'LayoutPreset': '布局预设',
  'LayoutType': '布局类型',
  'Left': '左',
  'Left2': '靠左',
  'Left3': '居左',
  'Left4': '左侧',
  'LeftAlign': '左对齐',
  'LeftAndRight': '左右',
  'LeftAndRightAdjustments': '左右调整',
  'LeftAndRightExternalBorder': '左右外侧边框',
  'LeftAndRightMargin': '左右边距',
  'LeftBackground': '左侧背景',
  'LeftBottom': '左下',
  'LeftBox': '左边框',
  'LeftExternalBorder': '左外侧边框',
  'LeftList': '左列表',
  'LeftSlantOverAdjustment': '左斜上调整',
  'LeftToRight': '从左至右',
  'Legend': '图例',
  'LegendTowards': '图例朝向',
  'Length': '长度',
  'LengthGreaterThan': '长度大于',
  'LessThan': '小于',
  'LessThanOrEqualTo': '小于等于',
  'Level': '层级',
  'Level2': '等级',
  'LevelOfTheComponent': '组件的的层级',
  'Library': '库',
  'LibraryClassificationSelection': '库分类选择',
  'LibraryFailed': '库失败',
  'LibraryGroupSelection': '库分组选择',
  'LibraryType': '库类型',
  'Light': '辅光',
  'Light001': '灯光001',
  'LightBlue': '浅蓝',
  'LightColourSet': '灯光颜色设定',
  'LightGrey01': '浅灰01',
  'LightGrey02': '浅灰02',
  'LightGrey03': '浅灰03',
  'LightGrey04': '浅灰04',
  'LightGrey05': '浅灰05',
  'LightQuantifiedList': '轻量化列表',
  'LightQueue': '轻量化队列',
  'Lights': '总灯光',
  'Lights2': '灯光',
  'Lights3': '灯',
  'LightsOffMode': '关灯模式',
  'LimitedNumberOfProjects': '项目数量受限',
  'Linear': '线性',
  'LinearGradient': '线性渐变',
  'LineChart': '折线图',
  'LinesBetweenColumns': '行列组间线条',
  'LineSpacing': '行间距',
  'LineWidth': '线宽',
  'List': '列表',
  'ListLayout': '列表布局',
  'ListOfButtons': '按钮列表',
  'ListOfCommands': '命令清单',
  'ListOfItems': '项目列表',
  'ListOfPermissions': '权限列表',
  'ListOfServiceProviders': '服务供应商列表',
  'ListOfWorkorder': '工单列表',
  'Load': '加载',
  'Load3dScenes': '加载三维场景',
  'LoadComplete': '加载完成',
  'Loaded': '已载入',
  'LoadedAllComponents': '加载了所有组件',
  'LoadHomePage': '加载主页',
  'LoadIconLibrary': '加载图标库',
  'Loading': '加载中',
  'LoadingFiles': '文件加载中...',
  'LoadingFiles1': '文件加载中',
  'LoadingModel': '模型加载中...',
  'LoadingModel1': '模型加载中',
  'LoadingOfTubeModels': '管线模型加载中...',
  'LoadingOfTubeModels1': '管线模型加载中',
  'LoadingPageScripts': '加载页面脚本',
  'LoadingProjectScripts': '加载项目脚本',
  'Loading…': '拼命加载中',
  'LoadPage': '加载页面',
  'LoadPages': '页面加载',
  'LoadServiceScripts': '加载服务脚本',
  'Lock': '锁定',
  'LockInTheDirection': '方向锁定',
  'LodashKit': 'Lodash工具包',
  'Login': '登录',
  'LoginClosed': '登录已关闭',
  'LoginFailed': '登录失败',
  'LoginIsInvalid': '登录凭据失效',
  'LoginServerRequired': '需要登录服务器',
  'LoginSuccessful': '登录成功',
  'LoginTimedOut': '登录超时',
  'LoginWithAccount': '使用账号登录',
  'LoginWithAccountNumberOtherPerson': '同账号他人登录',
  'LookAtTheHeightOfTheBoard': '看板高度',
  'Loop': '循环播放',
  'LoopScroll': '循环滚动',
  'LowercaseLettersAndSpecialCharacters': '小写字母和特殊字符',
  'LowerRight': '下右',
  'LowerView': '下移一层',
  'LowSpeed': '低速',
  'LowWind': '低风',
  'MainBody': '中查看',
  'MainLightSource': '中添加执行对象',
  'MainLightSource2': '主光源',
  'MaintainDataSources': '维护数据源',
  'Maintenance': '维护',
  'Mandatory': '必须',
  'Manual': '手动',
  'ManualStatus': '手动状态',
  'MapIsDisabled': '映射已经停用',
  'Material': '材质',
  'Material1': '材质1',
  'Max': '最大值',
  'MaybeItsBecauseSomeoneElseLogsIntoYourAccount': '可能是因为有其他人登录了您的账户,',
  'MediaResourceDataFormatError': '媒体资源数据格式错误',
  'Medium': '中',
  'MediumBold': '中粗',
  'MediumDisplay': '中间显示',
  'MediumLeft': '左中',
  'MediumSpeed': '中速',
  'MemoryUsageClosesToCriticality': '内存使用接近临界',
  'MemoryUse': '内存使用',
  'MemoryUseBeyondCriticality': '内存使用超过临界',
  'Message': '消息',
  'MessageList': '消息列表',
  'MessageLog': '消息日志',
  'MessageReceived': '接收到消息',
  'Methodology': '方法',
  'Microgram': '微图',
  'Middle': '中间',
  'MiddleDown': '下中',
  'Midline': '中间线',
  'Min': '分',
  'Minimum': '最小值',
  'Mirror': '镜像画面',
  'Mode': '模式',
  'Model': '模型',
  'ModelImport': '模型导入',
  'ModelImportComplete': '模型导入完成',
  'ModelImportFailed': '模型导入失败',
  'ModelImportFailedGltfWith': '模型导入失败。.gltf与',
  'ModelImportFailedTheSelectionNeedsToBeMadeAtTheSame': '模型导入失败。需要同时选择',
  'Modeling': '模型保存中',
  'ModelSaving': '模型保存中...',
  'ModelsLightQuantifiedLevels': '模型轻量化等级',
  'ModelsOfThings': '物模型',
  'ModelType': '模型类型',
  'ModifiedSuccessfully': '修改成功',
  'Modify': '修改',
  'ModifyGroupName': '修改组名称',
  'ModifyingModesWillClearOriginalData': '修改模式会清除原始区域数据',
  'Monday': '星期一',
  'MonitorSecuritySystem': '监控视频墙',
  'Monochrome': '单色',
  'Month': '月',
  'MonthlyScope': '月范围',
  'More': '更多',
  'Moveable': '可移动',
  'MoveDown': '向下移动',
  'MovedSuccessfullyTo': '已成功移动到',
  'MoveFailPleaseContactAdministrator': '移动失败，请联系管理员',
  'MoveGroup': '移动分组',
  'MoveLeft': '向左移',
  'MoveOneLayerDown': '向下移动一层',
  'MoveOneLayerUp': '向上移动一层',
  'MoveRight': '向右移动',
  'MoveSuccessfully': '移动成功',
  'MoveSuccessfullyToViewUnderThisItem': '移动成功，可至该项目下进行查看',
  'MoveToBottom': '移动到最下层',
  'MoveToBottom2': '移动到最下',
  'MoveToGroup': '移动到分组',
  'MoveToItem': '移动到项目',
  'MoveToMyFile': '移动到我的文件',
  'MoveToMyFile2': '可至我的文件',
  'MoveToTop': '移动到最上',
  'MoveUp': '向上移',
  'MoveUpOneLayer': '上移一层',
  'MovingFailed': '移动失败',
  'MsYahei': '微软雅黑',
  'MultipleBoxes': '多选框',
  'MultipleDates': '多个日期',
  'MultiscreenSynchronization': '多屏同步',
  'MustBeFilledIn': '必填',
  'Mute': '静音',
  'MyFiles': '我的文件',
  'Name': '名称',
  'NameCannotBeEmpty': '名称不能为空值',
  'NameCannotBeEmpty2': '名称不能为空',
  'NamedRepeated': '命名重复',
  'NameLength': '名称长度',
  'NameMayNotBeLongerThan': '名称长度不能超过',
  'NameMayNotBeLongerThan50Bytes': '名称长度不能超过50个字节',
  'NameOfComponent': '组件的名称',
  'NameRepeat': '名称重复',
  'NavigationBar': '导航栏',
  'NearbyMonitoringScreen': '附近监控画面',
  'NeedToSelectSimultaneously': '需要同时选择',
  'NetworkError': '网络错误',
  'New': '新建',
  'NewFloor': '新建楼层',
  'NewFolder': '新建文件夹',
  'NewGroup': '新增分组',
  'NewGroupName': '新组名',
  'NewPage': '新建页面',
  'NewPage2': '新页面',
  'NewPasswordFormatError': '新密码格式错误',
  'NewPasswordToIncludeNumbers': '新密码需包含数字',
  'NewRomanFonts': '新罗马字体',
  'NewStatus': '新建状态',
  'NewWindowOpensWebSite': '新窗口打开网址',
  'Next': '下一个',
  'NextPage': '下一页',
  'NextStep': '下一步',
  'NineCameras': '9画面',
  'No': '否',
  'NoBackgroundSelected': '未选中背景',
  'NoChangeInData': '数据未发生变化',
  'NoColourSelected': '未选中颜色',
  'NoComponentsFound': '没有找到组件',
  'NoDefinedAttributeGroupFound': '未找到定义的属性组',
  'NoDefinedAttributeTypeFound': '未找到定义的属性类型',
  'Nodes': '节点',
  'NoDeviceBindingDataDiagram': '无设备绑定数据图',
  'NoEditingAtPresent': '暂无编辑',
  'NoFill': '无填充',
  'NoFlash': '无闪烁',
  'NoGrouping': '未分组',
  'NoHomePage': '无首页',
  'NoHomepagePleaseSetTheHomePageFirst': '无首页，请先设置主页',
  'NoLoginDocumentsFound': '未找到登录凭据',
  'NoModelOfTheDataSourceFound': '没有找到该数据源的物模型',
  'NoneditableModeDoesNotAllowDeletion': '非编辑模式不允许删除',
  'Nonparticipant': '无参',
  'NoReduction': '不减少',
  'NoRegisteredDataSourceOr': '没有注册数据源或者',
  'NoRegisteredDataSourceOrProjectid': '没有注册数据源或者ProjectId为',
  'Normal': '正常',
  'Normal2': '普通',
  'NormalThickness': '正常粗细',
  'NoRoundCornerSelected': '未选中圆角',
  'NoScriptExecution': '没有脚本执行',
  'NoStatusSet': '没有设置状态',
  'NotAchieved': '未实现',
  'NotAdded': '未添加',
  'NotAllowed': '不允许',
  'NotBold': '不加粗',
  'NotClassified': '未分类',
  'NotConfigured': '未配置',
  'NotEqualTo': '不等于',
  'NoTiling': '不平铺',
  'NotLoadedSuccessfully': '未加载成功',
  'NotOnboard': '未上架',
  'NotSelected': '未选中',
  'NoVisibilitySetup': '没有设置显隐',
  'NumberOfDataSources': '数据源数量',
  'NumberOfSides': '面数',
  'Numbers': '数字',
  'NumbersToBoolean100': '数字转布尔100',
  'NumericalTurnToBoolean': '数字转布尔',
  'NumericalTypeTransferBoolean': '数字类型转布尔',
  'NumericColours': '数值颜色',
  'Object': '对象',
  'Objective': '目标',
  'ObliqueView': '斜视图',
  'ObtainModelData': '获取模型数据',
  'Of': '的',
  'Off': '關',
  'Office': '办公室',
  'Official': '官方',
  'OfficialComponent': '官方组件',
  'OfficialContent': '官方内容',
  'Offline': '离线',
  'Offline2': '下线',
  'Offset': '偏移',
  'OnBoard': '上架',
  'OnBoard2': '已上架',
  'One': '一',
  'OneOf': '一个',
  'Online': '上线',
  'Online2': '在线',
  'OnlineHelp': '在线帮助',
  'OnlineStatus': '在线状态',
  'Opacity': '不透明度',
  'Open': '开启',
  'Open2': '开',
  'Open3': '打开',
  'OpenIt': '開',
  'OpenLink': '打开链接',
  'OpenText': '开启文本',
  'OperateFailPleaseContactAdministrator': '操作失败，请联系管理员',
  'Operation': '操作',
  'Operation2': '运算',
  'OperationFailed': '操作失败',
  'Operations': '业务',
  'OperationsPerformedOnComponentList': '对组件列表执行的操作',
  'OperationStatus': '运转状态',
  'OperationSuccessful': '操作成功',
  'OptimalPerformance': '效果最优',
  'Optional': '可选中',
  'Orange': '橙色',
  'Orange01': '橙色01',
  'Orange02': '橙色02',
  'Orange03': '橙色03',
  'Orange04': '橙色04',
  'Orange05': '橙色05',
  'Orientation': '方向',
  'OriginalGroupName': '原组名',
  'OriginalSize': '原生大小',
  'OrUpdateDataAnomaly': '或更新数据异常',
  'Other': '其他',
  'OurContactInformation': '我们的联系方式',
  'Outcome': '结果',
  'Overline': '上划线',
  'Overwrite': '覆盖',
  'Page': '页面',
  'Page2': '页',
  'PageCopyFailed': '页面复制失败',
  'PageCopySuccessful': '页面复制成功',
  'PageCreatedSuccessfully': '页面创建成功',
  'PageDeleteSuccessful': '页面删除成功',
  'PageDeletingFailed': '页面删除失败',
  'PageFillMode': '页面填充模式',
  'PageIsTimed': '页面为时',
  'PageList': '页面列表',
  'PageMenu': '页面菜单',
  'PageName': '页面的名称',
  'PageName2': '页面名称',
  'PageSaveCompleted': '页面保存完成',
  'PageSaveFailed': '页面保存失败',
  'PageScript': '页面脚本',
  'PageServiceModelNotFound': '页面服务模型没有找到',
  'Panel': '面板',
  'PanelName': '面板名称',
  'PanelPackagingType': '面板容器类型',
  'ParameterError': '参数错误',
  'ParameterIs': '参数为',
  'ParameterIsEmpty': '参数为空',
  'Parameters': '参数',
  'ParametersForNonarrayType': '非数组类型参数',
  'ParameterUndefined': '参数未定义',
  'Park': '停车',
  'Password': '密码',
  'PasswordCannotBeEmpty': '密码不能为空',
  'Paste': '粘贴',
  'PasteComponent': '粘贴组件',
  'Path': '路径',
  'Pattern': '图案',
  'Pause': '暂停',
  'PauseStatus': '暂停状态',
  'Percentage': '百分比',
  'PerformSaveAs': '执行另存为',
  'Permanent': '永久',
  'PermanentlyValid': '永久有效',
  'Permissions': '权限',
  'Perspective': '视角',
  'Perspective2': '侧视图',
  'PerspectiveAddSuccess': '视角添加成功',
  'PerspectiveDeleted': '视角已删除',
  'PerspectiveMode': '透视模式',
  'PerspectiveView': '透视图',
  'PhoneNumber': '位手机号码',
  'PhoneNumber2': '联系电话',
  'PictureAdaptation': '图片适应',
  'PictureAddress': '图片地址',
  'PictureComponent': '图片组件',
  'PictureName': '图片名称',
  'PicturePath': '图片路径',
  'Pictures': '图片',
  'PictureScale': '图片比例',
  'Pipe': '管线',
  'Pipe2': '管件',
  'PipeDoesntSupportRotationForAWhile': '管线暂不支持旋转',
  'PipeRadius': '管半径',
  'Play': '播放',
  'PlayQuick': '播放倍速',
  'PleaseAddAnExecutoryFunction': '请添加执行函数',
  'PleaseCheckThatTheReleaseLinkIsCorrect': '请检查发布链接是否正确',
  'PleaseCheckTheApplicationInformation': '请您核对申请信息',
  'PleaseCheckYourEmail': '请注意查收邮件',
  'PleaseChoose': '请选择',
  'PleaseConfigureDataServices': '请配置数据服务',
  'PleaseConfirmIfYouWantToContinue': '请确认是否继续',
  'PleaseContactTheAdministrator': '请联系管理员',
  'PleaseContactTheAdministratorToImproveAccess': '请联系管理员提升权限',
  'PleaseContactThePublisherOfTheConnection': '请联系连接发布作者',
  'PleaseContactThePublisherToUpdateThePublisherConnection': '请联系连接发布作者更新发布连接',
  'PleaseEnter': '请输入',
  'PleaseEnterADescriptionOfTheModel': '请输入模型描述',
  'PleaseEnterAnAccountNumber': '请输入账号',
  'PleaseEnterAName': '请输入名称',
  'PleaseEnterSearchContent': '请输入搜索内容',
  'PleaseEnterTheAuthor': '请输入作者',
  'PleaseEnterTheCompany': '请输入公司',
  'PleaseEnterTheGroupName': '请输入分组名称',
  'PleaseEnterTheJob': '请输入职务',
  'PleaseEnterTheMailbox': '请输入邮箱',
  'PleaseEnterTheNameOfTheComponent': '请输入组件名称',
  'PleaseEnterTheNameOfTheItem': '请输入项目名称',
  'PleaseEnterTheOption': '请输入必选项',
  'PleaseEnterThePassword': '请输入密码',
  'PleaseEnterThePhone': '请输入电话',
  'PleaseEnterTheUser': '请输入用户',
  'PleaseEnterYourName': '请输入姓名',
  'PleaseFillIn': '请填写',
  'PleaseFillInTheProjectName': '请填写项目名',
  'PleaseFillInTheSceneName': '请填写场景名称',
  'PleaseFollowYourMailboxAsSoonAsPossible': '已提交成功,后续请随时关注您的邮箱',
  'PleaseLogBackIn': '请重新登陆',
  'PleaseMoveTo': '请移至',
  'PleaseMoveToLibrary': '请移至库',
  'PleaseReenter': '请重新输入',
  'PleaseReenterAndTryAgain': '请重新登录再尝试',
  'PleaseRelogIn': '请重新登录',
  'PleaseReturnLogin': '请返回登录',
  'PleaseSelectAComponentFirst': '请先选择一个组件',
  'PleaseSelectADate': '请选择日期',
  'PleaseSelectAFileFirst': '请先选择文件',
  'PleaseSelectAPageFirst': '请先选择一个页面',
  'PleaseSelectAPicture': '请选择图片',
  'PleaseSelectTheComponentsThatHaveNotBeenGroupedFirst': '请先选择未进行打组的组件',
  'PleaseSelectTheDataSourceNodeThatNeedsToBeSwitched': '请选中需要切换的数据源节点',
  'PleaseSelectTheItemFirst': '请先选择项目',
  'PleaseSelectTheVideo': '请选择视频',
  'PleaseSetGroup': '请设置分组',
  'PleaseSetTheHomePageFirst': '请先设置主页',
  'PleaseSetTheMainPage': '请设置主页面',
  'PleaseTryAgainLater': '请稍后再试',
  'PleaseUploadTheFileFirst': '请先上传文件',
  'PleaseWait': '请等待',
  'PleaseWaitForTheFileToBeProcessed': '请等待文件处理完成',
  'Point': '点状',
  'PointLink': '点位连线',
  'PointsheetDataUploadSuccessfully': '点表数据上传成功',
  'PointTableData': '点表数据',
  'PopChart': '波普图',
  'Position': '位置',
  'Positioning': '定位',
  'PositioningToBindingDevice': '定位到绑定的设备',
  'PositioningToComponent': '定位到组件',
  'PostedSuccessfully': '发布成功',
  'PostEffect': '后期',
  'Posteffect2': '后期效果',
  'PostLinksCopiedToClipboard': '发布链接已复制到剪贴板',
  'Preset': '预设',
  'Preview': '预览',
  'PreviewMode': '预览模式',
  'PreviousPage': '上一页',
  'PreviousStep': '上一步',
  'Primary': '主要',
  'PriorTo': '年之前',
  'Private': '仅自己',
  'Processing': '处理',
  'ProductName': '产品名称',
  'ProgressChart': '进度图',
  'ProjectDescription': '项目描述',
  'ProjectInformationRetrievalFailed': '获取项目信息失败',
  'ProjectInitializationCompleted': '项目初始化完成',
  'ProjectName': '项目名',
  'ProjectScript': '项目脚本',
  'ProjectScriptInitializationError': '项目脚本初始化错误',
  'ProjectScriptLoadFailed': '加载项目脚本失败',
  'ProjectSettings': '项目设置',
  'Properties': '属性',
  'Properties1': '属性1',
  'PropertiesFields': '属性字段',
  'PropertiesSettings': '属性设置',
  'ProvisionalVersion': '临时版本',
  'PublicLayer': '公共层',
  'PublishConnectionDownedOrTimeDue': '发布连接已下线或时间到期',
  'PublishFail': '发布失败',
  'PublishFailPleaseContactAdministrator': '发布失败，请联系管理员',
  'Publishing': '发布中...',
  'Purple': '紫色',
  'Purple01': '紫色01',
  'Purple02': '紫色02',
  'Purple03': '紫色03',
  'Purple04': '紫色04',
  'Purple05': '紫色05',
  'Query': '查询',
  'Question?': '？',
  'Quick': '快速',
  'Quit': '退出',
  'QuitEditingMode': '退出编辑模式',
  'ReadFile': '读取文件',
  'Received': '收到',
  'RecentlyDeleted': '最近删除',
  'RecentlyEdited': '最近编辑',
  'RecentlyUsed': '最近使用',
  'ReconfirmTheNewPassword': '再次确认新密码',
  'Rectangle': '矩形',
  'Red': '红色',
  'Red01': '红色01',
  'Red02': '红色02',
  'Red03': '红色03',
  'Red04': '红色04',
  'Red05': '红色05',
  'Redo': '重做操作',
  'ReducedToGlobal': '全局减面至',
  'Refresh': '刷新',
  'RefreshSuccess': '刷新成功',
  'Refrigeration': '制冷',
  'Region': '地区',
  'Regional': '区域',
  'Release': '发布',
  'ReleaseStatus': '发布状态',
  'RemainingQuantityToday': '今日剩余数量',
  'RememberPassword': '记住密码',
  'RemoveCollection': '删除收藏',
  'RemoveModel': '删除物模型',
  'RemovePanel': '删除面板',
  'RemoveProperties': '删除属性',
  'Rename': '重命名',
  'RenderInformation': '渲染信息',
  'RenderingEvent': '渲染事件',
  'Replace': '替换',
  'ReplaceAll': '替换所有',
  'Reposition': '复位',
  'Request': '请求',
  'RequestFailed': '请求失败',
  'RequestSendFailed': '发送请求失败',
  'RequestSuccessful': '请求成功',
  'Reset': '重置',
  'Resolution': '分辨率',
  'ResourceName': '资源名称',
  'Resources': '资源',
  'Restore': '恢复',
  'RetrievalFailed': '获取失败',
  'Retrieved': '获取的',
  'RetrieveReturnedComponentFilterValues': '获取返回的组件过滤值',
  'RetrieveReturnedDashboardFilterValues': '获取返回的仪表盘过滤值',
  'Returns3dScene': '返回三维场景',
  'ReturnsDataMap': '返回数据映射',
  'ReturnToHomePage': '返回首页',
  'Right': '右侧',
  'Right2': '右',
  'Right3': '右对齐',
  'Right4': '居右',
  'RightAllignment': '靠右对齐',
  'RightBackground': '右侧背景',
  'RightBottom': '右下',
  'RightClick': '右击',
  'RightInTheMiddle': '正中间',
  'RightKeyMenu': '右键菜单',
  'RightLeft': '右左',
  'RightOutsideBorder': '右外侧边框',
  'RightToLeft': '从右至左',
  'Ring': '环形',
  'Rotable': '可旋转',
  'Rotate': '旋转',
  'RotateAngle': '旋转角度',
  'RotateClockwise': '顺时针旋转',
  'RotateCounterclockwise': '逆时针旋转',
  'RoundCorner': '圆角',
  'RoundedRectangle': '圆角矩形',
  'RouteConfiguration': '路由配置',
  'RowSelection': '行选中',
  'RulemapView': '规则映射视图',
  'Run': '运行',
  'RunModeSwitch': '运行模式切换',
  'Running': '运行中',
  'SameOrder': '顺序一致',
  'SatisfactoryValue': '满足值',
  'Saturation': '饱和度',
  'Saturday': '星期六',
  'Save': '另存',
  'Save2': '保存',
  'SaveAs': '保持常在',
  'SaveAs2': '另存为',
  'SaveAsName': '另存为名称',
  'SaveBlueprints': '保存蓝图',
  'SaveFailed': '保存失败',
  'SaveProject': '保存项目',
  'SaveSceneAs': '场景另存为',
  'SaveSuccessful': '保存成功',
  'Scale': '缩放比例',
  'Scale2': '比例缩放',
  'ScaleBefore': '缩放前',
  'ScaleFill': '比例填充',
  'Scene': '场景',
  'Scene2': '为场景',
  'SceneCreatedSuccessfully': '场景创建成功',
  'SceneDownloading': '场景下载中',
  'SceneExport': '场景导出',
  'SceneFiles': '场景文件',
  'SceneInformation': '场景信息',
  'SceneList': '场景列表',
  'SceneSavedSuccessfully': '场景保存成功',
  'SceneSelection': '场景选择',
  'ScintillationEffect': '闪烁效果',
  'Scope': '范围',
  'ScreenGroup': '屏幕组',
  'ScreenRoles': '屏幕角色',
  'Script': '脚本',
  'ScriptData': '脚本数据',
  'ScriptExecutionError': '脚本执行错误',
  'ScriptLoadingFailed': '脚本加载失败',
  'ScriptOrEventError': '脚本或者事件错误',
  'Scrollable': '可滚动',
  'ScrollAlways': '始终滚动',
  'ScrollBackAndForth': '来回滚动',
  'Scrollbar': '滚动条',
  'ScrollOnDemand': '按需滚动',
  'Search2': '检索',
  'Sec': '秒',
  'SecondAgo': '秒之前',
  'Sector': '扇形',
  'SelectAGroup': '请选择一个分组',
  'SelectAll': '全选',
  'SelectAllComponents': '全选组件',
  'SelectAnAttribute': '选择一个属性',
  'SelectAnObjectForASpaceComponent': '选择空间组件对象',
  'SelectARoundCorner': '选中圆角',
  'SelectColour': '选中颜色',
  'Selected': '选中',
  'SelectedDevices': '选中设备',
  'SelectedState': '选中状态',
  'SelectedValue': '选中值',
  'SelectFile': '选择文件',
  'Selection': '选择',
  'SelectLocalFile': '选择本地文件',
  'SelectModel': '选择模型',
  'SelectModelGroups': '请选择模型分组',
  'Selector': '选择器',
  'SelectTheGroupOnTheLeft': '请选择左侧的分组',
  'Selfadaptation': '自适应',
  'SendAHeartbeat': '发送心跳',
  'SendGlobalEvents': '发送全局事件',
  'SendGlobalEventToAddress': '发送全局事件到地址',
  'SendTestEvent': '发出测试事件',
  'Sensors': '传感器',
  'SentenceEnd': '。',
  'Sequence': '序列',
  'SerialNumber': '序号',
  'Series': '系列',
  'Serious': '严重',
  'ServersNormal': '服务器正常',
  'ServiceInitializationFailed': '服务初始化失败',
  'ServiceList': '服务列表',
  'ServicePathNotFound': '服务路径没有找到',
  'Services': '服务',
  'SessionCreationFailed': '创建会话失败',
  'Set': '设定',
  'SetAllowedToSpecificLocation': '设置允许到具体位置',
  'SetAsDefaultStatus': '设置为默认状态',
  'SetComponent': '设置组件',
  'SetComponentProperties': '设置组件属性',
  'SetGlobalContext': '设置全局上下文',
  'SetPage': '设置页面',
  'SetParameters': '设置参数',
  'SetScene': '设置场景',
  'SetSpace': '设置空间',
  'SetTemperature': '设定温度',
  'SetTexture': '设置纹理',
  'SetTheCamera': '设置摄像头',
  'Settings': '设置',
  'SetupDisplay': '设置显示',
  'SetupVideoComponent': '设置视频组件',
  'Shadow': '阴影',
  'Share': '分享',
  'ShiftRightUp': '右斜上调整',
  'ShortchainInformation': '短链信息',
  'Shortcut': '快捷方式',
  'ShortcutKeys': '快捷键',
  'Show': '显示',
  'ShowAll': '显示全部',
  'ShowAll2': '全部显示',
  'ShowAllGroups': '显示全部分组',
  'ShowBottom': '显示底部',
  'ShowContents': '展示内容',
  'ShowHead': '显示头部',
  'ShowImmediately': '立即显示',
  'ShowLeft': '显示左侧',
  'ShowMessage': '显示消息',
  'ShowOnlyEncircledBoxes': '只显示包围盒',
  'ShowOnlyTheGroundArea': '只显示地面区域',
  'ShowRight': '显示右侧',
  'ShowTheBottomToolbarOfTheSpaceComponent': '显示空间组件底部工具栏',
  'ShowTitle': '显示标题',
  'ShowToolbar': '显示工具条',
  'ShowViewBar': '显示视图栏',
  'Side': '侧',
  'SimulationData': '模拟数据',
  'Single': '单个',
  'SingleSelectionWithinTheGroup': '组内单选',
  'Six': '六',
  'SixCameras': '6画面',
  'SixteenCameras': '16画面',
  'Size': '大小',
  'SizeInUnit': '单位大小',
  'SkipThisGuide': '跳过此引导',
  'Sky': '天',
  'Skyblue': '天蓝色',
  'Skyblue01': '天蓝色01',
  'Skyblue02': '天蓝色02',
  'Skyblue03': '天蓝色03',
  'Skyblue04': '天蓝色04',
  'Skyblue05': '天蓝色05',
  'SkyBox': '天空盒',
  'Slider': '滑动条',
  'Slider2': '滑块',
  'SlightlyLarger': '偏大',
  'SlowSpeed': '慢速',
  'Small': '小',
  'SmallListLayout': '小列表布局',
  'SmallSize': '小号',
  'SmartCharts': '智能图表',
  'Snippets': '片段',
  'Solid': '实线',
  'SomeoneElseHasPossiblyLoggedIntoYourAccount!': '可能是因为有其他人登录了您的账户',
  'SortAlphabetically': '按字母排序',
  'SortBy': '排序方式',
  'SortComponent': '排序组件',
  'Source': '来源',
  'Space': '空间',
  'SpaceComponentAutofocus': '空间组件自动聚焦',
  'SpaceComponentFullScreen': '空间组件全屏',
  'SpaceComponentSearch': '空间组件搜索',
  'SpaceComponentSwitchesToPerspective': '空间组件切换到透视视角',
  'SpaceGovernance': '空间治理',
  'SpaceSurplus': '空间剩余',
  'Spacing': '间距',
  'SpecialBold': '特粗体',
  'SplitVertically': '垂直分散',
  'SqlIs': 'SQL是',
  'Square': '方形',
  'Square2': '正方形',
  'SsoLoginClosed': 'SSO登录已关闭',
  'SsoUserNotFound': 'SSO用户未找到',
  'SsoUserOrPasswordInputError': 'SSO用户或密码输入错误',
  'StackLayout': '堆叠布局',
  'StandardBlue': '标准蓝色',
  'StandardColor': '标准色',
  'StandardDeepBlue': '标准深蓝色',
  'StandardDeepRed': '标准深红',
  'StandardGreen': '标准绿色',
  'StandardLightBlue': '标准浅蓝',
  'StandardLightGreen': '标准浅绿',
  'StandardOrange': '标准橙色',
  'StandardPurple': '标准紫色',
  'StandardRed': '标准红色',
  'Standards': '标准',
  'StandardYellow': '标准黄色',
  'StartEditMode': '开启编辑模式',
  'StartingPointAlignment': '起点对齐',
  'StartProcessing': '开始处理',
  'StartProcessingData': '开始处理数据',
  'StartRequestingData': '开始请求数据',
  'StartSurveillanceEquipment': '开始监视设备',
  'StateName': '状态名称',
  'StateSwitch': '状态切换',
  'Status': '状态',
  'StatusAsTime': '状态为时',
  'StatusConfiguration': '状态配置',
  'StatusIs': '的状态为',
  'StatusPoint': '状态点',
  'StatusSelection': '状态选择',
  'StatusSettings': '状态设置',
  'StatusText': '状态文本',
  'StatusText01': '状态文本01',
  'StatusText02': '状态文本02',
  'StatusText03': '状态文本03',
  'StatusText04': '状态文本04',
  'Step': '步长',
  'StepperControl': '步进器',
  'StepValue': '步进值',
  'Stop': '停止',
  'StopSurveillanceEquipment': '停止监视设备',
  'StopTheSurveillanceEquipment': '停止监视设备,',
  'StreamingServerAddressNeedsToBeSet': '需要设置流媒体服务器地址',
  'String': '字符串',
  'StringingNumerals': '字符串转数字',
  'ToolsNumberFormat': '数字格式化(可配置)',
  'numberFormat': '数字格式化',
  'ToolNumberToBoolean': '数字转布尔(可配置)',
  'numberToBoolean': '数字转布尔',
  'ToolBooleanToNumber': '布尔转数字(可配置)',
  'booleanToNumber': '布尔转数字',
  'StringTypeTurnsNumbers': '字符串类型转数字',
  'StyleDefinition': '样式定义',
  'StyleReference': '样式引用',
  'Styles': '样式',
  'Subject': '主体',
  'Submit': '提交',
  'SubmittedWithSuccess': '已提交成功',
  'SubmittingSuccessfully': '提交成功',
  'Subparagraph': '分段',
  'Subscriptions': '订阅的设备',
  'Success': '成功',
  'Succession': '继承',
  'SucessfullySavedResource': '保存资源成功',
  'Suitable': '合适',
  'Sunday': '星期天',
  'Sunggi!SongTypeface': '宋体',
  'SupplyTemperature': '供应温度',
  'SupportedModelFormat': '支持的模型格式',
  'Surveillance': '监控',
  'SurveillanceSystem': '监控系统',
  'Suspend': '悬停',
  'SuspendColour': '悬停颜色',
  'Switch': '开关',
  'SwitchedTo': '已切换成',
  'SwitchingDataSources': '切换数据源',
  'SwitchStatus': '开关状态',
  'SwitchValue': '开关值',
  'SynchronizeChanges': '同步修改',
  'SystemAnomaly': '系统异常',
  'SystemName': '系统名称',
  'SystemSubtitles': '系统副标题',
  'Tab': '选项卡',
  'TabDisplay': '标签显示',
  'Table': '桌',
  'Table2': '表格',
  'TablesOf': '的表',
  'TabText': '标签文本',
  'Take': '取',
  'TargetValue': '目标值',
  'Telephone': '电话',
  'Temperature': '温度',
  'Templates': '模板',
  'TenSecondsAgo': '10秒之前',
  'TerminateComponent': '销毁播放器',
  'TerminateScript': '销毁脚本',
  'Text': '文本',
  'Text2': '文字',
  'TextColour': '文字颜色',
  'TextContents': '文本内容',
  'TextContents2': '文字内容',
  'TextOrientation': '文字方向',
  'TextShadow': '文字阴影',
  'TextSize': '文字大小',
  'TextZoom': '文字缩放',
  'ThankYouForYourApplication': '感谢您的申请',
  'TheBinFileNameIsDifferent': '.bin文件名不相同',
  'TheBuildingsInTime': '大楼为时',
  'TheCodeHasBeenDestroyedAndCannotBeDeconstructed': '密文已被破坏，无法解析',
  'TheCompanyCantBeEmpty': '公司不能为空',
  'TheComponentsHaveBeenDestroyed': '组件已经销毁',
  'TheContentsWillBePermanentlyRemovedFromTheWastebin': '这些内容在回收站中将会被永久删除',
  'TheContentWillBePermanentlyDeleted': '该内容将会被永久删除',
  'TheContentWillBeRestoredToItsOriginalPosition': '该内容将会被恢复到原来位置',
  'TheCurrentlySelectedDataSource': '当前选中的数据源',
  'TheDatabaseDataUpdateFailed': '翠鸟数据库数据更新失败',
  'TheDatabaseDataUpdateWasSuccessful': '翠鸟数据库数据更新成功',
  'TheDatabaseHasBeenUpgradedSuccessfully': '翠鸟数据库升级成功',
  'TheDatabaseWasOpenedWrongly': '翠鸟数据库打开报错',
  'TheDataFormatMatchesSuccessfully': '数据格式匹配成功,',
  'TheDataIsMissing': '数据丢失啦',
  'TheDigitalManipulative': '数字人解说',
  'TheDimensionIs': '维度是',
  'TheDocumentsAreStillBeingProcessed': '文件还在处理中',
  'TheEmailHasBeenRegistered': '该邮箱已注册过',
  'TheEngineHasBeenDestroyed': '引擎已经销毁',
  'TheFileNamesAreDifferent': '文件名不相同',
  'TheFileTypeIsIncorrect': '文件类型不正确',
  'TheGroupAlreadyExists': '该分组已存在',
  'TheIndicatorsAre:': '指标是',
  'TheLibraryCantBeEmpty': '库分类不能为空',
  'TheLightOfTheDirection': '方向光',
  'TheLinkIsDisabled': '该链接已失效',
  'TheMailboxCantBeEmpty': '邮箱不能为空',
  'TheMaximumNumberOfUploadsOfLightquantifiedModelsToday': '今天轻量化模型上传数量已达上限',
  'Theme': '主题',
  'ThemeColor': '主题色',
  'ThemeColour': '主题颜色',
  'ThemeConfigurationList': '主题配置列表',
  'ThemeSide': '主题描边',
  'ThemeSwitch': '主题切换',
  'TheModelLimitsTheSizeOfTheFile': '模型限制文件体积在',
  'TheModelsTimeRangedFromAFewDozenSecondsToAFewMinutes': '模型所需时间从几十秒到几十分钟不等',
  'TheNameCantBeEmpty': '姓名不能为空',
  'TheNameOfTheFileIsDifferentFromThatOfBin': '与.bin文件名不相同',
  'TheNewPasswordCannotBeEmpty': '新密码不能为空',
  'TheNumberCantBeEmpty': '电话号码不能为空',
  'TheObjectModelConnectsToThe3dModel': '物模型连接三维模型',
  'TheOldPasswordCantBeEmpty': '旧密码不能为空',
  'TheOldVersionOfThePipeCouldNotBeEdited': '旧版本管线无法再编辑',
  'TheOnlyKeyOfTheComponents': '组件的唯一key',
  'TheOnlyOneOfTheComponents': '组件的唯一',
  'TheParameterIsEmptyPleaseCheckTheApplicationInformation': '参数为空，请您核对申请信息',
  'ThePlatform': '地台',
  'ThePublicLayerCanOnlyBeOpenedOnTheHomePage': '公共层只能在主页开启',
  'ThePuzzleHasBeenLoaded': '翠鸟拼图已经加载',
  'ThereIsNoChangeInTheData': '数据未发生变化,',
  'ThereIsNoHomePageForTheCurrentItem': '当前项目中没有主页面',
  'TheRequestFailed': '请求失败,',
  'TheresAnAnomalyInTheSystemTryAgainLaterPlease': '系统异常，请稍后再试',
  'TheresAProblemWithTheServiceConfiguration': '服务配置存在问题',
  'TheresNoWayToFindIt': '方法没有找到',
  'TheSceneHasNotYetBeenInitializedPleaseWait': '场景尚未初始化完毕，请等待',
  'TheSceneHasntBeenInitializedYet': '场景尚未初始化完毕',
  'TheSceneIsBeingDownloadedPleaseWait': '场景下载中...请等待',
  'TheWatchboardDrags': '看板拖动',
  'TheWatchboardSpreadsOut': '看板展开',
  'Thick': '粗细',
  'ThirtyDays': '30天。',
  'ThisEmailHasCompletedItsApplication': '此邮箱已完成申请',
  'ThisEmailIsAlreadyRegistered': '此邮箱已注册',
  'ThisMailboxHasCompletedItsApplicationPleaseCheckThe': '此邮箱已完成申请，请注意查收邮件',
  'ThisMailboxIsRegisteredPleaseReturnToLogin': '此邮箱已注册，请返回登录',
  'ThisOperationCannotBeRestored': '该操作无法恢复',
  'ThousandbitComma': '千分位逗号',
  'ThousandsOfSpaces': '千分位空格',
  'Three': '三',
  'ThroatSlider': '色调滑块',
  'Thursday': '星期四',
  'TieItToTheCamera': '绑定到摄像头',
  'Tiled': '平铺',
  'Tilt': '倾斜',
  'Time': '时间',
  'Timeconsuming': '耗时',
  'Timeout': '超时',
  'TimePointDate': '时间点日期',
  'TimePointDateRange': '时间点日期范围',
  'Title': '标题',
  'TitleColour': '标题颜色',
  'TitleDisplay': '标题显示',
  'TitleSize': '标题大小',
  'TitleText': '标题文字',
  'To': '到',
  'ToCurrentTab': '到当前页签',
  'Today': '今天',
  'Toggle': '切换',
  'ToggleDisplayModelType': '切换显示模型类型',
  'ToggleDisplayStatus': '切换显示状态',
  'ToggleRunningMode': '切换运行模式',
  'ToggleSpaceComponentSpace': '切换空间组件空间',
  'TokenValueObtained': '获取的token值',
  'ToNewTab': '到新页签',
  'Toolbar': '工具条',
  'Toolbars': '工具栏',
  'Toolkit': '工具包',
  'ToOverwriteRawRectangleData': '会覆盖原始矩形数据',
  'Top': '置顶',
  'Top&bottomBorder': '上下外侧边框',
  'Top2': '居上',
  'Top3': '顶',
  'Top4': '顶部',
  'Top5': '居顶',
  'TopBorder': '上边框',
  'TopDown': '下上',
  'TopdownMargin': '上下边距',
  'TopLeft': '左上',
  'TopOuterBorder': '上部外侧边框',
  'TopRight': '右上',
  'TopView': '顶视图',
  'TotalMaterial': '总材质',
  'TotalNumberOfPolygons': '多边形总数',
  'TotalTexture': '总贴图',
  'ToTheRight': '靠右',
  'Transparency': '透明度',
  'Triangle': '三角形',
  'TriggerEvent': '触发事件',
  'TrinityDatabaseDataDeleteSuccessfully': '翠鸟数据库数据删除成功',
  'TrinityDatabaseDataWriteSuccessfully': '翠鸟数据库数据写入成功',
  'TrinityDatabaseDataWritingFailed': '翠鸟数据库数据写入失败',
  'TrinityDatabaseOpenSuccessfully': '翠鸟数据库打开成功',
  'TrinityPuzzleUnmounted': '翠鸟拼图已卸载',
  'TryIt': '尝试一下',
  'TryMap': '尝试映射',
  'Tuesday': '星期二',
  'TurnOnTheDataLink': '开启数据链接器',
  'TurnOnTheFocus': '开启聚焦',
  'TurnOnTheGrid': '开启网格',
  'TurnOnThePanel': '开启面板',
  'Tutorial': '教程',
  'TutorialVideo': '教程视频',
  'Two': '二',
  'Type': '类型',
  'Type2': '类型为',
  'TypeConversion': '类型转换',
  'TypeOfPumpEquipment': '水泵设备类别',
  'UnableToConnectToServer': '无法连接服务器',
  'UnableToLoadModule': '无法加载模块',
  'UnableToMoveDown': '无法下移',
  'UnableToObtainRelatedDataExplanationInformation': '无法获取相关数据解释信息',
  'UnableToParse': '无法解析',
  'UnableToPublish': '无法发布',
  'UnableToQueryRelatedData': '无法查询到相关数据',
  'Undefined': '未定义',
  'UndefinedGlobalEvent': '未定义的全局事件',
  'Underline': '下划线',
  'UndoOperation': '撤回操作',
  'Ungrouped': '未打组',
  'UniInterfaceSubscriptions': 'UI界面订阅',
  'Units': '单位',
  'UnitText': '单位文本',
  'Unknown': '未知',
  'UnknownDevice': '未知设备',
  'UnknownService': '未知服务',
  'UnloadedScene': '未加载场景',
  'UnmountPage': '卸载页面',
  'UnmountPageEventListening': '卸载页面事件监听',
  'Unnamed': '未命名',
  'UnsavedContents': '有未保存的内容',
  'Unselect': '取消选择',
  'Up': '向上',
  'Up2': '上',
  'UpAndDown': '上下',
  'UpAndDownAdjustment': '上下调整',
  'Update': '更新',
  'UpdateComponent': '更新组件',
  'Updated': '更新了',
  'UpdateDataSources': '更新数据源',
  'UpdateDate': '更新日期',
  'UpdateFailed': '更新失败',
  'UpdateFailPleaseContactAdministrator': '更新失败，请联系管理员',
  'UpdateOfComponentSuccessfully': '更新组件成功',
  'UpdateSuccessful': '更新成功',
  'UpdateTime': '更新时间',
  'UpdatingOfThePublicWebSite': '更新公共网址',
  'UpgradeServiceRequestProcessingToVersion': '服务请求处理升级为版本',
  'Upload': '上传',
  'UploadDevicePointTable': '上传设备点表',
  'UploadedByMe': '我上传的',
  'UploadFailed': '上传失败',
  'UploadFile': '上传文件',
  'UploadingFailedFileTypeIsIncorrect': '上传失败，文件类型不正确',
  'UploadPointTableData': '上传点表数据',
  'UploadSuccessful': '上传成功',
  'UpperLeft': '上左',
  'UpperMiddle': '上中',
  'UpperRight': '上右',
  'User': '用户',
  'UserAccountExpired': '用户账号已过期',
  'UserComponent': '用户组件',
  'UserContents': '用户内容',
  'UserDoesNotExist': '用户不存在',
  'UserGroup': '用户组',
  'UserHasNoPrivilegePleaseContactAdministrator': '用户权限不足，请联系管理员提升权限',
  'UserIdentity': '用户身份',
  'UserManual': '使用文档',
  'UserManual2': '用户手册',
  'Username': '用户名',
  'UserName2': '用户名称',
  'UsernameOrPasswordCannotBeEmpty': '用户名或密码不能为空',
  'UsernameOrPasswordError': '用户名或者密码错误',
  'UserNotFound': '用户未找到',
  'UserNotRegistered': '用户尚未注册',
  'UserOrPasswordInputError': '用户或密码输入错误',
  'UserPermissionInsufficient': '用户权限不足',
  'UserSettings': '用户设置',
  'UserSRemainingSpaceIsNotEnough': '用户剩余可用空间不足',
  'ValidateFail': '验证失败,',
  'Validator': '验证器',
  'Value': '值',
  'Value2': '数值',
  'ValueAs': '值为',
  'ValueData': '值数据',
  'ValueDisplay': '数值显示',
  'ValueSize': '值大小',
  'ValueWhen': '数值为时',
  'Vendors': '供应商',
  'VerificationFailed': '验证失败',
  'Version': '版本',
  'VersionNumber': '版本号',
  'Vertical': '竖向',
  'Vertical2': '垂直',
  'Vertical3': '纵向',
  'VerticalAlignment': '垂直对齐',
  'VerticalFlatten': '纵向平铺',
  'VerticalLayout': '竖排',
  'VerticalPosition': '垂直位置',
  'VerticalText': '垂直文本',
  'Video': '视频',
  'VideoFiles': '视频文件',
  'VideoLibrary': '视频库',
  'VideoName': '视频名称',
  'VideoPath': '视频路径',
  'VideoSource': '视频源',
  'VideoTutor': '视频教程',
  'VideoWall': '视频墙',
  'View': '视图',
  'ViewDown': '下查看',
  'Viewer': '查看者',
  'ViewingInTheProject': '项目中进行查看',
  'ViewList': '视图列表',
  'ViewOperations': '视图操作',
  'ViewSceneInformation': '查看场景信息',
  'ViewScenes': '查看场景',
  'ViewVersion': '查看版本',
  'Visibility': '可见性',
  'VisibleSettings': '显隐设置',
  'Voltage': '电压',
  'Volume': '音量',
  'VueToolkit': 'Vue工具包',
  'Wait': '等待',
  'Wall': '墙',
  'Waltung': '华东',
  'Warm': '暖色',
  'WarningStatus': '告警状态',
  'Wastebin': '回收站',
  'WastebinEmptiedSuccessfully': '回收站清空成功',
  'WastebinResourceListNetworkRequestError': '回收站资源列表网络请求错误',
  'WatchTheBoard': '看板',
  'WaterTanks': '水箱',
  'Weather': '天气',
  'WebAddress': '网页地址',
  'WebJump': '网页跳转',
  'WebPage': '网页',
  'Website': '网址',
  'WechatLoginFailed': '微信登录失败',
  'WechatLoginFailUnknowService': '微信登录失败，未知服务',
  'Wednesday': '星期三',
  'Week': '周',
  'Week2': '星期',
  'WellKeepYouFor30Days': '我们将为您保留30天',
  'WellKeepYouFor30Days1': '，我们将为您保留30天。',
  'WellReserveItForYou': '我们将为您保留',
  'WereStartingToWatchTheEquipment': '开始监视设备,',
  'WhatAreTheParameters': '参数是',
  'When': '时，',
  'When1': '当',
  'WhenTheObjectModelDoesntConnectToThe3dComponent': '物模型没有关联三维组件时',
  'WhetherOrNotFullscreen': '是否全屏',
  'WhetherOrNotToSave': '是否保存',
  'WhetherOrNotToShowScrollbars': '是否显示滚动条',
  'WhetherToDeleteThisFile': '是否删除此文件',
  'WhetherToShowToolbars': '是否显示工具条',
  'White': '亮白',
  'White2': '白色',
  'WhiteLight': '白光',
  'Width': '宽',
  'WidthCanChange': '宽度可改变',
  'WidthFill': '宽度填充',
  'Window': '窗',
  'WindSpeed': '风速',
  'Within': '以内',
  'WordSpacing': '字间距',
  'WordWeights': '字重',
  'Workorder': '工单',
  'WorkorderInformation': '工单信息',
  'WorkorderName': '工单名称',
  'Wrap': '环绕',
  'WrongNumber': '电话号码错误',
  'Year': '年',
  'Yellow': '黄色',
  'Yellow01': '黄色01',
  'Yellow02': '黄色02',
  'Yellow03': '黄色03',
  'Yellow04': '黄色04',
  'Yellow05': '黄色05',
  'Yes': '是',
  'YouAlreadyLogOutOfTheSystem': '您已经登出系统,',
  'YouAreAlreadyOnTheSystem': '您已经登出系统',
  'YouCanOnlyUploadIt': '只能上传',
  'YouHaveBeenLoggedOut': '您被迫下线',
  'YouHaveNotObtainedAProductAuthorization': '您尚未获取产品授权',
  'YouHaventOperatedForTooLong': '您因为太久没有操作,',
  'YouHaventOperatedForTooLong1': '您因为太久没有操作',
  'YouHaveToChooseAProject': '必须要选择一个项目',
  'YourAccountHasBeenEnteredElsewhere': '您的账号已在其他环境登录',
  'YourAccountHasBeenEnteredElsewhereAndYouAreForcedOffline': '您的账号已在其他环境登录，您被迫下线',
  'YourAccountHasBeenEnteredInAnotherDevice': '您的账号已在其他设备登录',
  'YourAccountHasBeenEnteredInAnotherDeviceAndYouAreForced': '您的账号已在其他设备登录，您被迫下线',
  'Zoom': '缩放',
  'ZoomIn': '放大',
  'ZoomOut': '缩小',
  '[Script]': '[脚本',
  'LanguageSwitching':'语言切换',
  'Context':'内容',
  'MousePenetration':'鼠标穿透',
  'CannotExitLogin':'无法退出登录',
  'DevicePointTable': '设备点表',
  'Add1': '添加',
  'Programme': '方案',
  'ProgrammeList': '方案列表',
  'CreateProgramme': '创建方案',
  'ImportProgramme': '导入方案',
  'UnbindObjectModel': '解绑物模型',
  'DataEnumerationConversion':'数据枚举转换',
  'Furniture':'家具',
  'DialogList': '弹窗列表',
  'AddDialog': '新增弹窗',
  'DialogCreatedSuccessfully': '弹窗创建成功',
  'DialogCopySuccess': '弹窗复制成功',
  'DialogCopyFailed':'弹窗复制失败',
  'EditDialog': '编辑弹窗',
  'Modal':'遮罩',
  'IsDraggable':'是否可拖拽',
  'Hyperlink':'超链接',
  'ProgressBar':'进度条',
  'ProgressLoop':'进度环',
  'ApplicationPointTableDeviceName':'应用点表设备名称',
  'Connector':'连线',
  'PanelEvent':'面板事件',
  'PanelSelect':'面板选择',
  'IsConnector':'是否连线',
  'D1': '1天',
  'D7': '7天',
  'D30': '30天',
  'Configuration':'组态',
  'Artist':'艺术家',
  'CreateSpatialAssets':'创建空间资产',
  'CreateConfigurationAssets':'创建组态资产',
  'ClericalScript':'隶书',
  'MidWind': '中风',
  'Chart':'图表',
  'CouldNotUpload': '无法上传',
  'SpaceComponentSwitchingFloors':'空间组件切换楼层',
  'NextFloor':'下一层',
  'PreFloor':'上一层',
  'ArtistScene':'艺术家场景',
  'ImportSuccess':'导入成功',
  'ImportError':'导入失败',
  'EnglishName':'英文名称',
  'AreYouSureWantToEmptyTheObjectModel':'确定要清空物模型吗',
  'BlueprintMappingSuccessful':'蓝图映射成功',
  'TheBlueprintForTheObjectModelAlreadyExists':'该物模型蓝图已经存在',
  'PlanName':'方案名称',
  'UnbindObjectModelSuccessfully':'解绑物模型成功',
  'AreYouSureToUseThisPlan':'确定使用该方案吗',
  'OperationWillResultInThisObjectModelBeingOverwrittenByTheSelectedScheme':'操作将导致该物模型被选中方案覆盖',
  'FillInTheName':'填写名称',
  'Canceled':'已取消',
  'Use':'使用',
  'ConfirmTheDeletion': '确认删除吗？',
  'DragFilesHere': '拖拽文件到此处',
  'OrClickToSelect':'或点击选择',
  "ThePlanDoesNotExist":'方案不存在',
  'ExportSuccess':'导出成功',
  'ExportError':'导出失败',
  'IAdded':'我添加的',
  'ImportTheme':'导入主题',
  'AddSuccess':'添加成功',
  'UploadTime':'上传时间',
  'ThemeType':'主题类型',
  'ThemeUploaded':'主题已上传',
  'ThemeNotUploaded':'主题未上传',
  'Batch':'批量',
  'Selected1':'已选择',
  'NotSelected1':'未选择',
  'SpaceComponent':'空间组件',
  'ExportPointTableData':'导出点表数据',
  'ExportSpaceData':'导出空间数据',
  'TronV5': '工创5',
  'ConfigurationComponent':'组态组件',
  'shoppingCart':'购物车',
  'DownloadAll': '下载全部',
  'AllParts':'全部部件',
  'Latest':'最新',
  'DownloadNumber':'下载量',
  'Invert':'反选',
  'PleaseDoNotAddAgain':'请勿重复添加',
  'AreYouSureToClearTheShoppingCart':'确认清空购物车么?',
  'AddToLibrary':'添加到库',
  'Step1':'第一步',
  'Step2':'第二步',
  'Step3':'第三步',
  'Step4':'第四步',
  'Step5':'第五步',
  'Step6':'第六步',
  'SelectTheSpaceComponentsYouNeed':'选择您需要的空间组件',
  'SelectTheConfigurationComponentsYouNeed':'选择您需要的组态组件',
  'Tip':'提示',
  'TheSpatialComponentIsAComponentUsedUnderTheSpatialModule,WhichHasRealProportionalRelationshipsAndDataStateChanges,MeetingYourSpatialDataDisplayNeeds':'空间组件是在空间模块下使用的组件，具有真实的比例关系和数据状态变化，满足您的空间数据展示需求',
  'ClickOnTheCategoryListToFilterTheRequiredComponents':'点击分类列表筛选需要的组件',
  'TheClassificationListContainsRelevantComponentsForDifferentIndustries,Categories,AndPurposesClickingOnThemWillLoadTheCorrespondingContentOfTheClassificationInTheRightHandView':'分类列表包含了不同行业、种类、用途的相关组件，点击即可在右侧视图加载对应分类的内容',
  'SelectTheCorrespondingThemeToRenderTheSceneApplicationUnderTheCorrespondingTheme':'选择对应主题，即可渲染对应主题下的场景应用',
  'ClickOnTheCategorylistToFilterTheRequiredComponents':'点击分类列表筛选需要的组件',
  'ChooseTheThemeCategoryYouNeed':'选择你需要的主题分类',
  'AddComponentsToShoppingCart':'添加组件到购物车',
  'AddToLibraryOrDownloadToLocal':'添加到库或下载到本地',
  'TheComponentsInTheShoppingCartCanBeAddedToTheLibraryOfTheOnlineVersionOfGongchuang,OrImportedIntoTheLocalVersionOfGongchuangByDownloadingThemLocally':'购物车内组件可添加到在线版工创的库中，也可通过下载到本地，来导入本地版本工创中',
  'ClickTheAddButtonOnTheComponentToAddTheSelectedComponentToTheShoppingCart,AndClickTheBatchButtonInTheUpperRightCornerToBatchAddItToTheShoppingCart':'点击组件上的“添加”按钮，可将选中组件添加到购物车，点击右上角的“批量”按钮，可批量添加到购物车',
  'TheConfigurationComponentIsADeviceComponentInTheEquipmentSystemDiagram,SuitableForCreating3DConfigurationEquipmentSystemDiagramScenes,WithAppropriateSizeRatiosAndRichStateParametersForTheScreen':'组态组件为设备系统图中设备组件，适合制作3D组态设备系统图场景，具备画面适宜的尺寸比例和丰富的状态参数',
  'Skip':'跳过',
  'CouldNotAdd': '无法添加',
};
export default zh_CN;

import {createI18n} from 'vue-i18n';
import zh_CN from './zh_CN';
import zh_TW from './zh_TW';
import en from './en_US';

const getLocale = function () {
  let _value=localStorage.getItem('Kf_lang');
  if(_value){
    return _value;
  }else{
    return navigator.language;
  }
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: getLocale(),
  messages: {
    'zh-CN': zh_CN,
    'zh-TW': zh_TW,
    'en': en
  },
})

window.Kf = window.Kf || {};
window.Kf.i18nReady = true;
window.Kf.locale = i18n.global.locale;
window.Kf._t = i18n.global.t;
export const languageList = [
  {name:'中文',value:'zh-CN'},
  {name:'繁體中文',value:'zh-TW'},
  {name:'English',value:'en'}
];

export default i18n
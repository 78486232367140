import KingfisherService from '../utils/request.js';

class ProductService {
  /**
   * Creates an instance of ProductService.
   * @memberOf  ProductService
   */
  constructor() {
    this.module = 'product';
    this.kingfisherService = new KingfisherService({});
  }

  /**
   * 获取所有产品信息
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  getProductInfo() {
    return this.kingfisherService.get('/repo/kf-resource/find-product-info').then(response => {
      return response;
    });
  }

  /**
   * 获取产品权限版本
   * @returns Promise Ajax请求返回并处理后的Promise
   */
  getPlatformType() {
    return this.kingfisherService.get(`/repo/kf-resource/get-platform-type`).then(response => {
      return response;
      //return {platformType: 'standard'};
    });
  }

  /**
   * 获取产品配置
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  getEnvVariable() {
    return this.kingfisherService.get('/sys-env-variable/list').then(response => {
      return response;
    });
  }


  /**
   * 获取服务contextPath
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  getContextPath(){
    return this.kingfisherService.get("/repo/kf-project/server-path").then(response => {
      return response;
    });
  }

}

export default new ProductService();

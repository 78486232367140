import {ref} from 'vue';

let env = ref('designer');
const versionDate = '1.0.0 20240711';
const version = '1.0.0';

const isDesigner = function () {
  return env.value === 'designer';
};

const isProject = function () {
  return env.value === 'project';
};

const isMarket = function () {
  return env.value === 'market';
};

const init = function () {
  let url = window.location.href;

  if (url.indexOf('/presenter/') !== -1) {
    env.value = 'presenter';
    //window.Kf.prodCode = "ccd1066343c95877b75b79d47c36bebe";
    console.log('初始化为预览模式, 版本为:' + version);
  } else if (url.indexOf('/project') !== -1) {
    env.value = 'project';
    //window.Kf.prodCode = "ccd1066343c95877b75b79d47c36bebe";
    console.log('初始化为项目管理模式', version);
  } else {
    env.value = 'designer';
    // window.Kf.prodCode = "ccd1066343c95877b75b79d47c36bebe";
    console.log('初始化为设计器模式', version);
  }
};
const loading = ref(true);

const afterLoading = function () {
  loading.value = false;
};

const sysEnv = ref({});

const statisticsVisible = ref(false);
export default {
  env, isDesigner, init, isMarket, afterLoading, sysEnv, version, isProject, loading, versionDate, statisticsVisible,
};

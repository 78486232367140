import {createRouter, createWebHashHistory} from 'vue-router';


const routes = [
  {
    path: '/',
    name: '/login',
    component: () => import('../components/Login.vue'),
  },
  {
    path: '/content',
    name: '/content',
    component: () => import('../components/Content.vue'),
  },
  {
    path: '/content',
    name: '/content',
    component: () => import('../components/Content.vue'),
  },
  {
    path: "/login/:ticket",
    name: "/login-token",
    component: () => import("../components/Login.vue")
  },
  {
    path: '/:pathMatch',
    component: () => import("../components/notFound.vue"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),// hash模式：createWebHashHistory history模式：createWebHistory
  routes,
});

export default router;

const zh_TW = {
  language: {
    zh_CN: '简体中文',
    zh_TW: '繁体中文',
    en: 'English',
  },
  'TronMarket':'工創市場',
  'None': '無',
  '#MinutesAgo': '分鐘之前',
  '&Send': '併發送',
  '1Camera': '1畫面',
  '1Day': '1天',
  '2dComponent': '二維組件',
  '2dConfigurationTool': '二維組態工具',
  '2dEditingMode': '二維編輯模式',
  '30Days': '30天',
  '3d': '三維',
  '3dComponent': '三維組件',
  '3dConfigurationTool': '三維組態工具',
  '3dDesigner': '三維設計器',
  '3dDeviceConnectionNodes': '三維設備連接節點',
  '3dEditingMode': '三維編輯模式',
  '3dScene': '三維場景',
  '3dSpace': '三維空間',
  '7Days': '7天',
  'About': '關於',
  'AboutUs': '關於我們',
  'Absolutely': '絕對',
  'AbsolutePosition': '絕對定位',
  'Access': '接入',
  'AccessHasBeenRestricted': '訪問已被限制',
  'AccessToData': '獲取數據',
  'Account': '賬號',
  'AccountApplication': '賬號申請',
  'AccountName': '賬戶名',
  'AccountPassword': '賬戶密碼',
  'AccountStatus': '賬戶狀態',
  'AChangeOfLayoutWouldResultInAPartialDeformationOfThe': '改變佈局將導致部分組件變形，該操作無法恢復，請確認是否繼續？',
  'Actions': '動作',
  'ActiveStatus': '運行狀態',
  'Add': '新增',
  'AddAll': '全部添加',
  'AddCurrentCategory': '添加當前分類',
  'AddDataSource': '添加數據源',
  'Added': '已添加',
  'AddFieldsAsCreated': '添加字段為已創建',
  'AddFolder': '添加文件夾',
  'AddGroupToObjectModel': '添加組到物模型',
  'AddModel': '添加模型',
  'AddNewItem': '新增枚舉行',
  'AddPage': '新增頁面',
  'AddRequestQueue': '加入請求隊列',
  'AddToCurrentCategory': '添加到當前分類',
  'Administrator': '管理員',
  'AfterConfirmation': '確定後',
  'AfterLoadingImageHide': '加載畫面隱藏後',
  'AirConditioning': '空調',
  'AirConditioning001': '空調001',
  'AirconditioningEquipmentCategory': '空調設備類別',
  'AirconditioningSystems': '空調系統',
  'AirSensor5': '空氣傳感器5',
  'AirSensors': '空氣傳感器',
  'AirSensors1': '空氣傳感器1',
  'Alarm': '告警',
  'Alarm2': '警告',
  'AlarmBubbles': '告警氣泡',
  'AlertList': '告警列表',
  'AlignBothEnds': '兩端對齊',
  'AlignHorizontally': '水平對齊',
  'AlignItToTheLeft': '靠左對齊',
  'Alignment': '對齊',
  'AlignUp': '上對齊',
  'All': '全部',
  'All2': '所有人',
  'AllAboard': '全部上架',
  'AllowUsersToClick': '允許用戶點擊',
  'AllowUsersToExit': '允許用戶退出',
  'AllPublishefdHistoricalData': '全部的發佈歷史數據',
  'AllRemovedFromAboard': '全部下架',
  'Already': '已',
  'AlreadyLoggedInOnADifferentBrowser/window': '或者是您在其他瀏覽器窗口登錄了系統',
  'AlreadyOnTheSystem': '已經登出系統',
  'AlreadyRegistered': '已經被註冊了',
  'AlreadyTheBottomLayer': '已經是最下面的圖層',
  'AlreadyTheTopLayer': '已經是最上面的圖層',
  'AModifiedModelWouldRemoveRawAreaDataAndWouldAModified': '修改模式會清除原始區域數據，是否確認修改現有區域模式?',
  'Analysis': '解析',
  'AnchorPosition': '錨點位置',
  'And': '和',
  'AndInitializesTheCameraPositionAs': '並初始化機位為',
  'AndToTheTop': '並置頂',
  'ANewcomersGuide': '新手引導',
  'Angle': '角度',
  'AnHourAgo': '小時之前',
  'Animation': '動畫',
  'AnimationCycle': '動畫循環',
  'AnimationTime': '動畫時長',
  'Anomalous': '異常',
  'ApiInterfaceDocument': 'API接口文檔',
  'Appearances': '方頭',
  'ApplicationNotSupported': '不支持應用',
  'Applications': '申請',
  'ApplyMapRelationship': '應用映射關係',
  'AreaOfThePlatform': '地臺範圍',
  'AreaTime': '區域為時',
  'AreChangesToExistingRegionalModelsConfirmed': '是否確認修改現有區域模式?',
  'AreYouAlreadyLoggedIn': '是否登錄',
  'AreYouSure': '確定刪除?',
  'AreYouSureItsDeleted': '是否確定刪除?',
  'AreYouSureYouWantToDeleteIt': '確定刪除',
  'AreYouSureYouWantToDeleteItDeleteItAndFindItInTheWastebin': '是否確定刪除?刪除後可以在回收站中找到，我們將為您保留',
  'AreYouSureYouWantToDeleteItYouCanFindItInTheTrashWhen': '是否確定刪除?刪除後可以在回收站中找到',
  'AreYouSureYouWantToDeleteThatState': '確定刪除該狀態嗎',
  'AreYouSureYouWantToDeleteThatState1': '確定刪除該狀態嗎？',
  'AreYouSureYouWantToDeleteTheEvent': '確認刪除該事件嗎',
  'AreYouSureYouWantToDeleteThePerspective': '確定刪除視角嗎?',
  'AreYouSureYouWantToDeleteThePerspective1': '確定刪除視角嗎',
  'AreYouSureYouWantToDeleteTheScene': '確定刪除場景嗎?',
  'AreYouSureYouWantToDeleteTheScene1': '確定刪除場景嗎',
  'AreYouSureYouWantToDeleteThis': '是否確定刪除此內容',
  'ArrangeLayout': '排列布局',
  'Array': '數組',
  'As': '為',
  'Ash': '淺灰',
  'AssociatedDevices': '關聯設備',
  'Attribute2': '屬性2',
  'Audio': '音頻',
  'Author': '作者',
  'Auto': '自動',
  'Autoadapt': '自動調整',
  'AutoConnection': '自動連接',
  'Autocreate': '自動創建',
  'AutoGeneratePartialEnd': '自動生成部分結束',
  'AutoGeneratePartStart': '自動生成部分開始',
  'AutoLayout': '自動佈局',
  'AutomaticRowWrapping': '自動換行',
  'AutoPlay': '自動播放',
  'Autosave': '自動保存',
  'Available': '找到可用的',
  'AvailableForViewingUnderThisItem': '可至該項目下進行查看',
  'AxisLength': '軸長度',
  'Back': '返回',
  'Background': '背景',
  'BackgroundColour': '背景顏色',
  'BackgroundDisplay': '背景顯示',
  'BackgroundPictures': '背景圖片',
  'BackgroundShadow': '背景陰影',
  'BackstageProcessing': '後臺處理',
  'BackToTheFrontPage': '回到首頁',
  'Backwards': '向後',
  'Balance': '平衡',
  'BalanceMode': '平衡模式',
  'Baseboard': '底板',
  'BaseboardColour': '底板顏色',
  'BaseProperties': '基礎屬性',
  'Because': '由於',
  'Beginning': '開頭',
  'Beijing': '北京',
  'BeijingKingfisherTechnologyCoLtd': '北京翠鳥智擎科技有限公司',
  'BestPerformance': '性能最優',
  'Between': '之間',
  'BeyondScroll': '超出滾動',
  'BigNumber': '大號',
  'BindingEquipment': '綁定的設備',
  'Biscuits': '餅圖',
  'Black': '黑色',
  'BlankEdges': '描邊粗細',
  'Blinking': '閃爍',
  'Blue': '藍色',
  'Blue01': '藍色01',
  'Blue02': '藍色02',
  'Blue03': '藍色03',
  'Blue04': '藍色04',
  'Blue05': '藍色05',
  'BlueGray04': '藍灰色04',
  'Bluegrey': '藍灰色',
  'BlueGrey01': '藍灰色01',
  'BlueGrey02': '藍灰色02',
  'BlueGrey03': '藍灰色03',
  'BlueGrey05': '藍灰色05',
  'BlueprintForTheEditingModel': '編輯物模型藍圖',
  'BlueprintList': '藍圖列表',
  'BlueprintSave': '藍圖保存',
  'Blur': '模糊',
  'Bold': '黑體',
  'Bold2': '加粗',
  'Bold3': '粗',
  'Boolean': '布爾型',
  'BooleanTurns': '布爾轉數字',
  'BooleanTypeTransferNumber': '布爾類型轉數字',
  'Border': '有邊框',
  'BorderCard': '邊框卡片',
  'BorderLineColour': '邊框線顏色',
  'Borders': '邊框',
  'Bottom': '底部',
  'Bottom2': '居底',
  'BottomAlignment': '下對齊',
  'BottomBorder': '下邊框',
  'BottomExternalBorder': '下部外側邊框',
  'BottomLeft': '下左',
  'BreakLines': '折線',
  'Brightness': '亮度',
  'BrowseMode': '瀏覽模式',
  'Bubbles': '氣泡',
  'Building': '樓棟',
  'BuildingName': '建築名稱',
  'Busy': '繁忙',
  'Button': '按鈕',
  'Button1': '按鈕1',
  'Button2': '按鈕2',
  'Button3': '按鈕3',
  'ButtonColour': '按鈕顏色',
  'ButtonGroup': '按鈕組',
  'ButtonLabel': '按鈕標籤',
  'ButtonText': '按鈕文本',
  'Bytes': '個字節',
  'CalculatingEventHandling': '計算事件處理',
  'Camera': '攝像頭',
  'Camera1': '攝像頭1',
  'Camera10': '攝像頭10',
  'Camera11': '攝像頭11',
  'Camera12': '攝像頭12',
  'Camera13': '攝像頭13',
  'Camera14': '攝像頭14',
  'Camera15': '攝像頭15',
  'Camera16': '攝像頭16',
  'Camera17': '攝像頭17',
  'Camera18': '攝像頭18',
  'Camera19': '攝像頭19',
  'Camera2': '攝像頭2',
  'Camera20': '攝像頭20',
  'Camera3': '攝像頭3',
  'Camera4': '攝像頭4',
  'Camera5': '攝像頭5',
  'Camera6': '攝像頭6',
  'Camera7': '攝像頭7',
  'Camera8': '攝像頭8',
  'Camera9': '攝像頭9',
  'CanBeMovedTo': '可移至',
  'CanBeMovedToTheSceneForViewing': '可移至場景中進行查看',
  'Cancel': '取消',
  'Cancel2': '消',
  'Cannot': '不可以',
  'CannotBeDropped': '不能丟下',
  'CannotBeEmpty': '不能為空',
  'CannotGroupComponentsThatAreAlreadyGrouped': '不能對已打組的組件進行打組',
  'CanNotOpenMessage': '發送消息',
  'CanNotOpenMessage2': '輔助信息',
  'CanNotOpenMessage3': '微信登錄',
  'CanNotOpenMessage4': '選中無狀態',
  'Canvas': '畫布',
  'Capable': '可抓取',
  'CapitalLetters': '大寫字母',
  'Card': '卡片',
  'CategoryName': '分類名稱',
  'CategoryOfLightEquipment': '燈光設備類別',
  'CenterHorizontally': '水平居中',
  'CenterRight': '右中',
  'Centred': '居中',
  'CentredInRows': '行居中',
  'CentreVertically': '垂直居中',
  'CentreZoom': '中心縮放',
  'Chair': '椅',
  'Chamfer': '倒角',
  'Change': '變化',
  'ChangeTheGrid': '改變格子',
  'ChangingTheLayoutWillCauseSomeComponentsToDeform': '改變佈局將導致部分組件變形',
  'Character': '字符',
  'CharacterConnection': '字符連接',
  'CheckDown': '下進行查看',
  'CheckItOutPlease': '請檢查',
  'CheckNetworkConnectionsPlease': '請檢查網絡連接',
  'CipherTextHasBeenCorrupted': '密文已被破壞',
  'Circle': '圓形',
  'CircleGradient': '圓形漸變',
  'CircleHead': '圓頭',
  'Circulation': '送風',
  'City': '城市',
  'Classification': '分類',
  'Clear': '下架',
  'Clear2': '清空',
  'ClearExistingData': '清空原有數據',
  'ClearTimer': '清除定時器',
  'Click': '點擊',
  'Clickable': '可點擊',
  'ClickCopy': '點擊複製',
  'ClickEvent': '點擊事件',
  'ClickFocus': '點擊聚焦',
  'ClickOkAfterProcessing': '處理完畢後點擊確定',
  'ClickOnTheButton': '點擊按鈕',
  'ClickOnTheModel': '點擊模型',
  'ClickToDisplay': '點擊顯示',
  'ClickToDisplayTheGrid': '點擊顯示網格,',
  'ClickToEditScene': '點擊編輯場景',
  'ClickToShowTheGrid': '點擊顯示網格',
  'Client': '客戶端',
  'CloningFailedComponentNotFound': '克隆失敗，構件未找到',
  'Close': '關閉',
  'Close2': '關',
  'CloseAllFloatingWindows': '關閉所有浮窗',
  'Closed': '已結束',
  'CloseFocus': '關閉聚焦',
  'CloseText': '關閉文本',
  'Cloud': '雲',
  'CollapseButton': '摺疊按鈕',
  'Collection': '集合',
  'CollectionComponent': '收藏組件',
  'ColorLight': '彩光',
  'ColourInput': '顏色輸入',
  'Colours': '顏色',
  'ColourSettings': '顏色設定',
  'ColourSettings2': '顏色設置',
  'ColourTemperature': '色溫',
  'ColourTemperatureSettings': '色溫設置',
  'ColumnCentre': '列居中',
  'Comma': '，',
  'CompanyName': '公司名稱',
  'CompanyName2': '企業名稱',
  'Comparison': '比較',
  'Completed': '完成',
  'Completed2': '已完成',
  'Component': '組件',
  'Component2': '組件的',
  'ComponentAddedSuccessfully': '組件添加成功',
  'ComponentBrowser': '組件瀏覽器',
  'ComponentCloneSuccessfully': '構件克隆成功',
  'ComponentCollection': '組件收藏',
  'ComponentCreatedSuccessfully': '創建組件成功',
  'ComponentDeleted': '構件已刪除',
  'ComponentDisplay': '組件顯示',
  'ComponentDoesNotSupportPreview': '組件不支持預覽',
  'ComponentDoesNotSupportSharing': '組件不支持分享',
  'ComponentEdit': '組件編輯',
  'ComponentList': '組件列表',
  'ComponentListData': '組件列表數據',
  'ComponentMarket': '組件市場',
  'ComponentMarket2': '翠鳥雲組件市場',
  'ComponentName': '組件名稱',
  'ComponentNotFound': '構件未找到',
  'ComponentProperties': '組件屬性',
  'Condition': '條件',
  'ConfigurationTool': '翠鳥雲二維組態工具',
  'Configure': '配置',
  'Configured': '已配置',
  'ConfigureEntry': '配置項',
  'ConfigureInProjectScript': '請在項目腳本中配置',
  'Confirm': '確定',
  'Confirm2': '確認',
  'Confirm3': '確',
  'Confirm4': '定',
  'ConfirmationOfChangesInExistingRegionalModalities': '是否確認修改現有區域模式',
  'ConfirmChanges': '確認修改',
  'ConfirmExportScene': '確認導出場景',
  'ConfirmThatTheNewPasswordDoesNotMatchTheNewPassword': '確認新密碼與新密碼不一致',
  'ConfirmThatTheNewPasswordIsNotEmpty': '確認新密碼不能為空',
  'ConfirmTheDeletionOfTheEvent': '確認刪除該事件嗎？',
  'Connected': '已連接',
  'ConnectedSuccessfully': '連接成功',
  'Connection': '連接',
  'ConnectionClosed': '連接關閉',
  'ConnectionDisconnected': '連接斷開',
  'ConnectionError': '連接錯誤',
  'ConnectionName': '連接名稱',
  'Consolidated': '綜合',
  'ConstantArray': '常量數組',
  'ConstantFile': '常量文件',
  'ConstantNumber': '常量數字',
  'ConstantString': '常量字符串',
  'Construction': '建築',
  'ConstructionSelection': '建築選擇',
  'ContactUs': '聯繫我們',
  'Contents': '表內容',
  'Continuous': '連續',
  'Control': '控制',
  'ControlBar': '控制欄',
  'Controllable': '可控制',
  'ControlStatus': '控制狀態',
  'ControlSwitches': '控制開關',
  'ConvertToNumbers': '轉換為數字',
  'Copy': '.複製品',
  'Copy2': '複製',
  'Copy3': '複製品',
  'Copy4': '克隆',
  'Copy5': '副本',
  'Copyable': '可複製',
  'CopyComponent': '複製組件',
  'CopyFailedcannotFindTheWidget': '克隆失敗',
  'CopyLink': '複製鏈接',
  'CopyMapRelationship': '複製映射關係',
  'CopyPanel': '複製面板',
  'CopySuccessful': '複製成功',
  'CouldNotMoveUp': '無法上移',
  'Cover': '封面',
  'CoverChart': '封面圖',
  'Create': '創建',
  'CreateAGroup': '創建一個分組',
  'CreateANewSession': '創建新的會話',
  'CreateAProject': '創建一個項目',
  'CreateClassification': '創建分類',
  'CreateCopy': '創建副本',
  'Created': '創建時間',
  'CreateDataSource': '創建數據源',
  'CreatedDeviceData': '已創建設備數據',
  'CreateFieldsAsNewModel': '創建字段為新模型',
  'CreateGroup': '創建分組',
  'CreateItem': '創建項目',
  'CreateOrder': '創建順序',
  'CreatePage': '創建頁面',
  'CreatePlayer': '創建播放器',
  'CreateRequestAgent': '創建請求代理',
  'CreateScene': '創建場景',
  'CreateSheet': '創建工單',
  'CreateSuccessful': '創建成功',
  'Creativity': '工創',
  'CropContents': '裁剪內容',
  'Cross': '交叉',
  'Crossstand': '橫排',
  'Current': '當前的',
  'Current2': '當前',
  'CurrentBrightness': '當前亮度',
  'CurrentColourType': '當前顏色類型',
  'CurrentComponentData': '當前組件數據',
  'CurrentlySelected': '當前選中',
  'CurrentlySelectedDevice': '當前選中的設備',
  'CurrentPage': '當前頁面',
  'CurrentStatus': '當前狀態',
  'CurrentVersion': '當前版本',
  'Curtain001': '窗簾001',
  'CurtainDirection': '窗簾方向',
  'CurtainPosition': '窗簾位置',
  'Curtains': '窗簾',
  'CurtainSpeed': '窗簾速度',
  'Curve': '曲線圖',
  'Curve2': '曲線',
  'Custom': '自定義',
  'Cut': '剪切',
  'Cut2': '裁切',
  'CutComponent': '剪切組件',
  'CutTheHorn': '切角',
  'CylindricalChart': '柱狀圖',
  'Dangerous': '危險',
  'Dark': '暗黑',
  'DarkAsh': '深灰',
  'DarkBlue': '深藍',
  'DarkGrey04': '深灰04',
  'DarkGrey05': '深灰05',
  'Dashboard': '儀表盤',
  'Data': '數據',
  'Data1': '數據1',
  'DataArray': '數據數組',
  'DataComponent': '數據組件',
  'DataConfiguration': '數據配置',
  'DataConfigurationList': '數據配置列表',
  'DataConnection': '數據連接',
  'DataConnectionFailed': '數據連接失敗',
  'DataDevice': '數據設備',
  'DataDeviceComponent': '數據設備組件',
  'DataEnumeration': '數據枚舉',
  'DataError': '數據錯誤',
  'DataFormat': '數據格式',
  'DataFormatMatchingSuccessfully': '數據格式匹配成功',
  'DataMap': '數據映射',
  'DatamapSubscription': '數據映射訂閱',
  'DataNotAvailable': '暫無數據',
  'DataObjectives': '數據目標',
  'DataProperties': '數據屬性',
  'DataPropertiesConfigurationList': '數據屬性配置列表',
  'DataReceived': '收到數據',
  'DataSourceAccessList': '數據源接入列表',
  'DataSourceCannotBeEmpty': '數據源數據不能為空',
  'DataSourceCreatedSuccessfully': '數據源創建成功',
  'DataSourceCreationError': '數據源創建錯誤',
  'DataSourceDeletedSuccessfully': '數據源刪除成功',
  'DataSourceDeletingFailed': '數據源刪除失敗',
  'DataSourceError': '數據源錯誤',
  'DataSourceList': '數據源列表',
  'DataSourceName': '數據源名稱',
  'DataSources': '數據源',
  'DataSourceStandard': '數據源標準',
  'DataSourceUpdateSuccessful': '數據源更新成功',
  'DataType': '的數據類型',
  'DataType2': '數據類型',
  'DataValue': '數據值',
  'Date': '日期',
  'DateRange': '日期範圍',
  'DateSelection': '日期選擇',
  'Day': '天。',
  'Day1': '日',
  'DaysAgo': '天之前',
  'Decimal': '小數',
  'DecimalPlaces': '小數位數',
  'DeepGray03': '深灰03',
  'DeepGrey01': '深灰01',
  'DeepGrey02': '深灰02',
  'Default': '默認',
  'DefaultCamera': '默認相機',
  'DefaultComponent': '默認組件',
  'DefaultComponentWhenTheObjectModelIsNotAssociatedWith': '物模型沒有關聯三維組件時,默認組件',
  'DefaultDevice': '默認設備',
  'DefaultEvents': '默認事件',
  'DefaultFloor': '默認樓層',
  'DefaultLabel': '默認label',
  'DefaultLabel1': '默認LABEL',
  'DefaultLayout': '默認佈局',
  'DefaultModel': '默認模型',
  'DefaultName': '默認名稱',
  'DefaultProperties': '默認屬性',
  'DefaultResourceList': '默認資源列表',
  'DefaultStatusPoint': '默認狀態點',
  'DefaultValue': '默認值',
  'Degrees': '度',
  'Dehumidification': '除溼',
  'Delete': '刪除',
  'Deleted': '已刪除',
  'DeleteItAndFindItInTheWastebin': '刪除後可以在回收站中找到',
  'DeletePermanently': '永久刪除',
  'DeleteSuccess': '刪除成功',
  'DeleteTheRow': '刪除該行',
  'DeletionFailed': '刪除失敗',
  'Depends': '而定',
  'DeploymentDocument': '部署文檔',
  'Descending': '為降序',
  'Description': '描述',
  'DescriptionColour': '描述顏色',
  'Design': '設計',
  'DestructionOf3dComponents': '銷燬三維組件',
  'Details': '詳情',
  'DeterminesThatAllContentsAreEmptied': '是否確定清空所有內容',
  'DeterminesToDeleteItems': '確定刪除項目',
  'DeterminesWhetherThisReleaseLinkShouldBePlacedNext': '是否確定下架此發佈鏈接',
  'DetermineWhetherToDeleteTheDataService': '是否確定刪除該條數據服務',
  'DetermineWhetherToRestore': '是否確定恢復',
  'Device': '個設備',
  'DeviceAlarm': '設備報警',
  'DeviceClassification': '設備分類',
  'DeviceData': '設備數據',
  'DeviceId': '設備id',
  'DeviceLabel': '設備標籤',
  'DeviceList': '場景設備列表',
  'DeviceList2': '設備列表',
  'DeviceManagementPage': '設備管理頁',
  'DeviceName': '設備名稱',
  'DeviceNumber': '設備編號',
  'DeviceNumberToBeFilledIn': '需填入設備編號',
  'DevicePanel': '設備面板',
  'DeviceProperties': '設備屬性',
  'DeviceRepository': '設備庫樣',
  'DeviceStatus': '設備狀態',
  'DeviceTime': '設備為時',
  'DeviceType': '設備類型',
  'DeviceTypeA': '設備類型A',
  'DeviceTypeArray': '設備類型數組',
  'DeviceTypeArrayQueryFailed': '設備類型數組查詢失敗',
  'DeviceTypeB': '設備類型B',
  'DeviceTypeC': '設備類型C',
  'DeviceTypeD': '設備類型D',
  'DeviceTypeE': '設備類型E',
  'DeviceTypeF': '設備類型F',
  'DeviceTypeG': '設備類型G',
  'DifferentTypesOfEquipment': '設備類型不同',
  'DifferentTypesOfEquipmentAreNotSupportedForApplication': '設備類型不同,不支持應用',
  'Digital': '數字人',
  'Dimensions': '尺寸',
  'DirectionLight1': '方向光.1',
  'DirectlyAbove': '正上方',
  'DirectlyBelow': '正下方',
  'Disable': '停用',
  'Disable2': '禁用',
  'DisableGround': '禁用地面',
  'DisaggregatedData': '分類數據',
  'Disconnect': '斷開連接',
  'Disconnected': '未連接',
  'Discontinue': '已下架',
  'Dismantling': '解組',
  'DismantlingFailed': '解組失敗',
  'DismantlingSucceeded': '解組成功',
  'DismissGroupToDefault': '解散分組到默認',
  'DisplayOnBothSides': '兩側顯示',
  'DistortionOfCanvas': '畫布偏移',
  'Document': '文檔',
  'Documentation': '文件',
  'DoesNotSupportFullscreen': '不支持全屏',
  'DoNotAutomaticallyAdjust': '不自動調整',
  'DoNotScroll': '從不滾動',
  'DoNotShowBottom': '不顯示底部',
  'DoNotShowHead': '不顯示頭部',
  'DoNotShowLeft': '不顯示左側',
  'DoNotShowOutside': '不顯示外側',
  'DoNotShowRight': '不顯示右側',
  'Door': '門',
  'DottedLine': '虛線',
  'Double': '雙線',
  'Doubleclick': '雙擊',
  'Down': '下',
  'Down2': '居下',
  'Down3': '向下',
  'Download': '下載',
  'DownloadSuccessful': '下載成功',
  'Draw': '繪製',
  'DrawsideColours': '描邊顏色',
  'DrawTheEdge': '描邊',
  'DrinkingWaterMachine': '飲水機',
  'Duration': '有效期',
  'Edit': '編輯',
  'EditIn': '編輯於',
  'EditMode': '編輯模式',
  'EditModeRotation': '編輯模式旋轉',
  'EditNotSupported': '不支持編輯',
  'Editor': '編輯者',
  'EditorEditMode': '編輯器編輯模式',
  'EditorPreviewMode': '編輯器預覽模式',
  'EditPage': '編輯頁面',
  'EditScript': '編輯腳本',
  'Eight': '8位',
  'EightCameras': '8畫面',
  'Elevator': '電梯',
  'EllipseGradient': '橢圓漸變',
  'EmailFormatError': '郵箱格式錯誤',
  'Emergency': '緊急',
  'Empty': '空',
  'EmptyCanvas': '清空畫布',
  'EmptyModel': '清空物模型',
  'EmptyScene': '空場景',
  'EmptyWastebin': '清空回收站',
  'EmptyWastebinAttemptFailed': '回收站清空失敗',
  'Enable': '啟用',
  'EnableGround': '啟用地面',
  'Encoding': '編碼',
  'EndName': '端點名稱',
  'EndOfThePage': '頁尾',
  'EndpointAlignment': '終點對齊',
  'EndSession': '結束會話',
  'Enter': '進入',
  'EnterAccountName': '填入賬戶名',
  'EnterAccountPassword': '填入賬戶密碼',
  'EnterANewPassword': '輸入新密碼',
  'EnterTheItemCodePlease': '請輸入項目編碼',
  'EnterTheOriginalPassword': '輸入原密碼',
  'Environment': '環境',
  'EqualTo': '等於',
  'Equipment': '設備',
  'Equipment1': '設備1',
  'Equipment2': '設備2',
  'Equipment3': '設備3',
  'Equipment4': '設備4',
  'Equipment5': '設備5',
  'Equipment6': '設備6',
  'Equipment7': '設備7',
  'Equivalence': '等距',
  'EquivalentZoom': '等比縮放',
  'Error': '出錯',
  'Error2': '錯誤',
  'ErrorEnteringInformation': '輸入信息有錯誤',
  'ErrorFetchingDeviceMapData': '獲取設備映射數據出錯',
  'ErrorFetchingTheLatestScenarioScriptParameter': '獲取最新的場景腳本參數出錯',
  'ErrorFindingVersionScriptParameter': '查找版本腳本參數出錯',
  'ErrorInConfiguringCorrespondingDataProvider': '獲取相應數據供應商配置錯誤',
  'ErrorSavingSceneScript': '保存場景腳本出錯',
  'EventAs': '事件為',
  'EventBehaviorLibraryHasBeenLoaded': '事件行為組件庫已經加載',
  'EventHandlingError': '事件處理錯誤',
  'Events': '事件',
  'Events2': '的事件',
  'EventsAfterTheSceneIsLoaded': '場景加載後事件',
  'EventScript': '事件腳本',
  'ExampleMatrix': '示例矩陣',
  'ExitLogin': '退出登錄',
  'ExpandPanel': '展開面板',
  'Export': '導出',
  'ExportScene': '導出場景',
  'ExteriorShadows': '外陰影',
  'External': '外部',
  'ExternalPageInitialization': '外部頁面初始化',
  'ExternalPageLoadError': '外部頁面加載錯誤',
  'ExternalPageLoadingCompleted': '外部頁面加載完成',
  'ExternalPages': '外部網頁',
  'Failed': '失敗',
  'FailedAndDelete': '失敗並刪除',
  'FailedToDeletePage': '刪除頁面失敗',
  'FailedToLoadPageScripts': '加載頁面腳本失敗',
  'FailedToLoadServiceScript': '加載服務腳本失敗',
  'FailedToOpenPostPage': '發佈網頁打開失敗',
  'FailedToSaveResource': '保存資源失敗',
  'FailPleaseContactAdministrator': '失敗，請聯繫管理員',
  'Fault': '故障',
  'FaultState': '故障狀態',
  'FavouriteFolder': '收藏夾',
  'Fetching': '正在抓取',
  'FetchModelData': '獲取模型數據...',
  'Fields': '字段',
  'File': '個',
  'FileDeleteSuccessful': '文件刪除成功',
  'FileName': '文件名稱',
  'FileName2': '文件名',
  'FileReadingFailed': '文件讀取失敗',
  'FileRestorationSuccess': '文件恢復成功',
  'FileSize': '文件大小',
  'FileType': '類型的文件',
  'Fill': '填充',
  'FillingLengthOn': '填寫長度在',
  'FillItUp': '鋪滿',
  'FillMode': '填充模式',
  'FillWith': '填充方式',
  'Fine': '細體',
  'Fine2': '細',
  'Finer': '更細',
  'First': '首個',
  'FitToWindow': '適合窗口',
  'Five': '五',
  'Fixed': '固定',
  'FixedPanel': '固定面板',
  'FixedPosition': '固定位置',
  'FixedWidth': '固定寬度',
  'Float': '浮點',
  'FloatType': '浮點型',
  'Floor': '樓層',
  'FloorList': '樓層列表',
  'Fluid': '流式',
  'FocusZoom': '聚焦縮放',
  'FolderExists': '文件夾已存在',
  'FollowPanel': '跟隨面板',
  'Fonts': '字體',
  'FontSize': '字號',
  'Footer': '頁腳',
  'FooterBackground': '頁腳背景',
  'ForBrowsersThatDoNotSupportGradients': '對於不支持漸變的瀏覽器',
  'ForLargerFileProcessingNeedsPleaseContactUs': '需要更大的文件處理請聯繫我們',
  'Form': '表單',
  'Format': '格式',
  'FormatSelection': '格式化選中',
  'Formatting': '格式化',
  'FormRule': '表單規則',
  'Formula': '公式',
  'ForScene': '中南',
  'Forward': '向前',
  'Foundation': '基礎',
  'Four': '四',
  'FourCameras': '4畫面',
  'FourearlyBorders': '四邊外側邊框',
  'FrameRate': '幀率',
  'FrequencyOfIncomingAndOutgoing': '收發頻率',
  'Friday': '星期五',
  'Front': '前',
  'FrontView': '前視圖',
  'FullScreen': '全屏',
  'FullScreenControl': '全屏控制',
  'FullscreenModeDisabled': '全屏模式被禁用',
  'FunctionTypeError': '函數類型錯誤',
  'Gallery': '圖片庫',
  'GearLayout': '格子布局',
  'General': '常規',
  'GeneralModel': '總模型',
  'GenerateConnections': '生成連接',
  'GenerateLinkOpen': '生成鏈接打開',
  'GetAllThat': '獲取的所有的',
  'GetAnExampleObjectForAPageComponent': '獲取頁面組件實例對象',
  'GetSpaceList': '獲取空間列表',
  'GlobalData': '全局數據',
  'GlobalEvents': '全局事件',
  'GlobalMessage': '全局消息',
  'GoBackToTheHomepage': '返回主頁',
  'GoBackToTheMainPage': '返回主頁面',
  'GoBackToTheNextLevel': '返回上一級',
  'GoToDefinition': '轉到定義',
  'GoToQuote': '轉到引用',
  'GoToSymbol': '轉到符號...',
  'GoToSymbol1': '轉到符號',
  'GradientGradient': '徑向漸變',
  'GreaterThan': '大於',
  'GreaterThanOrEqualTo': '大於等於',
  'Green': '綠色',
  'Green01': '綠色01',
  'Green02': '綠色02',
  'Green03': '綠色03',
  'Green04': '綠色04',
  'Green05': '綠色05',
  'GridAlignment': '網格吸附',
  'GridDisplay': '網格顯示',
  'GridSize': '網格尺寸',
  'Ground': '地面',
  'GroundAreaAndEncircledBox': '地面區域和包圍盒',
  'GroundGeneration': '地臺生成',
  'GroundTemplate': '地面模板',
  'Group': '分組',
  'GroupAs': '分組為',
  'GroupedComponentsNotSupportedForAdditions': '已打組組件不支持添選',
  'Grouping': '打組',
  'GroupingFailed': '打組失敗',
  'GroupingSuccessful': '打組成功',
  'GroupName': '分組名稱',
  'GroupName2': '組名稱',
  'GroupNameCannotBeUsed': '分組名稱不能以',
  'GroupNameRepeat': '組名重複',
  'GroupRename': '分組重命名',
  'Guide': '輔助線',
  'HandleEach': '處理每個',
  'Has': '有',
  'Head': '頭部',
  'Header': '表頭',
  'Header2': '頁頭',
  'HeaderBackground': '頁頭背景',
  'Heating': '制熱',
  'Height': '高度',
  'Height2': '長',
  'HeightChanges': '高度可改變',
  'HeightFill': '高度填充',
  'Help': '幫助',
  'HelpDocument': '幫助文檔',
  'Hide': '隱藏',
  'HideDate': '隱藏日期',
  'HideGrid': '隱藏網格',
  'High': '高',
  'HighSpeed': '高速',
  'HighWind': '高風',
  'Hint': '提示',
  'History': '歷史記錄',
  'HitCache': '命中緩存',
  'HomePage': '主頁',
  'HomePage2': '首頁',
  'HomepageNotCurrentlySet': '當前暫未設置主頁',
  'HomepageNotCurrentlySetCannotBePublished': '當前暫未設置主頁，無法發佈',
  'Horizontal': '橫向',
  'Horizontal2': '水平',
  'HorizontalDispersal': '水平分散',
  'HorizontalPosition': '水平位置',
  'HorizontalTiling': '橫向平鋪',
  'Hours': '小時',
  'Hours2': '時',
  'Hue': '色調',
  'HueBand': '色調帶',
  'Huge': '超大',
  'Icon': '圖標',
  'IconButton': '圖標按鈕',
  'IconLibrary': '圖標庫',
  'IfParametersAreNotSetProperly': '如果參數設置不當',
  'IfTheValueIs': '如果值為',
  'IgnoreMap': '忽略映射',
  'IgnoreProcess': '忽略處理',
  'Image': '頭像圖片',
  'Image2': '畫面',
  'Import': '導入',
  'ImportComponent': '導入組件',
  'ImportDirectly': '直接導入',
  'ImportModel': '導入模型',
  'Index': '索引',
  'IndicatorCard': '指標卡',
  'IndicatorCard1': '指標卡1',
  'IndicatorCard2': '指標卡2',
  'IndicatorCard3': '指標卡3',
  'Indicators': '指標',
  'IndividualIndicators': '單個指標',
  'Information': '信息',
  'InformationAnomaly': '信息異常',
  'InformationError': '信息錯誤',
  'InitialCamera': '初始相機',
  'InitialCamera2': '初始攝像機',
  'InitialEvent': '初始事件',
  'Initialization': '初始化',
  'InitializeComponent': '初始化組件',
  'InitializeScriptManager': '初始化腳本管理器',
  'InitializeTo': '初始化為',
  'InitializeToComponentEditorMode': '初始化為組件編輯器模式',
  'InitializeToHomepageMode': '初始化為首頁模式',
  'InitializeToSceneEditorMode': '初始化為場景編輯器模式',
  'InitializeToSpaceMode': '初始化為空間模式',
  'InitialPosition': '初始位置',
  'InitialPosition2': '初始機位',
  'Inkgreen': '墨綠',
  'InplaceCopy': '原位複製',
  'InsideShadows': '內陰影',
  'InsortTheGrid': '吸附網格',
  'InsufficientPermissions': '權限不足',
  'Integer': '整數',
  'Integer2': '整型',
  'Interaction': '交互',
  'IntercolumnLines': '列間線條',
  'Interface': '界面',
  'InterfaceCreatedSuccessfully': '界面創建成功',
  'InterfaceDocument': '接口文檔',
  'InterfaceSubscriptions': '界面訂閱',
  'Interline': '行間線條',
  'Internal': '內部',
  'Interval': '區間',
  'InTheProcessOfBeingReleased': '發佈中',
  'InvokeRequestQueueSuccessCallback': '調用請求隊列成功回調',
  'IsItTheHomePage': '是否是主頁',
  'IssuanceOfConnectionAccessIssues': '發佈連接訪問次數問題',
  'IsThereUnsavedContentIsItSaved': '有未保存的內容，是否保存？',
  'Italicize': '斜體',
  'Italics': '楷體',
  'ItCanOnlyBeOpenedOnTheHomepage': '只能在主頁開啟',
  'Item': '項目',
  'ItemModelList': '物模型列表',
  'ItllIncreaseYourWaitingTimeConsiderably': '會大幅增加您的等待時間',
  'ItsAlreadyTheBottomLayerItCantMoveDown': '已經是最下面的圖層，無法下移',
  'ItsBeenModifiedPleaseReland': '修改成功，請重新登陸',
  'ItsFromTheStation': '地臺的',
  'ItsTheTopLayerItCantBeMovedUp': '已經是最上面的圖層，無法上移',
  'ItsTimeForTheFloor': '樓層為時',
  'ItsYours': '，由其',
  'IveGotAHeartbeat': '收到心跳',
  'JqueryKit': 'JQuery工具包',
  'Jump': '跳轉',
  'Jump2': '彈跳',
  'JumpAddress': '跳轉地址',
  'JumpToDatamapPage': '跳轉到數據映射頁面',
  'KeepAnEyeOnYourMailbox': '後續請隨時關注您的郵箱',
  'KeepDecimals': '保留小數',
  'KeySeries': '按鍵系列',
  'Kingfisher': '翠鳥',
  'KingfisherCloud': '翠鳥雲',
  'Label': '標籤',
  'Label1': '標籤1',
  'LabelHeight': '標籤高度',
  'LadderDiagram': '階梯圖',
  'Large': '大',
  'LargeDiagrams': '大圖',
  'LastModified': '距上次修改',
  'LastPage': '末頁',
  'Layout': '佈局',
  'Layout01': '佈局01',
  'Layout02': '佈局02',
  'Layout03': '佈局03',
  'Layout04': '佈局04',
  'Layout05': '佈局05',
  'LayoutPreset': '佈局預設',
  'LayoutType': '佈局類型',
  'Left': '左',
  'Left2': '靠左',
  'Left3': '居左',
  'Left4': '左側',
  'LeftAlign': '左對齊',
  'LeftAndRight': '左右',
  'LeftAndRightAdjustments': '左右調整',
  'LeftAndRightExternalBorder': '左右外側邊框',
  'LeftAndRightMargin': '左右邊距',
  'LeftBackground': '左側背景',
  'LeftBottom': '左下',
  'LeftBox': '左邊框',
  'LeftExternalBorder': '左外側邊框',
  'LeftList': '左列表',
  'LeftSlantOverAdjustment': '左斜上調整',
  'LeftToRight': '從左至右',
  'Legend': '圖例',
  'LegendTowards': '圖例朝向',
  'Length': '長度',
  'LengthGreaterThan': '長度大於',
  'LessThan': '小於',
  'LessThanOrEqualTo': '小於等於',
  'Level': '層級',
  'Level2': '等級',
  'LevelOfTheComponent': '組件的的層級',
  'Library': '庫',
  'LibraryClassificationSelection': '庫分類選擇',
  'LibraryFailed': '庫失敗',
  'LibraryGroupSelection': '庫分組選擇',
  'LibraryType': '庫類型',
  'Light': '輔光',
  'Light001': '燈光001',
  'LightBlue': '淺藍',
  'LightColourSet': '燈光顏色設定',
  'LightGrey01': '淺灰01',
  'LightGrey02': '淺灰02',
  'LightGrey03': '淺灰03',
  'LightGrey04': '淺灰04',
  'LightGrey05': '淺灰05',
  'LightQuantifiedList': '輕量化列表',
  'LightQueue': '輕量化隊列',
  'Lights': '總燈光',
  'Lights2': '燈光',
  'Lights3': '燈',
  'LightsOffMode': '關燈模式',
  'LimitedNumberOfProjects': '項目數量受限',
  'Linear': '線性',
  'LinearGradient': '線性漸變',
  'LineChart': '折線圖',
  'LinesBetweenColumns': '行列組間線條',
  'LineSpacing': '行間距',
  'LineWidth': '線寬',
  'List': '列表',
  'ListLayout': '列表佈局',
  'ListOfButtons': '按鈕列表',
  'ListOfCommands': '命令清單',
  'ListOfItems': '項目列表',
  'ListOfPermissions': '權限列表',
  'ListOfServiceProviders': '服務供應商列表',
  'ListOfWorkorder': '工單列表',
  'Load': '加載',
  'Load3dScenes': '加載三維場景',
  'LoadComplete': '加載完成',
  'Loaded': '已載入',
  'LoadedAllComponents': '加載了所有組件',
  'LoadHomePage': '加載主頁',
  'LoadIconLibrary': '加載圖標庫',
  'Loading': '加載中',
  'LoadingFiles': '文件加載中...',
  'LoadingFiles1': '文件加載中',
  'LoadingModel': '模型加載中...',
  'LoadingModel1': '模型加載中',
  'LoadingOfTubeModels': '管線模型加載中...',
  'LoadingOfTubeModels1': '管線模型加載中',
  'LoadingPageScripts': '加載頁面腳本',
  'LoadingProjectScripts': '加載項目腳本',
  'Loading…': '拼命加載中',
  'LoadPage': '加載頁面',
  'LoadPages': '頁面加載',
  'LoadServiceScripts': '加載服務腳本',
  'Lock': '鎖定',
  'LockInTheDirection': '方向鎖定',
  'LodashKit': 'Lodash工具包',
  'Login': '登錄',
  'LoginClosed': '登錄已關閉',
  'LoginFailed': '登錄失敗',
  'LoginIsInvalid': '登錄憑據失效',
  'LoginServerRequired': '需要登錄服務器',
  'LoginSuccessful': '登錄成功',
  'LoginTimedOut': '登錄超時',
  'LoginWithAccount': '使用賬號登錄',
  'LoginWithAccountNumberOtherPerson': '同賬號他人登錄',
  'LookAtTheHeightOfTheBoard': '看板高度',
  'Loop': '循環播放',
  'LoopScroll': '循環滾動',
  'LowercaseLettersAndSpecialCharacters': '小寫字母和特殊字符',
  'LowerRight': '下右',
  'LowerView': '下移一層',
  'LowSpeed': '低速',
  'LowWind': '低風',
  'MainBody': '中查看',
  'MainLightSource': '中添加執行對象',
  'MainLightSource2': '主光源',
  'MaintainDataSources': '維護數據源',
  'Maintenance': '維護',
  'Mandatory': '必須',
  'Manual': '手動',
  'ManualStatus': '手動狀態',
  'MapIsDisabled': '映射已經停用',
  'Material': '材質',
  'Material1': '材質1',
  'Max': '最大值',
  'MaybeItsBecauseSomeoneElseLogsIntoYourAccount': '可能是因為有其他人登錄了您的賬戶,',
  'MediaResourceDataFormatError': '媒體資源數據格式錯誤',
  'Medium': '中',
  'MediumBold': '中粗',
  'MediumDisplay': '中間顯示',
  'MediumLeft': '左中',
  'MediumSpeed': '中速',
  'MemoryUsageClosesToCriticality': '內存使用接近臨界',
  'MemoryUse': '內存使用',
  'MemoryUseBeyondCriticality': '內存使用超過臨界',
  'Message': '消息',
  'MessageList': '消息列表',
  'MessageLog': '消息日誌',
  'MessageReceived': '接收到消息',
  'Methodology': '方法',
  'Microgram': '微圖',
  'Middle': '中間',
  'MiddleDown': '下中',
  'Midline': '中間線',
  'Min': '分',
  'Minimum': '最小值',
  'Mirror': '鏡像畫面',
  'Mode': '模式',
  'Model': '模型',
  'ModelImport': '模型導入',
  'ModelImportComplete': '模型導入完成',
  'ModelImportFailed': '模型導入失敗',
  'ModelImportFailedGltfWith': '模型導入失敗。.gltf與',
  'ModelImportFailedTheSelectionNeedsToBeMadeAtTheSame': '模型導入失敗。需要同時選擇',
  'Modeling': '模型保存中',
  'ModelSaving': '模型保存中...',
  'ModelsLightQuantifiedLevels': '模型輕量化等級',
  'ModelsOfThings': '物模型',
  'ModelType': '模型類型',
  'ModifiedSuccessfully': '修改成功',
  'Modify': '修改',
  'ModifyGroupName': '修改組名稱',
  'ModifyingModesWillClearOriginalData': '修改模式會清除原始區域數據',
  'Monday': '星期一',
  'MonitorSecuritySystem': '監控視頻牆',
  'Monochrome': '單色',
  'Month': '月',
  'MonthlyScope': '月範圍',
  'More': '更多',
  'Moveable': '可移動',
  'MoveDown': '向下移動',
  'MovedSuccessfullyTo': '已成功移動到',
  'MoveFailPleaseContactAdministrator': '移動失敗，請聯繫管理員',
  'MoveGroup': '移動分組',
  'MoveLeft': '向左移',
  'MoveOneLayerDown': '向下移動一層',
  'MoveOneLayerUp': '向上移動一層',
  'MoveRight': '向右移動',
  'MoveSuccessfully': '移動成功',
  'MoveSuccessfullyToViewUnderThisItem': '移動成功，可至該項目下進行查看',
  'MoveToBottom': '移動到最下層',
  'MoveToBottom2': '移動到最下',
  'MoveToGroup': '移動到分組',
  'MoveToItem': '移動到項目',
  'MoveToMyFile': '移動到我的文件',
  'MoveToMyFile2': '可至我的文件',
  'MoveToTop': '移動到最上',
  'MoveUp': '向上移',
  'MoveUpOneLayer': '上移一層',
  'MovingFailed': '移動失敗',
  'MsYahei': '微軟雅黑',
  'MultipleBoxes': '多選框',
  'MultipleDates': '多個日期',
  'MultiscreenSynchronization': '多屏同步',
  'MustBeFilledIn': '必填',
  'Mute': '靜音',
  'MyFiles': '我的文件',
  'Name': '名稱',
  'NameCannotBeEmpty': '名稱不能為空值',
  'NameCannotBeEmpty2': '名稱不能為空',
  'NamedRepeated': '命名重複',
  'NameLength': '名稱長度',
  'NameMayNotBeLongerThan': '名稱長度不能超過',
  'NameMayNotBeLongerThan50Bytes': '名稱長度不能超過50個字節',
  'NameOfComponent': '組件的名稱',
  'NameRepeat': '名稱重複',
  'NavigationBar': '導航欄',
  'NearbyMonitoringScreen': '附近監控畫面',
  'NeedToSelectSimultaneously': '需要同時選擇',
  'NetworkError': '網絡錯誤',
  'New': '新建',
  'NewFloor': '新建樓層',
  'NewFolder': '新建文件夾',
  'NewGroup': '新增分組',
  'NewGroupName': '新組名',
  'NewPage': '新建頁面',
  'NewPage2': '新頁面',
  'NewPasswordFormatError': '新密碼格式錯誤',
  'NewPasswordToIncludeNumbers': '新密碼需包含數字',
  'NewRomanFonts': '新羅馬字體',
  'NewStatus': '新建狀態',
  'NewWindowOpensWebSite': '新窗口打開網址',
  'Next': '下一個',
  'NextPage': '下一頁',
  'NextStep': '下一步',
  'NineCameras': '9畫面',
  'No': '否',
  'NoBackgroundSelected': '未選中背景',
  'NoChangeInData': '數據未發生變化',
  'NoColourSelected': '未選中顏色',
  'NoComponentsFound': '沒有找到組件',
  'NoDefinedAttributeGroupFound': '未找到定義的屬性組',
  'NoDefinedAttributeTypeFound': '未找到定義的屬性類型',
  'Nodes': '節點',
  'NoDeviceBindingDataDiagram': '無設備綁定數據圖',
  'NoEditingAtPresent': '暫無編輯',
  'NoFill': '無填充',
  'NoFlash': '無閃爍',
  'NoGrouping': '未分組',
  'NoHomePage': '無首頁',
  'NoHomepagePleaseSetTheHomePageFirst': '無首頁，請先設置主頁',
  'NoLoginDocumentsFound': '未找到登錄憑據',
  'NoModelOfTheDataSourceFound': '沒有找到該數據源的物模型',
  'NoneditableModeDoesNotAllowDeletion': '非編輯模式不允許刪除',
  'Nonparticipant': '無參',
  'NoReduction': '不減少',
  'NoRegisteredDataSourceOr': '沒有註冊數據源或者',
  'NoRegisteredDataSourceOrProjectid': '沒有註冊數據源或者ProjectId為',
  'Normal': '正常',
  'Normal2': '普通',
  'NormalThickness': '正常粗細',
  'NoRoundCornerSelected': '未選中圓角',
  'NoScriptExecution': '沒有腳本執行',
  'NoStatusSet': '沒有設置狀態',
  'NotAchieved': '未實現',
  'NotAdded': '未添加',
  'NotAllowed': '不允許',
  'NotBold': '不加粗',
  'NotClassified': '未分類',
  'NotConfigured': '未配置',
  'NotEqualTo': '不等於',
  'NoTiling': '不平鋪',
  'NotLoadedSuccessfully': '未加載成功',
  'NotOnboard': '未上架',
  'NotSelected': '未選中',
  'NoVisibilitySetup': '沒有設置顯隱',
  'NumberOfDataSources': '數據源數量',
  'NumberOfSides': '面數',
  'Numbers': '數字',
  'NumbersToBoolean100': '數字轉布爾100',
  'NumericalTurnToBoolean': '數字轉布爾',
  'NumericalTypeTransferBoolean': '數字類型轉布爾',
  'NumericColours': '數值顏色',
  'Object': '對象',
  'Objective': '目標',
  'ObliqueView': '斜視圖',
  'ObtainModelData': '獲取模型數據',
  'Of': '的',
  'Off': '關',
  'Office': '辦公室',
  'Official': '官方',
  'OfficialComponent': '官方組件',
  'OfficialContent': '官方內容',
  'Offline': '離線',
  'Offline2': '下線',
  'Offset': '偏移',
  'OnBoard': '上架',
  'OnBoard2': '已上架',
  'One': '一',
  'OneOf': '一個',
  'Online': '上線',
  'Online2': '在線',
  'OnlineHelp': '在線幫助',
  'OnlineStatus': '在線狀態',
  'Opacity': '不透明度',
  'Open': '開啟',
  'Open2': '開',
  'Open3': '打開',
  'OpenIt': '開',
  'OpenLink': '打開鏈接',
  'OpenText': '開啟文本',
  'OperateFailPleaseContactAdministrator': '操作失敗，請聯繫管理員',
  'Operation': '操作',
  'Operation2': '運算',
  'OperationFailed': '操作失敗',
  'Operations': '業務',
  'OperationsPerformedOnComponentList': '對組件列表執行的操作',
  'OperationStatus': '運轉狀態',
  'OperationSuccessful': '操作成功',
  'OptimalPerformance': '效果最優',
  'Optional': '可選中',
  'Orange': '橙色',
  'Orange01': '橙色01',
  'Orange02': '橙色02',
  'Orange03': '橙色03',
  'Orange04': '橙色04',
  'Orange05': '橙色05',
  'Orientation': '方向',
  'OriginalGroupName': '原組名',
  'OriginalSize': '原生大小',
  'OrUpdateDataAnomaly': '或更新數據異常',
  'Other': '其他',
  'OurContactInformation': '我們的聯繫方式',
  'Outcome': '結果',
  'Overline': '上劃線',
  'Overwrite': '覆蓋',
  'Page': '頁面',
  'Page2': '頁',
  'PageCopyFailed': '頁面複製失敗',
  'PageCopySuccessful': '頁面複製成功',
  'PageCreatedSuccessfully': '頁面創建成功',
  'PageDeleteSuccessful': '頁面刪除成功',
  'PageDeletingFailed': '頁面刪除失敗',
  'PageFillMode': '頁面填充模式',
  'PageIsTimed': '頁面為時',
  'PageList': '頁面列表',
  'PageMenu': '頁面菜單',
  'PageName': '頁面的名稱',
  'PageName2': '頁面名稱',
  'PageSaveCompleted': '頁面保存完成',
  'PageSaveFailed': '頁面保存失敗',
  'PageScript': '頁面腳本',
  'PageServiceModelNotFound': '頁面服務模型沒有找到',
  'Panel': '面板',
  'PanelName': '面板名稱',
  'PanelPackagingType': '面板容器類型',
  'ParameterError': '參數錯誤',
  'ParameterIs': '參數為',
  'ParameterIsEmpty': '參數為空',
  'Parameters': '參數',
  'ParametersForNonarrayType': '非數組類型參數',
  'ParameterUndefined': '參數未定義',
  'Park': '停車',
  'Password': '密碼',
  'PasswordCannotBeEmpty': '密碼不能為空',
  'Paste': '粘貼',
  'PasteComponent': '粘貼組件',
  'Path': '路徑',
  'Pattern': '圖案',
  'Pause': '暫停',
  'PauseStatus': '暫停狀態',
  'Percentage': '百分比',
  'PerformSaveAs': '執行另存為',
  'Permanent': '永久',
  'PermanentlyValid': '永久有效',
  'Permissions': '權限',
  'Perspective': '視角',
  'Perspective2': '側視圖',
  'PerspectiveAddSuccess': '視角添加成功',
  'PerspectiveDeleted': '視角已刪除',
  'PerspectiveMode': '透視模式',
  'PerspectiveView': '透視圖',
  'PhoneNumber': '位手機號碼',
  'PhoneNumber2': '聯繫電話',
  'PictureAdaptation': '圖片適應',
  'PictureAddress': '圖片地址',
  'PictureComponent': '圖片組件',
  'PictureName': '圖片名稱',
  'PicturePath': '圖片路徑',
  'Pictures': '圖片',
  'PictureScale': '圖片比例',
  'Pipe': '管線',
  'Pipe2': '管件',
  'PipeDoesntSupportRotationForAWhile': '管線暫不支持旋轉',
  'PipeRadius': '管半徑',
  'Play': '播放',
  'PlayQuick': '播放倍速',
  'PleaseAddAnExecutoryFunction': '請添加執行函數',
  'PleaseCheckThatTheReleaseLinkIsCorrect': '請檢查發佈鏈接是否正確',
  'PleaseCheckTheApplicationInformation': '請您核對申請信息',
  'PleaseCheckYourEmail': '請注意查收郵件',
  'PleaseChoose': '請選擇',
  'PleaseConfigureDataServices': '請配置數據服務',
  'PleaseConfirmIfYouWantToContinue': '請確認是否繼續',
  'PleaseContactTheAdministrator': '請聯繫管理員',
  'PleaseContactTheAdministratorToImproveAccess': '請聯繫管理員提升權限',
  'PleaseContactThePublisherOfTheConnection': '請聯繫連接發佈作者',
  'PleaseContactThePublisherToUpdateThePublisherConnection': '請聯繫連接發佈作者更新發布連接',
  'PleaseEnter': '請輸入',
  'PleaseEnterADescriptionOfTheModel': '請輸入模型描述',
  'PleaseEnterAnAccountNumber': '請輸入賬號',
  'PleaseEnterAName': '請輸入名稱',
  'PleaseEnterSearchContent': '請輸入搜索內容',
  'PleaseEnterTheAuthor': '請輸入作者',
  'PleaseEnterTheCompany': '請輸入公司',
  'PleaseEnterTheGroupName': '請輸入分組名稱',
  'PleaseEnterTheJob': '請輸入職務',
  'PleaseEnterTheMailbox': '請輸入郵箱',
  'PleaseEnterTheNameOfTheComponent': '請輸入組件名稱',
  'PleaseEnterTheNameOfTheItem': '請輸入項目名稱',
  'PleaseEnterTheOption': '請輸入必選項',
  'PleaseEnterThePassword': '請輸入密碼',
  'PleaseEnterThePhone': '請輸入電話',
  'PleaseEnterTheUser': '請輸入用戶',
  'PleaseEnterYourName': '請輸入姓名',
  'PleaseFillIn': '請填寫',
  'PleaseFillInTheProjectName': '請填寫項目名',
  'PleaseFillInTheSceneName': '請填寫場景名稱',
  'PleaseFollowYourMailboxAsSoonAsPossible': '已提交成功,後續請隨時關注您的郵箱',
  'PleaseLogBackIn': '請重新登陸',
  'PleaseMoveTo': '請移至',
  'PleaseMoveToLibrary': '請移至庫',
  'PleaseReenter': '請重新輸入',
  'PleaseReenterAndTryAgain': '請重新登錄再嘗試',
  'PleaseRelogIn': '請重新登錄',
  'PleaseReturnLogin': '請返回登錄',
  'PleaseSelectAComponentFirst': '請先選擇一個組件',
  'PleaseSelectADate': '請選擇日期',
  'PleaseSelectAFileFirst': '請先選擇文件',
  'PleaseSelectAPageFirst': '請先選擇一個頁面',
  'PleaseSelectAPicture': '請選擇圖片',
  'PleaseSelectTheComponentsThatHaveNotBeenGroupedFirst': '請先選擇未進行打組的組件',
  'PleaseSelectTheDataSourceNodeThatNeedsToBeSwitched': '請選中需要切換的數據源節點',
  'PleaseSelectTheItemFirst': '請先選擇項目',
  'PleaseSelectTheVideo': '請選擇視頻',
  'PleaseSetGroup': '請設置分組',
  'PleaseSetTheHomePageFirst': '請先設置主頁',
  'PleaseSetTheMainPage': '請設置主頁面',
  'PleaseTryAgainLater': '請稍後再試',
  'PleaseUploadTheFileFirst': '請先上傳文件',
  'PleaseWait': '請等待',
  'PleaseWaitForTheFileToBeProcessed': '請等待文件處理完成',
  'Point': '點狀',
  'PointLink': '點位連線',
  'PointsheetDataUploadSuccessfully': '點表數據上傳成功',
  'PointTableData': '點表數據',
  'PopChart': '波普圖',
  'Position': '位置',
  'Positioning': '定位',
  'PositioningToBindingDevice': '定位到綁定的設備',
  'PositioningToComponent': '定位到組件',
  'PostedSuccessfully': '發佈成功',
  'PostEffect': '後期',
  'Posteffect2': '後期效果',
  'PostLinksCopiedToClipboard': '發佈鏈接已複製到剪貼板',
  'Preset': '預設',
  'Preview': '預覽',
  'PreviewMode': '預覽模式',
  'PreviousPage': '上一頁',
  'PreviousStep': '上一步',
  'Primary': '主要',
  'PriorTo': '年之前',
  'Private': '僅自己',
  'Processing': '處理',
  'ProductName': '產品名稱',
  'ProgressChart': '進度圖',
  'ProjectDescription': '項目描述',
  'ProjectInformationRetrievalFailed': '獲取項目信息失敗',
  'ProjectInitializationCompleted': '項目初始化完成',
  'ProjectName': '項目名',
  'ProjectScript': '項目腳本',
  'ProjectScriptInitializationError': '項目腳本初始化錯誤',
  'ProjectScriptLoadFailed': '加載項目腳本失敗',
  'ProjectSettings': '項目設置',
  'Properties': '屬性',
  'Properties1': '屬性1',
  'PropertiesFields': '屬性字段',
  'PropertiesSettings': '屬性設置',
  'ProvisionalVersion': '臨時版本',
  'PublicLayer': '公共層',
  'PublishConnectionDownedOrTimeDue': '發佈連接已下線或時間到期',
  'PublishFail': '發佈失敗',
  'PublishFailPleaseContactAdministrator': '發佈失敗，請聯繫管理員',
  'Publishing': '發佈中...',
  'Purple': '紫色',
  'Purple01': '紫色01',
  'Purple02': '紫色02',
  'Purple03': '紫色03',
  'Purple04': '紫色04',
  'Purple05': '紫色05',
  'Query': '查詢',
  'Question?': '？',
  'Quick': '快速',
  'Quit': '退出',
  'QuitEditingMode': '退出編輯模式',
  'ReadFile': '讀取文件',
  'Received': '收到',
  'RecentlyDeleted': '最近刪除',
  'RecentlyEdited': '最近編輯',
  'RecentlyUsed': '最近使用',
  'ReconfirmTheNewPassword': '再次確認新密碼',
  'Rectangle': '矩形',
  'Red': '紅色',
  'Red01': '紅色01',
  'Red02': '紅色02',
  'Red03': '紅色03',
  'Red04': '紅色04',
  'Red05': '紅色05',
  'Redo': '重做操作',
  'ReducedToGlobal': '全局減面至',
  'Refresh': '刷新',
  'RefreshSuccess': '刷新成功',
  'Refrigeration': '製冷',
  'Region': '地區',
  'Regional': '區域',
  'Release': '發佈',
  'ReleaseStatus': '發佈狀態',
  'RemainingQuantityToday': '今日剩餘數量',
  'RememberPassword': '記住密碼',
  'RemoveCollection': '刪除收藏',
  'RemoveModel': '刪除物模型',
  'RemovePanel': '刪除面板',
  'RemoveProperties': '刪除屬性',
  'Rename': '重命名',
  'RenderInformation': '渲染信息',
  'RenderingEvent': '渲染事件',
  'Replace': '替換',
  'ReplaceAll': '替換所有',
  'Reposition': '復位',
  'Request': '請求',
  'RequestFailed': '請求失敗',
  'RequestSendFailed': '發送請求失敗',
  'RequestSuccessful': '請求成功',
  'Reset': '重置',
  'Resolution': '分辨率',
  'ResourceName': '資源名稱',
  'Resources': '資源',
  'Restore': '恢復',
  'RetrievalFailed': '獲取失敗',
  'Retrieved': '獲取的',
  'RetrieveReturnedComponentFilterValues': '獲取返回的組件過濾值',
  'RetrieveReturnedDashboardFilterValues': '獲取返回的儀表盤過濾值',
  'Returns3dScene': '返回三維場景',
  'ReturnsDataMap': '返回數據映射',
  'ReturnToHomePage': '返回首頁',
  'Right': '右側',
  'Right2': '右',
  'Right3': '右對齊',
  'Right4': '居右',
  'RightAllignment': '靠右對齊',
  'RightBackground': '右側背景',
  'RightBottom': '右下',
  'RightClick': '右擊',
  'RightInTheMiddle': '正中間',
  'RightKeyMenu': '右鍵菜單',
  'RightLeft': '右左',
  'RightOutsideBorder': '右外側邊框',
  'RightToLeft': '從右至左',
  'Ring': '環形',
  'Rotable': '可旋轉',
  'Rotate': '旋轉',
  'RotateAngle': '旋轉角度',
  'RotateClockwise': '順時針旋轉',
  'RotateCounterclockwise': '逆時針旋轉',
  'RoundCorner': '圓角',
  'RoundedRectangle': '圓角矩形',
  'RouteConfiguration': '路由配置',
  'RowSelection': '行選中',
  'RulemapView': '規則映射視圖',
  'Run': '運行',
  'RunModeSwitch': '運行模式切換',
  'Running': '運行中',
  'SameOrder': '順序一致',
  'SatisfactoryValue': '滿足值',
  'Saturation': '飽和度',
  'Saturday': '星期六',
  'Save': '另存',
  'Save2': '保存',
  'SaveAs': '保持常在',
  'SaveAs2': '另存為',
  'SaveAsName': '另存為名稱',
  'SaveBlueprints': '保存藍圖',
  'SaveFailed': '保存失敗',
  'SaveProject': '保存項目',
  'SaveSceneAs': '場景另存為',
  'SaveSuccessful': '保存成功',
  'Scale': '縮放比例',
  'Scale2': '比例縮放',
  'ScaleBefore': '縮放前',
  'ScaleFill': '比例填充',
  'Scene': '場景',
  'Scene2': '為場景',
  'SceneCreatedSuccessfully': '場景創建成功',
  'SceneDownloading': '場景下載中',
  'SceneExport': '場景導出',
  'SceneFiles': '場景文件',
  'SceneInformation': '場景信息',
  'SceneList': '場景列表',
  'SceneSavedSuccessfully': '場景保存成功',
  'SceneSelection': '場景選擇',
  'ScintillationEffect': '閃爍效果',
  'Scope': '範圍',
  'ScreenGroup': '屏幕組',
  'ScreenRoles': '屏幕角色',
  'Script': '腳本',
  'ScriptData': '腳本數據',
  'ScriptExecutionError': '腳本執行錯誤',
  'ScriptLoadingFailed': '腳本加載失敗',
  'ScriptOrEventError': '腳本或者事件錯誤',
  'Scrollable': '可滾動',
  'ScrollAlways': '始終滾動',
  'ScrollBackAndForth': '來回滾動',
  'Scrollbar': '滾動條',
  'ScrollOnDemand': '按需滾動',
  'Search': '搜索',
  'Search2': '檢索',
  'Sec': '秒',
  'SecondAgo': '秒之前',
  'Sector': '扇形',
  'SelectAGroup': '請選擇一個分組',
  'SelectAll': '全選',
  'SelectAllComponents': '全選組件',
  'SelectAnAttribute': '選擇一個屬性',
  'SelectAnObjectForASpaceComponent': '選擇空間組件對象',
  'SelectARoundCorner': '選中圓角',
  'SelectColour': '選中顏色',
  'Selected': '選中',
  'SelectedDevices': '選中設備',
  'SelectedState': '選中狀態',
  'SelectedValue': '選中值',
  'SelectFile': '選擇文件',
  'Selection': '選擇',
  'SelectLocalFile': '選擇本地文件',
  'SelectModel': '選擇模型',
  'SelectModelGroups': '請選擇模型分組',
  'Selector': '選擇器',
  'SelectTheGroupOnTheLeft': '請選擇左側的分組',
  'Selfadaptation': '自適應',
  'SendAHeartbeat': '發送心跳',
  'SendGlobalEvents': '發送全局事件',
  'SendGlobalEventToAddress': '發送全局事件到地址',
  'SendTestEvent': '發出測試事件',
  'Sensors': '傳感器',
  'SentenceEnd': '。',
  'Sequence': '序列',
  'SerialNumber': '序號',
  'Series': '系列',
  'Serious': '嚴重',
  'ServersNormal': '服務器正常',
  'ServiceInitializationFailed': '服務初始化失敗',
  'ServiceList': '服務列表',
  'ServicePathNotFound': '服務路徑沒有找到',
  'Services': '服務',
  'SessionCreationFailed': '創建會話失敗',
  'Set': '設定',
  'SetAllowedToSpecificLocation': '設置允許到具體位置',
  'SetAsDefaultStatus': '設置為默認狀態',
  'SetComponent': '設置組件',
  'SetComponentProperties': '設置組件屬性',
  'SetGlobalContext': '設置全局上下文',
  'SetPage': '設置頁面',
  'SetParameters': '設置參數',
  'SetScene': '設置場景',
  'SetSpace': '設置空間',
  'SetTemperature': '設定溫度',
  'SetTexture': '設置紋理',
  'SetTheCamera': '設置攝像頭',
  'Settings': '設置',
  'SetupDisplay': '設置顯示',
  'SetupVideoComponent': '設置視頻組件',
  'Shadow': '陰影',
  'Share': '分享',
  'ShiftRightUp': '右斜上調整',
  'ShortchainInformation': '短鏈信息',
  'Shortcut': '快捷方式',
  'ShortcutKeys': '快捷鍵',
  'Show': '顯示',
  'ShowAll': '顯示全部',
  'ShowAll2': '全部顯示',
  'ShowAllGroups': '顯示全部分組',
  'ShowBottom': '顯示底部',
  'ShowContents': '展示內容',
  'ShowHead': '顯示頭部',
  'ShowImmediately': '立即顯示',
  'ShowLeft': '顯示左側',
  'ShowMessage': '顯示消息',
  'ShowOnlyEncircledBoxes': '只顯示包圍盒',
  'ShowOnlyTheGroundArea': '只顯示地面區域',
  'ShowRight': '顯示右側',
  'ShowTheBottomToolbarOfTheSpaceComponent': '顯示空間組件底部工具欄',
  'ShowTitle': '顯示標題',
  'ShowToolbar': '顯示工具條',
  'ShowViewBar': '顯示視圖欄',
  'Side': '側',
  'SimulationData': '模擬數據',
  'Single': '單個',
  'SingleSelectionWithinTheGroup': '組內單選',
  'Six': '六',
  'SixCameras': '6畫面',
  'SixteenCameras': '16畫面',
  'Size': '大小',
  'SizeInUnit': '單位大小',
  'SkipThisGuide': '跳過此引導',
  'Sky': '天',
  'Skyblue': '天藍色',
  'Skyblue01': '天藍色01',
  'Skyblue02': '天藍色02',
  'Skyblue03': '天藍色03',
  'Skyblue04': '天藍色04',
  'Skyblue05': '天藍色05',
  'SkyBox': '天空盒',
  'Slider': '滑動條',
  'Slider2': '滑塊',
  'SlightlyLarger': '偏大',
  'SlowSpeed': '慢速',
  'Small': '小',
  'SmallListLayout': '小列表佈局',
  'SmallSize': '小號',
  'SmartCharts': '智能圖表',
  'Snippets': '片段',
  'Solid': '實線',
  'SomeoneElseHasPossiblyLoggedIntoYourAccount!': '可能是因為有其他人登錄了您的賬戶',
  'SortAlphabetically': '按字母排序',
  'SortBy': '排序方式',
  'SortComponent': '排序組件',
  'Source': '來源',
  'Space': '空間',
  'SpaceComponentAutofocus': '空間組件自動聚焦',
  'SpaceComponentFullScreen': '空間組件全屏',
  'SpaceComponentSearch': '空間組件搜索',
  'SpaceComponentSwitchesToPerspective': '空間組件切換到透視視角',
  'SpaceGovernance': '空間治理',
  'SpaceSurplus': '空間剩餘',
  'Spacing': '間距',
  'SpecialBold': '特粗體',
  'SplitVertically': '垂直分散',
  'SqlIs': 'SQL是',
  'Square': '方形',
  'Square2': '正方形',
  'SsoLoginClosed': 'SSO登錄已關閉',
  'SsoUserNotFound': 'SSO用戶未找到',
  'SsoUserOrPasswordInputError': 'SSO用戶或密碼輸入錯誤',
  'StackLayout': '堆疊佈局',
  'StandardBlue': '標準藍色',
  'StandardColor': '標準色',
  'StandardDeepBlue': '標準深藍色',
  'StandardDeepRed': '標準深紅',
  'StandardGreen': '標準綠色',
  'StandardLightBlue': '標準淺藍',
  'StandardLightGreen': '標準淺綠',
  'StandardOrange': '標準橙色',
  'StandardPurple': '標準紫色',
  'StandardRed': '標準紅色',
  'Standards': '標準',
  'StandardYellow': '標準黃色',
  'StartEditMode': '開啟編輯模式',
  'StartingPointAlignment': '起點對齊',
  'StartProcessing': '開始處理',
  'StartProcessingData': '開始處理數據',
  'StartRequestingData': '開始請求數據',
  'StartSurveillanceEquipment': '開始監視設備',
  'StateName': '狀態名稱',
  'StateSwitch': '狀態切換',
  'Status': '狀態',
  'StatusAsTime': '狀態為時',
  'StatusConfiguration': '狀態配置',
  'StatusIs': '的狀態為',
  'StatusPoint': '狀態點',
  'StatusSelection': '狀態選擇',
  'StatusSettings': '狀態設置',
  'StatusText': '狀態文本',
  'StatusText01': '狀態文本01',
  'StatusText02': '狀態文本02',
  'StatusText03': '狀態文本03',
  'StatusText04': '狀態文本04',
  'Step': '步長',
  'StepperControl': '步進器',
  'StepValue': '步進值',
  'Stop': '停止',
  'StopSurveillanceEquipment': '停止監視設備',
  'StopTheSurveillanceEquipment': '停止監視設備,',
  'StreamingServerAddressNeedsToBeSet': '需要設置流媒體服務器地址',
  'String': '字符串',
  'StringingNumerals': '字符串轉數字',
  'ToolsNumberFormat': '數字格式化(可配置)',
  'numberFormat': '數字格式化',
  'ToolNumberToBoolean': '數字轉布爾(可配置)',
  'numberToBoolean': '數字轉布爾',
  'ToolBooleanToNumber': '布爾轉數字(可配置)',
  'booleanToNumber': '布爾轉數字',
  'StringTypeTurnsNumbers': '字符串類型轉數字',
  'StyleDefinition': '樣式定義',
  'StyleReference': '樣式引用',
  'Styles': '樣式',
  'Subject': '主體',
  'Submit': '提交',
  'SubmittedWithSuccess': '已提交成功',
  'SubmittingSuccessfully': '提交成功',
  'Subparagraph': '分段',
  'Subscriptions': '訂閱的設備',
  'Success': '成功',
  'Succession': '繼承',
  'SucessfullySavedResource': '保存資源成功',
  'Suitable': '合適',
  'Sunday': '星期天',
  'Sunggi!SongTypeface': '宋體',
  'SupplyTemperature': '供應溫度',
  'SupportedModelFormat': '支持的模型格式',
  'Surveillance': '監控',
  'SurveillanceSystem': '監控系統',
  'Suspend': '懸停',
  'SuspendColour': '懸停顏色',
  'Switch': '開關',
  'SwitchedTo': '已切換成',
  'SwitchingDataSources': '切換數據源',
  'SwitchStatus': '開關狀態',
  'SwitchValue': '開關值',
  'SynchronizeChanges': '同步修改',
  'SystemAnomaly': '系統異常',
  'SystemName': '系統名稱',
  'SystemSubtitles': '系統副標題',
  'Tab': '選項卡',
  'TabDisplay': '標籤顯示',
  'Table': '桌',
  'Table2': '表格',
  'TablesOf': '的表',
  'TabText': '標籤文本',
  'Take': '取',
  'TargetValue': '目標值',
  'Telephone': '電話',
  'Temperature': '溫度',
  'Templates': '模板',
  'TenSecondsAgo': '10秒之前',
  'TerminateComponent': '銷燬播放器',
  'TerminateScript': '銷燬腳本',
  'Text': '文本',
  'Text2': '文字',
  'TextColour': '文字顏色',
  'TextContents': '文本內容',
  'TextContents2': '文字內容',
  'TextOrientation': '文字方向',
  'TextShadow': '文字陰影',
  'TextSize': '文字大小',
  'TextZoom': '文字縮放',
  'ThankYouForYourApplication': '感謝您的申請',
  'TheBinFileNameIsDifferent': '.bin文件名不相同',
  'TheBuildingsInTime': '大樓為時',
  'TheCodeHasBeenDestroyedAndCannotBeDeconstructed': '密文已被破壞，無法解析',
  'TheCompanyCantBeEmpty': '公司不能為空',
  'TheComponentsHaveBeenDestroyed': '組件已經銷燬',
  'TheContentsWillBePermanentlyRemovedFromTheWastebin': '這些內容在回收站中將會被永久刪除',
  'TheContentWillBePermanentlyDeleted': '該內容將會被永久刪除',
  'TheContentWillBeRestoredToItsOriginalPosition': '該內容將會被恢復到原來位置',
  'TheCurrentlySelectedDataSource': '當前選中的數據源',
  'TheDatabaseDataUpdateFailed': '翠鳥數據庫數據更新失敗',
  'TheDatabaseDataUpdateWasSuccessful': '翠鳥數據庫數據更新成功',
  'TheDatabaseHasBeenUpgradedSuccessfully': '翠鳥數據庫升級成功',
  'TheDatabaseWasOpenedWrongly': '翠鳥數據庫打開報錯',
  'TheDataFormatMatchesSuccessfully': '數據格式匹配成功,',
  'TheDataIsMissing': '數據丟失啦',
  'TheDigitalManipulative': '數字人解說',
  'TheDimensionIs': '維度是',
  'TheDocumentsAreStillBeingProcessed': '文件還在處理中',
  'TheEmailHasBeenRegistered': '該郵箱已註冊過',
  'TheEngineHasBeenDestroyed': '引擎已經銷燬',
  'TheFileNamesAreDifferent': '文件名不相同',
  'TheFileTypeIsIncorrect': '文件類型不正確',
  'TheGroupAlreadyExists': '該分組已存在',
  'TheIndicatorsAre:': '指標是',
  'TheLibraryCantBeEmpty': '庫分類不能為空',
  'TheLightOfTheDirection': '方向光',
  'TheLinkIsDisabled': '該鏈接已失效',
  'TheMailboxCantBeEmpty': '郵箱不能為空',
  'TheMaximumNumberOfUploadsOfLightquantifiedModelsToday': '今天輕量化模型上傳數量已達上限',
  'Theme': '主題',
  'ThemeColor': '主題色',
  'ThemeColour': '主題顏色',
  'ThemeConfigurationList': '主題配置列表',
  'ThemeSide': '主題描邊',
  'ThemeSwitch': '主題切換',
  'TheModelLimitsTheSizeOfTheFile': '模型限制文件體積在',
  'TheModelsTimeRangedFromAFewDozenSecondsToAFewMinutes': '模型所需時間從幾十秒到幾十分鐘不等',
  'TheNameCantBeEmpty': '姓名不能為空',
  'TheNameOfTheFileIsDifferentFromThatOfBin': '與.bin文件名不相同',
  'TheNewPasswordCannotBeEmpty': '新密碼不能為空',
  'TheNumberCantBeEmpty': '電話號碼不能為空',
  'TheObjectModelConnectsToThe3dModel': '物模型連接三維模型',
  'TheOldPasswordCantBeEmpty': '舊密碼不能為空',
  'TheOldVersionOfThePipeCouldNotBeEdited': '舊版本管線無法再編輯',
  'TheOnlyKeyOfTheComponents': '組件的唯一key',
  'TheOnlyOneOfTheComponents': '組件的唯一',
  'TheParameterIsEmptyPleaseCheckTheApplicationInformation': '參數為空，請您核對申請信息',
  'ThePlatform': '地臺',
  'ThePublicLayerCanOnlyBeOpenedOnTheHomePage': '公共層只能在主頁開啟',
  'ThePuzzleHasBeenLoaded': '翠鳥拼圖已經加載',
  'ThereIsNoChangeInTheData': '數據未發生變化,',
  'ThereIsNoHomePageForTheCurrentItem': '當前項目中沒有主頁面',
  'TheRequestFailed': '請求失敗,',
  'TheresAnAnomalyInTheSystemTryAgainLaterPlease': '系統異常，請稍後再試',
  'TheresAProblemWithTheServiceConfiguration': '服務配置存在問題',
  'TheresNoWayToFindIt': '方法沒有找到',
  'TheSceneHasNotYetBeenInitializedPleaseWait': '場景尚未初始化完畢，請等待',
  'TheSceneHasntBeenInitializedYet': '場景尚未初始化完畢',
  'TheSceneIsBeingDownloadedPleaseWait': '場景下載中...請等待',
  'TheWatchboardDrags': '看板拖動',
  'TheWatchboardSpreadsOut': '看板展開',
  'Thick': '粗細',
  'ThirtyDays': '30天。',
  'ThisEmailHasCompletedItsApplication': '此郵箱已完成申請',
  'ThisEmailIsAlreadyRegistered': '此郵箱已註冊',
  'ThisMailboxHasCompletedItsApplicationPleaseCheckThe': '此郵箱已完成申請，請注意查收郵件',
  'ThisMailboxIsRegisteredPleaseReturnToLogin': '此郵箱已註冊，請返回登錄',
  'ThisOperationCannotBeRestored': '該操作無法恢復',
  'ThousandbitComma': '千分位逗號',
  'ThousandsOfSpaces': '千分位空格',
  'Three': '三',
  'ThroatSlider': '色調滑塊',
  'Thursday': '星期四',
  'TieItToTheCamera': '綁定到攝像頭',
  'Tiled': '平鋪',
  'Tilt': '傾斜',
  'Time': '時間',
  'Timeconsuming': '耗時',
  'Timeout': '超時',
  'TimePointDate': '時間點日期',
  'TimePointDateRange': '時間點日期範圍',
  'Title': '標題',
  'TitleColour': '標題顏色',
  'TitleDisplay': '標題顯示',
  'TitleSize': '標題大小',
  'TitleText': '標題文字',
  'To': '到',
  'ToCurrentTab': '到當前頁籤',
  'Today': '今天',
  'Toggle': '切換',
  'ToggleDisplayModelType': '切換顯示模型類型',
  'ToggleDisplayStatus': '切換顯示狀態',
  'ToggleRunningMode': '切換運行模式',
  'ToggleSpaceComponentSpace': '切換空間組件空間',
  'TokenValueObtained': '獲取的token值',
  'ToNewTab': '到新頁籤',
  'Toolbar': '工具條',
  'Toolbars': '工具欄',
  'Toolkit': '工具包',
  'ToOverwriteRawRectangleData': '會覆蓋原始矩形數據',
  'Top': '置頂',
  'Top&bottomBorder': '上下外側邊框',
  'Top2': '居上',
  'Top3': '頂',
  'Top4': '頂部',
  'Top5': '居頂',
  'TopBorder': '上邊框',
  'TopDown': '下上',
  'TopdownMargin': '上下邊距',
  'TopLeft': '左上',
  'TopOuterBorder': '上部外側邊框',
  'TopRight': '右上',
  'TopView': '頂視圖',
  'TotalMaterial': '總材質',
  'TotalNumberOfPolygons': '多邊形總數',
  'TotalTexture': '總貼圖',
  'ToTheRight': '靠右',
  'Transparency': '透明度',
  'Triangle': '三角形',
  'TriggerEvent': '觸發事件',
  'TrinityDatabaseDataDeleteSuccessfully': '翠鳥數據庫數據刪除成功',
  'TrinityDatabaseDataWriteSuccessfully': '翠鳥數據庫數據寫入成功',
  'TrinityDatabaseDataWritingFailed': '翠鳥數據庫數據寫入失敗',
  'TrinityDatabaseOpenSuccessfully': '翠鳥數據庫打開成功',
  'TrinityPuzzleUnmounted': '翠鳥拼圖已卸載',
  'TronV4': '工創4',
  'TryIt': '嘗試一下',
  'TryMap': '嘗試映射',
  'Tuesday': '星期二',
  'TurnOnTheDataLink': '開啟數據鏈接器',
  'TurnOnTheFocus': '開啟聚焦',
  'TurnOnTheGrid': '開啟網格',
  'TurnOnThePanel': '開啟面板',
  'Tutorial': '教程',
  'TutorialVideo': '教程視頻',
  'Two': '二',
  'Type': '類型',
  'Type2': '類型為',
  'TypeConversion': '類型轉換',
  'TypeOfPumpEquipment': '水泵設備類別',
  'UnableToConnectToServer': '無法連接服務器',
  'UnableToLoadModule': '無法加載模塊',
  'UnableToMoveDown': '無法下移',
  'UnableToObtainRelatedDataExplanationInformation': '無法獲取相關數據解釋信息',
  'UnableToParse': '無法解析',
  'UnableToPublish': '無法發佈',
  'UnableToQueryRelatedData': '無法查詢到相關數據',
  'Undefined': '未定義',
  'UndefinedGlobalEvent': '未定義的全局事件',
  'Underline': '下劃線',
  'UndoOperation': '撤回操作',
  'Ungrouped': '未打組',
  'UniInterfaceSubscriptions': 'UI界面訂閱',
  'Units': '單位',
  'UnitText': '單位文本',
  'Unknown': '未知',
  'UnknownDevice': '未知設備',
  'UnknownService': '未知服務',
  'UnloadedScene': '未加載場景',
  'UnmountPage': '卸載頁面',
  'UnmountPageEventListening': '卸載頁面事件監聽',
  'Unnamed': '未命名',
  'UnsavedContents': '有未保存的內容',
  'Unselect': '取消選擇',
  'Up': '向上',
  'Up2': '上',
  'UpAndDown': '上下',
  'UpAndDownAdjustment': '上下調整',
  'Update': '更新',
  'UpdateComponent': '更新組件',
  'Updated': '更新了',
  'UpdateDataSources': '更新數據源',
  'UpdateDate': '更新日期',
  'UpdateFailed': '更新失敗',
  'UpdateFailPleaseContactAdministrator': '更新失敗，請聯繫管理員',
  'UpdateOfComponentSuccessfully': '更新組件成功',
  'UpdateSuccessful': '更新成功',
  'UpdateTime': '更新時間',
  'UpdatingOfThePublicWebSite': '更新公共網址',
  'UpgradeServiceRequestProcessingToVersion': '服務請求處理升級為版本',
  'Upload': '上傳',
  'UploadDevicePointTable': '上傳設備點表',
  'UploadedByMe': '我上傳的',
  'UploadFailed': '上傳失敗',
  'UploadFile': '上傳文件',
  'UploadingFailedFileTypeIsIncorrect': '上傳失敗，文件類型不正確',
  'UploadPointTableData': '上傳點表數據',
  'UploadSuccessful': '上傳成功',
  'UpperLeft': '上左',
  'UpperMiddle': '上中',
  'UpperRight': '上右',
  'User': '用戶',
  'UserAccountExpired': '用戶賬號已過期',
  'UserComponent': '用戶組件',
  'UserContents': '用戶內容',
  'UserDoesNotExist': '用戶不存在',
  'UserGroup': '用戶組',
  'UserHasNoPrivilegePleaseContactAdministrator': '用戶權限不足，請聯繫管理員提升權限',
  'UserIdentity': '用戶身份',
  'UserManual': '使用文檔',
  'UserManual2': '用戶手冊',
  'Username': '用戶名',
  'UserName2': '用戶名稱',
  'UsernameCannotBeEmpty': '用戶名不能為空',
  'UsernameOrPasswordCannotBeEmpty': '用戶名或密碼不能為空',
  'UsernameOrPasswordError': '用戶名或者密碼錯誤',
  'UserNotFound': '用戶未找到',
  'UserNotRegistered': '用戶尚未註冊',
  'UserOrPasswordInputError': '用戶或密碼輸入錯誤',
  'UserPermissionInsufficient': '用戶權限不足',
  'UserSettings': '用戶設置',
  'UserSRemainingSpaceIsNotEnough': '用戶剩餘可用空間不足',
  'ValidateFail': '驗證失敗,',
  'Validator': '驗證器',
  'Value': '值',
  'Value2': '數值',
  'ValueAs': '值為',
  'ValueData': '值數據',
  'ValueDisplay': '數值顯示',
  'ValueSize': '值大小',
  'ValueWhen': '數值為時',
  'Vendors': '供應商',
  'VerificationFailed': '驗證失敗',
  'Version': '版本',
  'VersionNumber': '版本號',
  'Vertical': '豎向',
  'Vertical2': '垂直',
  'Vertical3': '縱向',
  'VerticalAlignment': '垂直對齊',
  'VerticalFlatten': '縱向平鋪',
  'VerticalLayout': '豎排',
  'VerticalPosition': '垂直位置',
  'VerticalText': '垂直文本',
  'Video': '視頻',
  'VideoFiles': '視頻文件',
  'VideoLibrary': '視頻庫',
  'VideoName': '視頻名稱',
  'VideoPath': '視頻路徑',
  'VideoSource': '視頻源',
  'VideoTutor': '視頻教程',
  'VideoWall': '視頻牆',
  'View': '視圖',
  'ViewDown': '下查看',
  'Viewer': '查看者',
  'ViewingInTheProject': '項目中進行查看',
  'ViewList': '視圖列表',
  'ViewOperations': '視圖操作',
  'ViewSceneInformation': '查看場景信息',
  'ViewScenes': '查看場景',
  'ViewVersion': '查看版本',
  'Visibility': '可見性',
  'VisibleSettings': '顯隱設置',
  'Voltage': '電壓',
  'Volume': '音量',
  'VueToolkit': 'Vue工具包',
  'Wait': '等待',
  'Wall': '牆',
  'Waltung': '華東',
  'Warm': '暖色',
  'WarningStatus': '告警狀態',
  'Wastebin': '回收站',
  'WastebinEmptiedSuccessfully': '回收站清空成功',
  'WastebinResourceListNetworkRequestError': '回收站資源列表網絡請求錯誤',
  'WatchTheBoard': '看板',
  'WaterTanks': '水箱',
  'Weather': '天氣',
  'WebAddress': '網頁地址',
  'WebJump': '網頁跳轉',
  'WebPage': '網頁',
  'Website': '網址',
  'WechatLoginFailed': '微信登錄失敗',
  'WechatLoginFailUnknowService': '微信登錄失敗，未知服務',
  'Wednesday': '星期三',
  'Week': '周',
  'Week2': '星期',
  'WellKeepYouFor30Days': '我們將為您保留30天',
  'WellKeepYouFor30Days1': '，我們將為您保留30天。',
  'WellReserveItForYou': '我們將為您保留',
  'WereStartingToWatchTheEquipment': '開始監視設備,',
  'WhatAreTheParameters': '參數是',
  'When': '時，',
  'When1': '當',
  'WhenTheObjectModelDoesntConnectToThe3dComponent': '物模型沒有關聯三維組件時',
  'WhetherOrNotFullscreen': '是否全屏',
  'WhetherOrNotToSave': '是否保存',
  'WhetherOrNotToShowScrollbars': '是否顯示滾動條',
  'WhetherToDeleteThisFile': '是否刪除此文件',
  'WhetherToShowToolbars': '是否顯示工具條',
  'White': '亮白',
  'White2': '白色',
  'WhiteLight': '白光',
  'Width': '寬',
  'WidthCanChange': '寬度可改變',
  'WidthFill': '寬度填充',
  'Window': '窗',
  'WindSpeed': '風速',
  'Within': '以內',
  'WordSpacing': '字間距',
  'WordWeights': '字重',
  'Workorder': '工單',
  'WorkorderInformation': '工單信息',
  'WorkorderName': '工單名稱',
  'Wrap': '環繞',
  'WrongNumber': '電話號碼錯誤',
  'Year': '年',
  'Yellow': '黃色',
  'Yellow01': '黃色01',
  'Yellow02': '黃色02',
  'Yellow03': '黃色03',
  'Yellow04': '黃色04',
  'Yellow05': '黃色05',
  'Yes': '是',
  'YouAlreadyLogOutOfTheSystem': '您已經登出系統,',
  'YouAreAlreadyOnTheSystem': '您已經登出系統',
  'YouCanOnlyUploadIt': '只能上傳',
  'YouHaveBeenLoggedOut': '您被迫下線',
  'YouHaveNotObtainedAProductAuthorization': '您尚未獲取產品授權',
  'YouHaventOperatedForTooLong': '您因為太久沒有操作,',
  'YouHaventOperatedForTooLong1': '您因為太久沒有操作',
  'YouHaveToChooseAProject': '必須要選擇一個項目',
  'YourAccountHasBeenEnteredElsewhere': '您的賬號已在其他環境登錄',
  'YourAccountHasBeenEnteredElsewhereAndYouAreForcedOffline': '您的賬號已在其他環境登錄，您被迫下線',
  'YourAccountHasBeenEnteredInAnotherDevice': '您的賬號已在其他設備登錄',
  'YourAccountHasBeenEnteredInAnotherDeviceAndYouAreForced': '您的賬號已在其他設備登錄，您被迫下線',
  'Zoom': '縮放',
  'ZoomIn': '放大',
  'ZoomOut': '縮小',
  '[Script]': '[腳本',
  'LanguageSwitching':'語言切換',
  'Context':'内容',
  'MousePenetration':'鼠標穿透',
  'CannotExitLogin':'無法推出登錄',
  'DevicePointTable': '設備點表',
  'Add1': '添加',
  'Programme': '方案',
  'ProgrammeList': '方案列表',
  'CreateProgramme': '創建方案',
  'ImportProgramme': '導入方案',
  'UnbindObjectModel': '解綁物模型',
  'DataEnumerationConversion':'數據枚舉轉換',
  'Furniture':'家具',
  'AreYouSureYouWantToSwitchLanguagesAndReload': '確定要切換語言並重新加載頁面嗎？',
  'DialogList': '彈窗列表',
  'AddDialog': '新增彈窗',
  'DialogCreatedSuccessfully': '彈窗創建成功',
  'DialogCopySuccess': '彈窗複製成功',
  'DialogCopyFailed':'弹窗复制失敗',
  'EditDialog': '編輯彈窗',
  'Modal':'遮罩',
  'IsDraggable':'是否可拖拽',
  'Hyperlink':'超鏈接',
  'ProgressBar':'進度條',
  'ProgressLoop':'進度環',
  'ApplicationPointTableDeviceName':'應用點表設備名稱',
  'Connector':'連綫',
  'PanelEvent':'面板事件',
  'PanelSelect':'面板選擇',
  'IsConnector':'是否連綫',
  'D1': '1天',
  'D7': '7天',
  'D30': '30天',
  'Configuration':'組態',
  'Artist':'藝術家',
  'CreateSpatialAssets':'創建空間資產',
  'CreateConfigurationAssets':'創建組態資產',
  'ClericalScript':'隷書',
  'MidWind': '中風',
  'Chart':'圖表',
  'CouldNotUpload': '無法上傳',
  'SpaceComponentSwitchingFloors':'空間組件切換樓層',
  'NextFloor':'下一層',
  'PreFloor':'上一層',
  'ArtistScene':'藝術家場景',
  'ImportSuccess':'導入成功',
  'ImportError':'導入失敗',
  'EnglishName':'英文名',
  'AreYouSureWantToEmptyTheObjectModel':'確定要清空物模型嗎？',
  'BlueprintMappingSuccessful':'藍圖映射成功',
  'TheBlueprintForTheObjectModelAlreadyExists':'物模型的藍圖已經存在',
  'PlanName':'方案名稱',
  'UnbindObjectModelSuccessfully':'解綁物模型成功',
  'AreYouSureToUseThisPlan':'確定使用該方案嗎？',
  'OperationWillResultInThisObjectModelBeingOverwrittenByTheSelectedScheme':'操作將導致該物模型被選擇方案覆蓋',
  'FillInTheName':'請填寫名稱',
  'Canceled':'已取消',
  'Use':'使用',
  'ConfirmTheDeletion': '確認刪除嗎？',
  'DragFilesHere': '拖拽文件到這裡',
  'OrClickToSelect':'或點擊選擇',
  "ThePlanDoesNotExist":'方案不存在',
  'ExportSuccess':'導出成功',
  'ExportError':'導出失敗',
  'IAdded':'我添加的',
  'ImportTheme':'導入主題',
  'AddSuccess':'添加成功',
  'UploadTime':'上傳時間',
  'ThemeType':'主題類型',
  'ThemeUploaded':'主題已上傳',
  'ThemeNotUploaded':'主題未上傳',
  'Batch':'批量',
  'Selected1':'已選擇',
  'NotSelected1':'未選擇',
  'SpaceComponent':'空間組件',
  'ExportPointTableData':'導出點表數據',
  'ExportSpaceData':'導出空間數據',
  'TronV5': '工創5',
  'ConfigurationComponent':'組態组件',
  'shoppingCart':'購物車',
  'DownloadAll': '下載全部',
  'AllParts':'全部部件',
  'Latest':'最新',
  'DownloadNumber':'下載量',
  'Invert':'反选',
  'PleaseDoNotAddAgain':'請勿重複添加',
  'AreYouSureToClearTheShoppingCart':'確認清空購物車麽?',
  'AddToLibrary':'添加到庫',
  'Step1':'第一步',
  'Step2':'第二步',
  'Step3':'第三步',
  'Step4':'第四步',
  'Step5':'第五步',
  'Step6':'第六步',
  'SelectTheSpaceComponentsYouNeed':'選擇您需要的空間組件',
  'SelectTheConfigurationComponentsYouNeed':'選擇您需要的組態組件',
  'Tip':'提示',
  'TheSpatialComponentIsAComponentUsedUnderTheSpatialModule,WhichHasRealProportionalRelationshipsAndDataStateChanges,MeetingYourSpatialDataDisplayNeeds':'空間組件實在空間模塊下使用的組件，具有真實的比例關係和數據狀態變化，滿足您的空間數據展示需求',
  'ClickOnTheCategoryListToFilterTheRequiredComponents':'點擊分類列表篩選需要的組件',
  'TheClassificationListContainsRelevantComponentsForDifferentIndustries,Categories,AndPurposesClickingOnThemWillLoadTheCorrespondingContentOfTheClassificationInTheRightHandView':'分類列表包含了不同行業、種類、用途的相關組件，點擊即可在右側試圖加載對應分類的内容',
  'SelectTheCorrespondingThemeToRenderTheSceneApplicationUnderTheCorrespondingTheme':'鉆則對應主題，即可渲染對應主題下的場景應用',
  'ClickOnTheCategorylistToFilterTheRequiredComponents':'點擊分類列表篩選需要的組件',
  'ChooseTheThemeCategoryYouNeed':'選擇您需要的主題分類',
  'AddComponentsToShoppingCart':'添加組件到購物車',
  'AddToLibraryOrDownloadToLocal':'添加倒庫或下載到本地',
  'TheComponentsInTheShoppingCartCanBeAddedToTheLibraryOfTheOnlineVersionOfGongchuang,OrImportedIntoTheLocalVersionOfGongchuangByDownloadingThemLocally':'購物車内組件可添加到在綫版工創的庫中，也可通過下載到本地，來導入本地版本工創中',
  'ClickTheAddButtonOnTheComponentToAddTheSelectedComponentToTheShoppingCart,AndClickTheBatchButtonInTheUpperRightCornerToBatchAddItToTheShoppingCart':'點擊組件上的添加按鈕，可將選中組件添加到購物車，點擊右上角的批量按鈕，可批量添加到購物車中',
  'TheConfigurationComponentIsADeviceComponentInTheEquipmentSystemDiagram,SuitableForCreating3DConfigurationEquipmentSystemDiagramScenes,WithAppropriateSizeRatiosAndRichStateParametersForTheScreen':'組態組件為設備系統中設備組件，適合製作3D組態設備系統圖場景，具備畫面適宜的尺寸和豐富的狀態參數',
  'Skip':'跳過',
  'CouldNotAdd': '無法添加',
};
export default zh_TW;

import productService from '@/service/product';
import Const from '@/utils/const';
import _ from 'lodash';
import {platformType, updatePlatformType} from '@/model/project';
import {nextTick} from 'vue'

let products={};
const updateProducts=function (){
  products ={
    product: {
      "name": Kf.t('TronMarket'),
      "title": Kf.t('TronMarket'),
      "icon": "/static/favicon.ico",
      "logo": "/img/logo.png",
      "miniLogo": "/img/kfMinLogo.png",
      "loading": "/img/kf-loadding.webp",
      "bg": "/img/tron_login_bg.webp",
      "enTitle": "KINGFISHER MARKET"
    },
    "3dcmarket": {
      "name":Kf.t('TronMarket'),
      "title": Kf.t('TronMarket'),
      "logo": "/img/logo.png",
      "logo3": "/img/logo3.png",
      "loading": "/img/kf-loadding.webp",
      "bg": "/img/kingfisher_login_bg.webp",
      "enTitle": "KINGFISHER MARKET",
      "wxLogin": true,
      "doc": true,
      "tutorial": true,
      "applyAccount": false,
    },
    "tronmarket": {
      "name":  Kf.t('TronMarket'),
      "title": Kf.t('TronMarket'),
      "logo": "/img/logo.png",
      "logo3": "/img/logo3.png",
      "loading": "/img/kf-loadding.webp",
      "bg": "/img/tron_login_bg.webp",
      "enTitle": "KINGFISHER MARKET",
      "wxLogin":false,
      "doc":true,
      "tutorial":true,
      "applyAccount":false
    },
    "saasmarket": {
      "name":Kf.t('TronMarket'),
      "title": Kf.t('TronMarket'),
      "logo": "/img/logo.png",
      "logo3": "/img/logo3.png",
      "loading": "/img/kf-loadding.webp",
      "bg": "/img/tron_login_bg.webp",
      "enTitle": "KINGFISHER MARKET",
      "wxLogin": false,
      "doc": false,
      "tutorial": false,
      "applyAccount": true,
    },
  }
}
nextTick(() =>{
  if(products.products===undefined){
    updateProducts()
  }
})


let serverSetting = {
  loaded: false,
};

const init = function(callback) {
  if (!serverSetting.loaded) {
    if(products.products===undefined){
      updateProducts()
    }
    serverSetting = Object.assign(serverSetting, _.cloneDeep(products));
    productService.getProductInfo().then((result) => {
      serverSetting.loaded = true;
      Object.keys(serverSetting).map(key => {
        if (result[key]) {
          serverSetting[key] = Object.assign(serverSetting[key], result[key]);
        }
      });
      if (callback) {
        callback();
      }
    });
  } else {
    if (callback) {
      callback();
    }
  }
};

const getImage = function (product, item, context) {
  if (!serverSetting.loaded) {
    init();
  }
  if (Const.develop) {
    return serverSetting[product][item];
  } else {
    return '/cloud/3dc'  + serverSetting[product][item];
  }
};

const beSame = function (product, item) {
  if (!serverSetting.loaded) {
    init();
  }
  return products[product][item] === serverSetting[product][item];
};

export const dependPlatformType = function (callBack = function () {
}) {
  if (platformType.value === null) {
    updatePlatformType().then(() => {
      callBack();
    });
  } else {
    callBack();
  }

};

export default {
  products: serverSetting,
  init,
  getImage,
  beSame,
};

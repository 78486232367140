import {ref} from 'vue';

let currTheme=ref('dark');
let sysThemeList = ref([
    {
        name: '深色', code: 'dark', type: 'theme', data: {
            theme2: '#2B2E32',
            theme3: '#000000',
            theme4: '#1C1C1C',
            theme5: '#FFFFFF',
            subTheme1: '#069697',
            subTheme2: '#272E2D',

            success: '#2FA84F',
            success2: '#01240A',
            warning: '#F3AA18',
            warning2: '#2F2609',
            error: '#EA3D2F',
            error2: '#340400',
            hover: 'rgba(255,255,255,0.1)',
            input: 'rgba(0,0,0,0.9)',

            title1: '#FFFFFF',
            title2: '#FFFFFF',
            title3: '#ffffff',
            title4: 'rgba(255,255,255,0.75)',
            text: '#FFFFFF',
            paragraph: '#FFFFFF',
            subText: 'rgba(255,255,255,0.5)',
            importantText: '#FFFFFF',
            buttonText: '#FFFFFF',
            buttonReversedText: 'rgba(255,255,255,0.75)',
            placeholderText: 'rgba(255,255,255,0.5)',
            inputText: 'rgba(255,255,255,0.75)',

            background: '#000000',
            module1: 'rgba(255,255,255,0.2)',
            module2: 'rgba(255,255,255,0.1)',
            module3: 'rgba(0,0,0,0.3)',
            module4: 'rgba(0,0,0,0.4)',
            module5: 'rgba(0,0,0,0.5)',
            module6: 'rgba(0,0,0,0.6)',
            module7: 'rgba(0,0,0,0.8)',
            module8: 'rgba(0,0,0,0.75)',

            borderColor1: 'rgba(255,255,255,0.1)',
            borderColor2: '#474747',
            borderColor3: 'rgba(255,255,255,0.4)',
            borderColor4: 'rgba(255,255,255,0.6)'
        }
    },
    {
        name: '浅色', code: 'light', type: 'theme', data: {
            theme1: '#367BF5',
            theme2: '#E5EFFF',
            theme3: '#FFFFFF',
            theme4: '#1C1C1C',
            theme5: '#FFFFFF',
            subTheme1: '#069697',
            subTheme2: '#DAF5F2',

            success: '#2FA84F',
            success2: '#DCF7E3',
            warning: '#F3AA18',
            warning2: '#FCF3D7',
            error: '#EA3D2F',
            error2: '#FEE4E2',
            hover: 'rgba(0,0,0,0.1)',
            input: 'rgba(0,0,0,0.2)',

            title1: '#000000',
            title2: '#000000',
            title3: '#000000',
            title4: 'rgba(0,0,0,0.75)',
            text: '#000000',
            paragraph: '#000000',
            subText: 'rgba(0,0,0,0.5)',
            importantText: '#000000',
            buttonText: '#FFFFFF',
            buttonReversedText: 'rgba(0,0,0,0.65)',
            placeholderText: 'rgba(0,0,0,0.35)',
            inputText: 'rgba(0,0,0,0.75)',

            background: '#FFFFFF',
            module1: 'rgba(0,0,0,0.05)',
            module2: 'rgba(0,0,0,0.1)',
            module3: 'rgba(255,255,255,0.8)',
            module4: 'rgba(0,0,0,0.4)',
            module5: 'rgba(0,0,0,0.5)',
            module6: 'rgba(0,0,0,0.6)',
            module7: 'rgba(0,0,0,0.8)',
            module8: 'rgba(255,255,255,0.75)',

            borderColor1: 'rgba(0,0,0,0.1)',
            borderColor2: '#DADADA',
            borderColor3: 'rgba(0,0,0,0.4)',
            borderColor4: 'rgba(255,255,255,0.6)',
        }
    }
])
let currThemeData=ref(sysThemeList.value[0].data)

const updateTheme = function(theme) {
  currTheme.value = theme;
  let _theme=sysThemeList.value.find(item =>{return item.code===theme})
  if(_theme?.data){
      currThemeData.value=_theme.data
  }
};

export default {
  currTheme,
  updateTheme,
  sysThemeList,
  currThemeData
};

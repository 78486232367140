<script setup>
import envModel from '@/model/env';
import themeModel from '@/model/theme';
import oemManager from '@/utils/oem';
import {onMounted, ref,watch} from 'vue';

window.Kf = window.Kf || {};

window.Kf.prodCode = 'c1c16452b04650ea34d1463da2739f3b';
window.oncontextmenu = function (e) {
    e.preventDefault();
};
const logo = ref('');
watch(themeModel.currTheme,() =>{
    document.documentElement.setAttribute('kf-theme', themeModel.currTheme.value)
})

onMounted(() => {

    let width = document.body.clientWidth;
    let fontSize = Math.floor(width / 1920 * 16);
    document.body.parentElement.style.fontSize = fontSize + 'px';
    document.documentElement.setAttribute('kf-theme', themeModel.currTheme.value)

    oemManager.init(() => {
        logo.value = oemManager.getImage('product', 'loading', 'market');
        if (oemManager.products.product.saas) {
            window.Kf = window.Kf || {};
            window.Kf.Saas = oemManager.products.product.saas;
        }
    });

    const go = new window.Go();
    let mod, inst;
    WebAssembly.instantiateStreaming(fetch('static/kingfisher.wasm'), go.importObject).then((result) => {
        mod = result.module;
        inst = result.instance;
        run();
    }).catch((err) => {
        console.error(err);
    });

    async function run() {
        window.Kf = window.Kf || {};
        window.Kf.onSsoReady = (proInfo, sysInfo, ssoInfo) => {
            envModel.sysEnv.value = {
                proInfo: JSON.parse(proInfo),
                sysInfo: JSON.parse(sysInfo),
                ssoInfo: JSON.parse(ssoInfo),
            };
        };

        await go.run(inst);
        inst = await WebAssembly.instantiate(mod, go.importObject);
    }

    document.body.classList.remove('kingfisher-body');
});
</script>

<template>
    <div class="kf-art-container kf-bg">
        <router-view></router-view>
    </div>
</template>

<style lang="scss">
@import "style/css/mainIndex";
</style>
<style>
@import "style/icon/iconfont.css";
</style>

import * as vue from 'vue';
import {createApp} from 'vue';
import './style.css';
import App from './App.vue';
import Router from './router';
import i18n from './i18n/index.js';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import * as Icons from '@element-plus/icons'
import VueTour from 'v3-tour'
import "v3-tour/dist/vue-tour.css"


window.Kf = window.Kf || {};
window.Kf.locale = i18n.global.locale;
window.Kf.t = i18n.global.t;
window.kfApp = createApp(App).use(ElementPlus, {locale: zhCn}).use(VueTour).use(i18n).use(Router);
window.isRuntime = Router.isRuntime;

Object.keys(Icons).forEach(key => {
    window.kfApp.component(key, Icons[key])
})

window.kfApp.mount('#app');
window.Vue = vue;
window.router = Router;


import axios from 'axios';
import {ElMessage, ElNotification} from 'element-plus';
import {getToken, removeToken} from "./auth";

let _headers = {};
let HOST = "";

export let _handle = {
  redirect: {},
  showMsg: {}
};

class KingfisherService {

  constructor(param = {}) {
    //param里参数：context, token,loginRoute
    this.host = param.host || '';
    this.loginRoute = param.loginRoute

    if (!this.host) {
      this.host = window.location.href.substring(0, window.location.href.indexOf('/', 8));
    }

    this.errorStatus = {
      message: '',
      statusCode: -1,
      screen: ''
    };

    this.context = param.host || "/cloud";
    if (param.context) {
      this.context = param.context;
    }

    this.token = getToken();
    if (param.token) {
      this.token = param.token
    }
  }

  static get header() {
    return _headers;
  }

  static get serverIP() {
    return HOST;
  }

  static setHeader(headers) {
    Object.keys(headers).forEach(key => {
      _headers[key] = headers[key];
    });
  }

  static hasHeader(key) {
    return !!_headers[key];
  }

  static setHost(host) {
    HOST = host;
  }

  static setHandle(funcName, func) {
    _handle[funcName] = func;
  }

  getURL(url) {
    let result = this.host ? this.host : HOST;

    const isDev = 'development' === import.meta.env.VITE_NODE_ENV;

    if (!url.startsWith("http")) {
      result = this.context + "/api" + url;
      // if (isDev) {
      //   result = this.context + result;
      // }
      //
      // let currUrl = window.location.href;
      // currUrl = currUrl.substr(currUrl.indexOf("://") + 3);
      // const contextPath = currUrl.substr(currUrl.indexOf("/"), currUrl.indexOf("cloud") - currUrl.indexOf("/"));
      // result = contextPath + result;
    } else {
      result = url;
    }
    return result;
  }

  sendFetch(method, args) {
    let axOptions = {
      method: method,
      url: this.getURL(args[0]),
      data: args[1],
      headers: Object.assign(args[2] ? {} : {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authentication': getToken()
      }, args[2]),
    };
    if (args[3]) {
      Object.assign(axOptions, args[3]);
    }
    return axios(axOptions);
  }

  fetchPromise(method, args) {
    return new Promise((resolve, reject) => {
      this.sendFetch(method, args).then(res => {
          if (res.statusText === 'OK' || res.status === 200) {
            // resolve(res.data);
            const fileName = res.headers['content-disposition'];
            if (fileName) {
              let type = res.headers['content-type'];
              let name = res.headers['content-disposition'];
              name = name.substring(name.indexOf('=') + 1);
              let blob = new Blob([res.data], {type: type});
              let url = window.URL.createObjectURL(blob);
              let link = document.createElement('a');
              link.style.display = 'none';
              link.href = url;
              name=decodeURIComponent(name)
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
              window.URL.revokeObjectURL(url);
              this.handleConnection(resolve, reject, "下载成功");
            } else {
              this.handleConnection(resolve, reject, res.data);
            }
          } else {
            reject(this.processFailed(res.data));
          }
        }, err => {
          console.warn(this.getURL(args[0]));
          if (err.response) {
            reject(this.processFailed(err.response));
          } else if (typeof err.message === 'string') {
            reject(this.processFailed(err.message));
          } else {
            reject(this.processFailed(err.message.response || {message: '网络错误'}));
          }
        }
      );
    });
  }

  handleConnection(resolve, reject, data) {
    if (data && data.kfSysCode && typeof data.kfSysCode == 'number') {
      const _msg = data.kfSysMsg ? data.kfSysMsg : 'System Error';

      const _code = data.kfSysCode;
      let _errMsg = _msg;
      switch (_code) {
        case 101: {
          _errMsg = Kf.t('UserNotFound');
          break;
        }
        case 102: {
          _errMsg = Kf.t('UserOrPasswordInputError');
          break;
        }
        case 103: {
          _errMsg = Kf.t('LoginTimedOut');
          break;
        }
        case 104: {
          _errMsg = Kf.t('YourAccountHasBeenEnteredInAnotherDeviceAndYouAreForced');
          break;
        }
        case 106: {
          _errMsg = Kf.t('LoginTimedOut');
          break;
        }
        case 200: {
          _errMsg = Kf.t('SsoLoginClosed');
          break;
        }
        case 201: {
          _errMsg = Kf.t('SsoUserNotFound');
          break;
        }
        case 202: {
          _errMsg = Kf.t('SsoUserOrPasswordInputError');
          break;
        }
        case 304: {
          _errMsg = Kf.t('WechatLoginFailUnknowService');
          break;
        }
        default: {
          _errMsg = Kf.t('SystemAnomaly');
          break;
        }
      }
      if (this.lastErrorTime == null || this.lastError == null
        || new Date().getTime() - this.lastErrorTime > 1000 || _errMsg !== this.lastError) {
        ElNotification({
          title: 'Warning',
          message: _errMsg,
          type: 'warning'
        });
        this.lastErrorTime = new Date().getTime();
        this.lastError = _errMsg;
      }
      if (_code === 103 || _code === 104 || _code === 106) {
        removeToken();
        //router.push({path: '/'});
        window.location.href = window.location.href.substring(0, window.location.href.indexOf("#/"))
      }
      reject(this.processFailed(_msg));
    } else {
      resolve(data);
    }
  }

  processFailed(response) {
    if (typeof response === 'string') {
      this.errorStatus = {
        message: response,
        statusCode: null,
        screen: ''
      };
      return {response, errorStatus: this.errorStatus};
    }

    if (!response.status) {
      this.errorStatus = {
        message: response.message,
        statusCode: 0
      };
      /*if (process.env.PLATFORM === 'web') {
        ElMessage({
          type: 'warning',
          message: response.message
        });
      }*/
      return {response, errorStatus: this.errorStatus};
    }

    this.errorStatus = {
      message: '',
      statusCode: response.status,
      screen: ''
    };

    let message = "";
    switch (response.status) {
      case -1:
        this.errorStatus.message = response.message;
        return {response, errorStatus: this.errorStatus};
      case 0:
        ElMessage({
          type: 'warning',
          message: Kf.t('CheckNetworkConnectionsPlease'),grouping: true
        });
        break;
      case 401:
        ElMessage({type: 'warning', message: Kf.t('LoginTimedOut'),grouping: true});
        router.push({path: '/'});
        break;
      default:
        message = Kf.t('RequestSendFailed')+'：' + JSON.stringify(response.data);
        if (response.data.requestId) {
          message = `[${response.data.requestId}] ${
              response.data.message
          }`;
        }

        this.errorStatus.message = message;
        return {response, errorStatus: this.errorStatus};
    }
  }

  get() {
    return this.fetchPromise('GET', arguments);
  }

  post() {
    return this.fetchPromise('POST', arguments);
  }

  put() {
    return this.fetchPromise('PUT', arguments);
  }

  delete() {
    return this.fetchPromise('DELETE', arguments);
  }

}

export default KingfisherService;
const en = {
  language: {
    zh_CN: '简体中文',
    zh_TW: '繁体中文',
    en: 'English',
  },
  'TronMarket':'Tron Market',
  'None': 'None',
  '#MinutesAgo': '# minute(s) ago.',
  '&Send': '& Send',
  '1Camera': '1 Camera',
  '1Day': '1 day',
  '2dComponent': '2D Component',
  '2dConfigurationTool': '2D Configuration Tool ',
  '2dEditingMode': '2D Editing Mode',
  '30Days': '30 days',
  '3d': '3D',
  '3dComponent': '3D Component ',
  '3dConfigurationTool': '3D Configuration Tool',
  '3dDesigner': '3D Designer',
  '3dDeviceConnectionNodes': '3D device connection nodes',
  '3dEditingMode': '3D Editing Mode',
  '3dScene': '3D Scene',
  '3dSpace': '3D Space',
  '7Days': '7 days',
  'About': 'About ',
  'AboutUs': 'About us',
  'Absolutely': 'Absolutely',
  'AbsolutePosition': 'Absolute Position',
  'Access': 'Access',
  'AccessHasBeenRestricted': 'Access has been restricted',
  'AccessToData': 'Access to data',
  'Account': 'Account',
  'AccountApplication': 'Account Application',
  'AccountName': 'Account name',
  'AccountPassword': 'Account password',
  'AccountStatus': 'Account Status',
  'AChangeOfLayoutWouldResultInAPartialDeformationOfThe': 'A change of layout would result in a partial deformation of the component and the operation could not be resumed. Please confirm if it continues.',
  'Actions': 'Actions',
  'ActiveStatus': 'Active Status',
  'Add': 'Add',
  'AddAll': 'Add All',
  'AddCurrentCategory': 'Add Current Category',
  'AddDataSource': 'Add Data Source',
  'Added': 'Added',
  'AddFieldsAsCreated': 'Add Fields as Created',
  'AddFolder': 'Add Folder',
  'AddGroupToObjectModel': 'Add group to object model',
  'AddModel': 'Add Model',
  'AddNewItem': 'Add New Item',
  'AddPage': 'Add Page',
  'AddRequestQueue': 'Add Request Queue',
  'AddToCurrentCategory': 'Add to Current Category',
  'Administrator': 'Administrator',
  'AfterConfirmation': 'After confirmation',
  'AfterLoadingImageHide': 'After Loading Image Hide',
  'AirConditioning': 'Air conditioning',
  'AirConditioning001': 'Air conditioning 001',
  'AirconditioningEquipmentCategory': 'Air-conditioning equipment category',
  'AirconditioningSystems': 'Air-conditioning systems',
  'AirSensor5': 'Air Sensor 5',
  'AirSensors': 'Air sensors',
  'AirSensors1': 'Air Sensors1',
  'Alarm': 'Alarm',
  'Alarm2': 'Alarm',
  'AlarmBubbles': 'Alarm bubbles.',
  'AlertList': 'Alert List',
  'AlignBothEnds': 'Align both ends',
  'AlignHorizontally': 'Align Horizontally',
  'AlignItToTheLeft': 'Align it to the left',
  'Alignment': 'Alignment',
  'AlignUp': 'Align Up',
  'All': 'All',
  'All2': 'All ',
  'AllAboard': 'All aboard',
  'AllowUsersToClick': 'Allow users to click',
  'AllowUsersToExit': 'Allow users to exit',
  'AllPublishefdHistoricalData': 'All publishefd historical data',
  'AllRemovedFromAboard': 'All removed from aboard',
  'Already': 'Already',
  'AlreadyLoggedInOnADifferentBrowser/window': 'Already logged in on a different browser/window',
  'AlreadyOnTheSystem': 'Already on the system.',
  'AlreadyRegistered': 'Already registered.',
  'AlreadyTheBottomLayer': 'Already the bottom layer.',
  'AlreadyTheTopLayer': 'Already the top layer.',
  'AModifiedModelWouldRemoveRawAreaDataAndWouldAModified': 'A modified model would remove raw area data, and would a modified existing area model be confirmed?',
  'Analysis': 'Analysis ',
  'AnchorPosition': 'Anchor Position ',
  'And': 'And',
  'AndInitializesTheCameraPositionAs': 'and initializes the camera position as',
  'AndToTheTop': 'And to the top.',
  'ANewcomersGuide': 'A newcomer\'s guide.',
  'Angle': 'Angle',
  'AnHourAgo': 'An hour ago',
  'Animation': 'Animation',
  'AnimationCycle': 'Animation Cycle',
  'AnimationTime': 'Animation time',
  'Anomalous': 'Anomalous',
  'ApiInterfaceDocument': 'API Interface Document',
  'Appearances': 'Appearances',
  'ApplicationNotSupported': 'Application not supported',
  'Applications': 'Applications',
  'ApplyMapRelationship': 'Apply Map Relationship',
  'AreaOfThePlatform': 'Area of the platform',
  'AreaTime': 'Area Time',
  'AreChangesToExistingRegionalModelsConfirmed': 'Are changes to existing regional models confirmed?',
  'AreYouAlreadyLoggedIn': 'Are you already logged in? ',
  'AreYouSure': 'Are you sure?',
  'AreYouSureItsDeleted': 'Are you sure it\'s deleted?',
  'AreYouSureYouWantToDeleteIt': 'Are you sure you want to delete it?',
  'AreYouSureYouWantToDeleteItDeleteItAndFindItInTheWastebin': 'Are you sure you want to delete it? Delete it and find it in the wastebin. We\'ll keep it for you.',
  'AreYouSureYouWantToDeleteItYouCanFindItInTheTrashWhen': 'Are you sure you want to delete it? You can find it in the trash when you delete it.',
  'AreYouSureYouWantToDeleteThatState': 'Are you sure you want to delete that state?',
  'AreYouSureYouWantToDeleteThatState1': 'Are you sure you want to delete that state?',
  'AreYouSureYouWantToDeleteTheEvent': 'Are you sure you want to delete the event?',
  'AreYouSureYouWantToDeleteThePerspective': 'Are you sure you want to delete the perspective?',
  'AreYouSureYouWantToDeleteThePerspective1': 'Are you sure you want to delete the perspective?',
  'AreYouSureYouWantToDeleteTheScene': 'Are you sure you want to delete the scene?',
  'AreYouSureYouWantToDeleteTheScene1': 'Are you sure you want to delete the scene?',
  'AreYouSureYouWantToDeleteThis': 'Are you sure you want to delete this?',
  'ArrangeLayout': 'Arrange Layout',
  'Array': 'Array',
  'As': 'As',
  'Ash': 'Ash',
  'AssociatedDevices': 'Associated devices',
  'Attribute2': 'Attribute 2',
  'Audio': 'Audio',
  'Author': 'Author',
  'Auto': 'Auto',
  'Autoadapt': 'Auto-adapt',
  'AutoConnection': 'Auto Connection',
  'Autocreate': 'AutoCreate',
  'AutoGeneratePartialEnd': 'Auto Generate Partial End',
  'AutoGeneratePartStart': 'Auto Generate Part Start',
  'AutoLayout': 'Auto Layout',
  'AutomaticRowWrapping': 'Automatic row wrapping',
  'AutoPlay': 'Auto Play',
  'Autosave': 'Autosave',
  'Available': 'Available',
  'AvailableForViewingUnderThisItem': 'Available for viewing under this item',
  'AxisLength': 'Axis Length',
  'Back': 'Back',
  'Background': 'Background',
  'BackgroundColour': 'Background Colour',
  'BackgroundDisplay': 'Background Display',
  'BackgroundPictures': 'Background pictures',
  'BackgroundShadow': 'Background Shadow',
  'BackstageProcessing': 'Backstage processing',
  'BackToTheFrontPage': 'Back to the front page.',
  'Backwards': 'Backwards',
  'Balance': 'Balance',
  'BalanceMode': 'Balance Mode',
  'Baseboard': 'Baseboard',
  'BaseboardColour': 'Baseboard Colour',
  'BaseProperties': 'Base Properties',
  'Because': 'Because',
  'Beginning': 'Beginning',
  'Beijing': 'Beijing',
  'BeijingKingfisherTechnologyCoLtd': 'Beijing Kingfisher Technology Co. Ltd.',
  'BestPerformance': 'Best performance',
  'Between': 'Between',
  'BeyondScroll': 'Beyond Scroll',
  'BigNumber': 'Big number',
  'BindingEquipment': 'Binding equipment',
  'Biscuits': 'Biscuits',
  'Black': 'Black',
  'BlankEdges': 'Blank edges',
  'Blinking': 'Blinking',
  'Blue': 'Blue',
  'Blue01': 'Blue 01',
  'Blue02': 'Blue 02',
  'Blue03': 'Blue 03',
  'Blue04': 'Blue 04',
  'Blue05': 'Blue 05',
  'BlueGray04': 'Blue Gray 04',
  'Bluegrey': 'Blue-Grey',
  'BlueGrey01': 'Blue Grey 01',
  'BlueGrey02': 'Blue Grey 02',
  'BlueGrey03': 'Blue Grey 03',
  'BlueGrey05': 'Blue Grey 05',
  'BlueprintForTheEditingModel': 'Blueprint for the editing model',
  'BlueprintList': 'Blueprint List',
  'BlueprintSave': 'Blueprint Save',
  'Blur': 'Blur',
  'Bold': 'Bold',
  'Bold2': 'Bold',
  'Bold3': 'Bold',
  'Boolean': 'Boolean',
  'BooleanTurns': 'Boolean turns',
  'BooleanTypeTransferNumber': 'Boolean Type Transfer Number',
  'Border': 'Border',
  'BorderCard': 'Border Card',
  'BorderLineColour': 'Border Line Colour',
  'Borders': 'Borders',
  'Bottom': 'Bottom',
  'Bottom2': 'Bottom',
  'BottomAlignment': 'Bottom Alignment ',
  'BottomBorder': 'Bottom Border',
  'BottomExternalBorder': 'Bottom External Border',
  'BottomLeft': 'Bottom Left',
  'BreakLines': 'Break Lines',
  'Brightness': 'Brightness',
  'BrowseMode': 'Browse mode',
  'Bubbles': 'Bubbles',
  'Building': 'Building',
  'BuildingName': 'Building name',
  'Busy': 'Busy',
  'Button': 'Button',
  'Button1': 'Button 1',
  'Button2': 'Button 2',
  'Button3': 'Button 3',
  'ButtonColour': 'Button Colour',
  'ButtonGroup': 'Button Group',
  'ButtonLabel': 'Button Label',
  'ButtonText': 'Button Text',
  'Bytes': 'Bytes',
  'CalculatingEventHandling': 'Calculating event handling',
  'Camera': 'Camera',
  'Camera1': 'Camera 1',
  'Camera10': 'Camera 10',
  'Camera11': 'Camera 11',
  'Camera12': 'Camera 12',
  'Camera13': 'Camera 13',
  'Camera14': 'Camera 14',
  'Camera15': 'Camera 15',
  'Camera16': 'Camera 16',
  'Camera17': 'Camera 17',
  'Camera18': 'Camera 18',
  'Camera19': 'Camera 19',
  'Camera2': 'Camera 2',
  'Camera20': 'Camera 20',
  'Camera3': 'Camera 3',
  'Camera4': 'Camera 4',
  'Camera5': 'Camera 5',
  'Camera6': 'Camera 6',
  'Camera7': 'Camera 7',
  'Camera8': 'Camera 8',
  'Camera9': 'Camera 9',
  'CanBeMovedTo': 'Can Be Moved To',
  'CanBeMovedToTheSceneForViewing': 'Can be moved to the scene for viewing',
  'Cancel': 'Cancel',
  'Cancel2': 'Cancel',
  'Cannot': 'Cannot',
  'CannotBeDropped': 'Cannot be Dropped',
  'CannotBeEmpty': 'Cannot be Empty ',
  'CannotGroupComponentsThatAreAlreadyGrouped': 'Cannot group components that are already grouped ',
  'CanNotOpenMessage': 'Can not open message',
  'CanNotOpenMessage2': 'Can not open message',
  'CanNotOpenMessage3': 'Can not open message',
  'CanNotOpenMessage4': 'Can not open message',
  'Canvas': 'Canvas',
  'Capable': 'Capable',
  'CapitalLetters': 'Capital letters',
  'Card': 'Card',
  'CategoryName': 'Category Name',
  'CategoryOfLightEquipment': 'Category of light equipment',
  'CenterHorizontally': 'Center Horizontally',
  'CenterRight': 'Center Right',
  'Centred': 'Centred',
  'CentredInRows': 'Centred in Rows',
  'CentreVertically': 'Centre Vertically',
  'CentreZoom': 'Centre Zoom',
  'Chair': 'Chair',
  'Chamfer': 'Chamfer',
  'Change': 'Change',
  'ChangeTheGrid': 'Change the grid.',
  'ChangingTheLayoutWillCauseSomeComponentsToDeform': 'Changing the layout will cause some components to deform.',
  'Character': 'Character',
  'CharacterConnection': 'Character Connection',
  'CheckDown': 'Check Down',
  'CheckItOutPlease': 'Check it out, please.',
  'CheckNetworkConnectionsPlease': 'Check network connections, please.',
  'CipherTextHasBeenCorrupted': 'Cipher text has been corrupted',
  'Circle': 'Circle',
  'CircleGradient': 'Circle Gradient',
  'CircleHead': 'Circle Head',
  'Circulation': 'Circulation ',
  'City': 'City',
  'Classification': 'Classification',
  'Clear': 'Clear',
  'Clear2': 'Clear',
  'ClearExistingData': 'Clear existing data',
  'ClearTimer': 'Clear Timer',
  'Click': 'Click',
  'Clickable': 'Clickable',
  'ClickCopy': 'Click Copy',
  'ClickEvent': 'Click Event',
  'ClickFocus': 'Click Focus',
  'ClickOkAfterProcessing': 'Click OK after processing.',
  'ClickOnTheButton': 'Click on the button',
  'ClickOnTheModel': 'Click on the model',
  'ClickToDisplay': 'Click to display',
  'ClickToDisplayTheGrid': 'Click to display the grid,',
  'ClickToEditScene': 'Click to edit scene',
  'ClickToShowTheGrid': 'Click to show the grid',
  'Client': 'Client',
  'CloningFailedComponentNotFound': 'Cloning failed. Component not found.',
  'Close': 'Close',
  'Close2': 'Close',
  'CloseAllFloatingWindows': 'Close all floating windows',
  'Closed': 'Closed',
  'CloseFocus': 'Close Focus',
  'CloseText': 'Close Text',
  'Cloud': 'Cloud',
  'CollapseButton': 'Collapse button',
  'Collection': 'Collection',
  'CollectionComponent': 'Collection Component',
  'ColorLight': 'Color light ',
  'ColourInput': 'Colour Input',
  'Colours': 'Colours',
  'ColourSettings': 'Colour Settings',
  'ColourSettings2': 'Colour Settings',
  'ColourTemperature': 'Colour temperature',
  'ColourTemperatureSettings': 'Colour Temperature Settings',
  'ColumnCentre': 'Column Centre',
  'Comma': ',',
  'CompanyName': 'Company Name ',
  'CompanyName2': 'Company Name ',
  'Comparison': 'Comparison',
  'Completed': 'Completed',
  'Completed2': 'Completed',
  'Component': 'Component',
  'Component2': 'Component',
  'ComponentAddedSuccessfully': 'Component added successfully',
  'ComponentBrowser': 'Component Browser',
  'ComponentCloneSuccessfully': 'Component clone successfully',
  'ComponentCollection': 'Component Collection',
  'ComponentCreatedSuccessfully': 'Component created successfully',
  'ComponentDeleted': 'Component deleted',
  'ComponentDisplay': 'Component Display',
  'ComponentDoesNotSupportPreview': 'Component does not support preview',
  'ComponentDoesNotSupportSharing': 'Component does not support sharing',
  'ComponentEdit': 'Component Edit',
  'ComponentList': 'Component List',
  'ComponentListData': 'Component List Data',
  'ComponentMarket': 'Component market',
  'ComponentMarket2': 'Component Market',
  'ComponentName': 'Component Name',
  'ComponentNotFound': 'Component not found',
  'ComponentProperties': 'Component Properties',
  'Condition': 'Condition',
  'ConfigurationTool': 'Configuration Tool',
  'Configure': 'Configure',
  'Configured': 'Configured',
  'ConfigureEntry': 'Configure Entry',
  'ConfigureInProjectScript': 'Configure in Project Script',
  'Confirm': 'Confirm',
  'Confirm2': 'Confirm',
  'Confirm3': 'Confirm',
  'Confirm4': 'Confirm',
  'ConfirmationOfChangesInExistingRegionalModalities': 'Confirmation of changes in existing regional modalities',
  'ConfirmChanges': 'Confirm Changes',
  'ConfirmExportScene': 'Confirm export scene',
  'ConfirmThatTheNewPasswordDoesNotMatchTheNewPassword': 'Confirm that the new password does not match the new password.',
  'ConfirmThatTheNewPasswordIsNotEmpty': 'Confirm that the new password is not empty.',
  'ConfirmTheDeletionOfTheEvent': 'Confirm the deletion of the event?',
  'Connected': 'Connected',
  'ConnectedSuccessfully': 'Connected successfully',
  'Connection': 'Connection',
  'ConnectionClosed': 'Connection closed',
  'ConnectionDisconnected': 'Connection Disconnected',
  'ConnectionError': 'Connection Error',
  'ConnectionName': 'Connection Name',
  'Consolidated': 'Consolidated',
  'ConstantArray': 'Constant array',
  'ConstantFile': 'Constant File',
  'ConstantNumber': 'Constant number',
  'ConstantString': 'Constant String',
  'Construction': 'Construction',
  'ConstructionSelection': 'Construction Selection',
  'ContactUs': 'Contact Us',
  'Contents': 'Contents',
  'Continuous': 'Continuous',
  'Control': 'Control',
  'ControlBar': 'Control Bar',
  'Controllable': 'Controllable',
  'ControlStatus': 'Control Status',
  'ControlSwitches': 'Control Switches',
  'ConvertToNumbers': 'Convert to Numbers',
  'Copy': '.Copy',
  'Copy2': 'Copy',
  'Copy3': 'Copy',
  'Copy4': 'Copy',
  'Copy5': 'Copy',
  'Copyable': 'Copyable',
  'CopyComponent': 'Copy Component',
  'CopyFailedcannotFindTheWidget': 'Copy failed,cannot find the widget.',
  'CopyLink': 'Copy Link',
  'CopyMapRelationship': 'Copy Map Relationship',
  'CopyPanel': 'Copy Panel',
  'CopySuccessful': 'Copy Successful',
  'CouldNotMoveUp': 'Could not move up',
  'Cover': 'Cover',
  'CoverChart': 'Cover Chart',
  'Create': 'Create',
  'CreateAGroup': 'Create a group',
  'CreateANewSession': 'Create a new session',
  'CreateAProject': 'Create a project',
  'CreateClassification': 'Create Classification',
  'CreateCopy': 'Create Copy',
  'Created': 'Created',
  'CreateDataSource': 'Create Data Source',
  'CreatedDeviceData': 'Created device data',
  'CreateFieldsAsNewModel': 'Create Fields as New Model',
  'CreateGroup': 'Create Group',
  'CreateItem': 'Create Item',
  'CreateOrder': 'Create Order',
  'CreatePage': 'Create Page',
  'CreatePlayer': 'Create Player',
  'CreateRequestAgent': 'Create Request Agent',
  'CreateScene': 'Create Scene',
  'CreateSheet': 'Create Sheet',
  'CreateSuccessful': 'Create successful',
  'Creativity': 'Creativity',
  'CropContents': 'Crop Contents',
  'Cross': 'Cross',
  'Crossstand': 'Cross-Stand',
  'Current': 'Current',
  'Current2': 'Current',
  'CurrentBrightness': 'Current Brightness',
  'CurrentColourType': 'Current colour type',
  'CurrentComponentData': 'Current Component Data',
  'CurrentlySelected': 'Currently Selected',
  'CurrentlySelectedDevice': 'currently selected device',
  'CurrentPage': 'Current Page',
  'CurrentStatus': 'Current status',
  'CurrentVersion': 'Current Version',
  'Curtain001': 'Curtain 001',
  'CurtainDirection': 'Curtain direction',
  'CurtainPosition': 'Curtain position',
  'Curtains': 'Curtains',
  'CurtainSpeed': 'Curtain speed',
  'Curve': 'Curve',
  'Curve2': 'Curve',
  'Custom': 'Custom',
  'Cut': 'Cut',
  'Cut2': 'Cut',
  'CutComponent': 'Cut Component',
  'CutTheHorn': 'Cut the horn.',
  'CylindricalChart': 'Cylindrical Chart',
  'Dangerous': 'Dangerous',
  'Dark': 'Dark',
  'DarkAsh': 'Dark Ash',
  'DarkBlue': 'Dark Blue',
  'DarkGrey04': 'Dark Grey 04',
  'DarkGrey05': 'Dark Grey 05',
  'Dashboard': 'Dashboard',
  'Data': 'Data',
  'Data1': 'Data 1',
  'DataArray': 'Data array',
  'DataComponent': 'Data Component',
  'DataConfiguration': 'Data Configuration',
  'DataConfigurationList': 'Data Configuration List',
  'DataConnection': 'Data Connection',
  'DataConnectionFailed': 'Data connection failed',
  'DataDevice': 'Data Device',
  'DataDeviceComponent': 'Data Device Component',
  'DataEnumeration': 'Data Enumeration',
  'DataError': 'Data error',
  'DataFormat': 'Data Format',
  'DataFormatMatchingSuccessfully': 'Data format matching successfully',
  'DataMap': 'Data Map',
  'DatamapSubscription': 'DataMap Subscription',
  'DataNotAvailable': 'Data not available',
  'DataObjectives': 'Data objectives',
  'DataProperties': 'Data Properties',
  'DataPropertiesConfigurationList': 'Data Properties Configuration List',
  'DataReceived': 'Data received',
  'DataSourceAccessList': 'Data Source Access List',
  'DataSourceCannotBeEmpty': 'Data source cannot be empty',
  'DataSourceCreatedSuccessfully': 'Data source created successfully',
  'DataSourceCreationError': 'Data source creation error',
  'DataSourceDeletedSuccessfully': 'Data source deleted successfully',
  'DataSourceDeletingFailed': 'Data Source Deleting Failed',
  'DataSourceError': 'Data source error',
  'DataSourceList': 'Data Source List',
  'DataSourceName': 'Data Source Name',
  'DataSources': 'Data Sources',
  'DataSourceStandard': 'Data source standard',
  'DataSourceUpdateSuccessful': 'Data source update successful',
  'DataType': 'Data type',
  'DataType2': 'Data type',
  'DataValue': 'Data value',
  'Date': 'Date',
  'DateRange': 'Date Range',
  'DateSelection': 'Date Selection',
  'Day': ' day.',
  'Day1': 'Day',
  'DaysAgo': 'Days ago ',
  'Decimal': 'Decimal',
  'DecimalPlaces': 'Decimal places',
  'DeepGray03': 'Deep Gray 03',
  'DeepGrey01': 'Deep Grey 01',
  'DeepGrey02': 'Deep Grey 02',
  'Default': 'Default',
  'DefaultCamera': 'Default Camera',
  'DefaultComponent': 'Default Component',
  'DefaultComponentWhenTheObjectModelIsNotAssociatedWith': 'Default component when the object model is not associated with a 3D component',
  'DefaultDevice': 'Default Device',
  'DefaultEvents': 'Default Events',
  'DefaultFloor': 'Default floor',
  'DefaultLabel': 'Default label',
  'DefaultLabel1': 'Default LABEL',
  'DefaultLayout': 'Default Layout',
  'DefaultModel': 'Default Model',
  'DefaultName': 'Default name',
  'DefaultProperties': 'Default Properties',
  'DefaultResourceList': 'Default Resource List',
  'DefaultStatusPoint': 'Default status point',
  'DefaultValue': 'Default value',
  'Degrees': 'degrees',
  'Dehumidification': 'Dehumidification ',
  'Delete': 'Delete',
  'Deleted': 'Deleted',
  'DeleteItAndFindItInTheWastebin': 'Delete it and find it in the wastebin.',
  'DeletePermanently': 'Delete permanently',
  'DeleteSuccess': 'Delete Success',
  'DeleteTheRow': 'Delete the row',
  'DeletionFailed': 'Deletion Failed',
  'Depends': 'Depends',
  'DeploymentDocument': 'Deployment Document',
  'Descending': 'Descending',
  'Description': 'Description',
  'DescriptionColour': 'Description Colour',
  'Design': 'Design',
  'DestructionOf3dComponents': 'Destruction of 3D components',
  'Details': 'Details',
  'DeterminesThatAllContentsAreEmptied': 'Determines that all contents are emptied',
  'DeterminesToDeleteItems': 'Determines to Delete Items',
  'DeterminesWhetherThisReleaseLinkShouldBePlacedNext': 'Determines whether this release link should be placed next',
  'DetermineWhetherToDeleteTheDataService': 'Determine whether to delete the data service',
  'DetermineWhetherToRestore': 'Determine whether to restore',
  'Device': 'Device',
  'DeviceAlarm': 'Device alarm.',
  'DeviceClassification': 'Device Classification',
  'DeviceData': 'Device data',
  'DeviceId': 'Device id',
  'DeviceLabel': 'Device Label',
  'DeviceList': 'Device List',
  'DeviceList2': 'Device List',
  'DeviceManagementPage': 'Device Management Page',
  'DeviceName': 'Device Name',
  'DeviceNumber': 'Device Number',
  'DeviceNumberToBeFilledIn': 'Device number to be filled in',
  'DevicePanel': 'Device Panel',
  'DeviceProperties': 'Device Properties',
  'DeviceRepository': 'Device Repository',
  'DeviceStatus': 'Device Status',
  'DeviceTime': 'Device Time',
  'DeviceType': 'Device type',
  'DeviceTypeA': 'Device Type A',
  'DeviceTypeArray': 'Device type array',
  'DeviceTypeArrayQueryFailed': 'Device Type Array Query Failed',
  'DeviceTypeB': 'Device Type B',
  'DeviceTypeC': 'Device type C',
  'DeviceTypeD': 'Device type D',
  'DeviceTypeE': 'Device type E',
  'DeviceTypeF': 'Device Type F',
  'DeviceTypeG': 'Device type G',
  'DifferentTypesOfEquipment': 'Different types of equipment',
  'DifferentTypesOfEquipmentAreNotSupportedForApplication': 'Different types of equipment are not supported for application',
  'Digital': 'Digital',
  'Dimensions': 'Dimensions',
  'DirectionLight1': 'Direction light 1',
  'DirectlyAbove': 'Directly Above',
  'DirectlyBelow': 'Directly Below',
  'Disable': 'Disable',
  'Disable2': 'Disable',
  'DisableGround': 'Disable ground',
  'DisaggregatedData': 'Disaggregated data',
  'Disconnect': 'Disconnect',
  'Disconnected': 'Disconnected',
  'Discontinue': 'Discontinue ',
  'Dismantling': 'Dismantling',
  'DismantlingFailed': 'Dismantling failed.',
  'DismantlingSucceeded': 'Dismantling succeeded.',
  'DismissGroupToDefault': 'Dismiss Group to Default',
  'DisplayOnBothSides': 'Display on Both Sides',
  'DistortionOfCanvas': 'Distortion of canvas',
  'Document': 'Document',
  'Documentation': 'Documentation',
  'DoesNotSupportFullscreen': 'Does Not Support Fullscreen',
  'DoNotAutomaticallyAdjust': 'Do not automatically adjust',
  'DoNotScroll': 'Do Not Scroll ',
  'DoNotShowBottom': 'Do Not Show Bottom',
  'DoNotShowHead': 'Do not show head',
  'DoNotShowLeft': 'Do Not Show Left',
  'DoNotShowOutside': 'Do Not Show Outside',
  'DoNotShowRight': 'Do Not Show Right',
  'Door': 'Door',
  'DottedLine': 'Dotted Line',
  'Double': 'Double',
  'Doubleclick': 'Double-click',
  'Down': 'Down',
  'Down2': 'Down',
  'Down3': 'Down',
  'Download': 'Download',
  'DownloadSuccessful': 'Download Successful ',
  'Draw': 'Draw',
  'DrawsideColours': 'Drawside Colours',
  'DrawTheEdge': 'Draw the edge',
  'DrinkingWaterMachine': 'Drinking water machine',
  'Duration': 'Duration',
  'Edit': 'Edit',
  'EditIn': 'Edit in',
  'EditMode': 'Edit Mode',
  'EditModeRotation': 'Edit Mode Rotation',
  'EditNotSupported': 'Edit Not Supported',
  'Editor': 'Editor',
  'EditorEditMode': 'Editor Edit Mode',
  'EditorPreviewMode': 'Editor Preview Mode',
  'EditPage': 'Edit Page',
  'EditScript': 'Edit Script',
  'Eight': 'Eight.',
  'EightCameras': 'Eight Cameras',
  'Elevator': 'Elevator',
  'EllipseGradient': 'Ellipse Gradient',
  'EmailFormatError': 'Email Format Error',
  'Emergency': 'Emergency',
  'Empty': 'Empty',
  'EmptyCanvas': 'Empty canvas',
  'EmptyModel': 'Empty Model',
  'EmptyScene': 'Empty scene',
  'EmptyWastebin': 'Empty Wastebin',
  'EmptyWastebinAttemptFailed': 'Empty Wastebin Attempt Failed ',
  'Enable': 'Enable',
  'EnableGround': 'Enable ground',
  'Encoding': 'Encoding',
  'EndName': 'End Name',
  'EndOfThePage': 'End of the page',
  'EndpointAlignment': 'Endpoint Alignment',
  'EndSession': 'End Session',
  'Enter': 'Enter',
  'EnterAccountName': 'Enter Account Name',
  'EnterAccountPassword': 'Enter Account Password',
  'EnterANewPassword': 'Enter a new password',
  'EnterTheItemCodePlease': 'Enter the item code, please.',
  'EnterTheOriginalPassword': 'Enter the original password',
  'Environment': 'Environment',
  'EqualTo': 'equal to',
  'Equipment': 'Equipment',
  'Equipment1': 'Equipment 1',
  'Equipment2': 'Equipment 2',
  'Equipment3': 'Equipment 3',
  'Equipment4': 'Equipment 4',
  'Equipment5': 'Equipment 5',
  'Equipment6': 'Equipment 6',
  'Equipment7': 'Equipment 7',
  'Equivalence': 'Equivalence',
  'EquivalentZoom': 'Equivalent Zoom',
  'Error': 'Error',
  'Error2': 'Error',
  'ErrorEnteringInformation': 'Error entering information',
  'ErrorFetchingDeviceMapData': 'Error fetching device map data',
  'ErrorFetchingTheLatestScenarioScriptParameter': 'Error fetching the latest scenario script parameter',
  'ErrorFindingVersionScriptParameter': 'Error finding version script parameter',
  'ErrorInConfiguringCorrespondingDataProvider': 'Error in Configuring Corresponding Data Provider',
  'ErrorSavingSceneScript': 'Error saving scene script',
  'EventAs': 'Event As',
  'EventBehaviorLibraryHasBeenLoaded': 'Event Behavior Library Has Been Loaded',
  'EventHandlingError': 'Event Handling Error ',
  'Events': 'Events',
  'Events2': 'Events',
  'EventsAfterTheSceneIsLoaded': 'Events after the scene is loaded',
  'EventScript': 'Event Script',
  'ExampleMatrix': 'Example matrix',
  'ExitLogin': 'Exit Login',
  'ExpandPanel': 'Expand Panel',
  'Export': 'Export',
  'ExportScene': 'Export scene',
  'ExteriorShadows': 'Exterior Shadows',
  'External': 'External',
  'ExternalPageInitialization': 'External Page Initialization',
  'ExternalPageLoadError': 'External Page Load Error',
  'ExternalPageLoadingCompleted': 'External Page Loading Completed',
  'ExternalPages': 'External pages',
  'Failed': 'Failed',
  'FailedAndDelete': 'Failed and delete',
  'FailedToDeletePage': 'Failed to delete page',
  'FailedToLoadPageScripts': 'Failed to load page scripts',
  'FailedToLoadServiceScript': 'Failed to load service script',
  'FailedToOpenPostPage': 'Failed to open post page',
  'FailedToSaveResource': 'Failed to save resource',
  'FailPleaseContactAdministrator': 'fail, please contact administrator',
  'Fault': 'Fault',
  'FaultState': 'Fault state',
  'FavouriteFolder': 'Favourite Folder',
  'Fetching': 'Fetching',
  'FetchModelData': 'Fetch Model Data...',
  'Fields': 'Fields',
  'File': 'File',
  'FileDeleteSuccessful': 'File delete successful',
  'FileName': 'File Name',
  'FileName2': 'File Name',
  'FileReadingFailed': 'File Reading Failed',
  'FileRestorationSuccess': 'File Restoration Success',
  'FileSize': 'File Size',
  'FileType': 'File Type',
  'Fill': 'Fill',
  'FillingLengthOn': 'Filling length on',
  'FillItUp': 'Fill it up.',
  'FillMode': 'Fill Mode',
  'FillWith': 'Fill with',
  'Fine': 'Fine',
  'Fine2': 'Fine',
  'Finer': 'Finer',
  'First': 'First',
  'FitToWindow': 'Fit to Window',
  'Five': 'Five',
  'Fixed': 'Fixed',
  'FixedPanel': 'Fixed Panel',
  'FixedPosition': 'Fixed Position',
  'FixedWidth': 'Fixed Width',
  'Float': 'Float',
  'FloatType': 'Float type',
  'Floor': 'Floor',
  'FloorList': 'Floor List',
  'Fluid': 'Fluid',
  'FocusZoom': 'Focus Zoom',
  'FolderExists': 'Folder Exists',
  'FollowPanel': 'Follow Panel',
  'Fonts': 'Fonts',
  'FontSize': 'Font Size',
  'Footer': 'Footer',
  'FooterBackground': 'Footer Background',
  'ForBrowsersThatDoNotSupportGradients': 'For browsers that do not support gradients',
  'ForLargerFileProcessingNeedsPleaseContactUs': 'For larger file processing needs, please contact us.',
  'Form': 'Form',
  'Format': 'Format',
  'FormatSelection': 'Format Selection',
  'Formatting': 'Formatting',
  'FormRule': 'Form Rule',
  'Formula': 'Formula',
  'ForScene': 'For Scene ',
  'Forward': 'Forward',
  'Foundation': 'Foundation',
  'Four': 'Four.',
  'FourCameras': 'Four Cameras',
  'FourearlyBorders': 'Four-Early Borders',
  'FrameRate': 'Frame Rate',
  'FrequencyOfIncomingAndOutgoing': 'Frequency of incoming and outgoing',
  'Friday': 'Friday',
  'Front': 'Front',
  'FrontView': 'Front View',
  'FullScreen': 'Full Screen',
  'FullScreenControl': 'Full Screen Control',
  'FullscreenModeDisabled': 'Fullscreen mode disabled',
  'FunctionTypeError': 'Function type error',
  'Gallery': 'Gallery',
  'GearLayout': 'Gear Layout',
  'General': 'General',
  'GeneralModel': 'General model',
  'GenerateConnections': 'Generate Connections',
  'GenerateLinkOpen': 'Generate Link Open',
  'GetAllThat': 'Get all that ',
  'GetAnExampleObjectForAPageComponent': 'Get an example object for a page component',
  'GetSpaceList': 'Get Space List',
  'GlobalData': 'Global data',
  'GlobalEvents': 'Global Events',
  'GlobalMessage': 'Global Message',
  'GoBackToTheHomepage': 'Go back to the homepage',
  'GoBackToTheMainPage': 'Go back to the main page',
  'GoBackToTheNextLevel': 'Go back to the next level.',
  'GoToDefinition': 'Go to Definition',
  'GoToQuote': 'Go to Quote',
  'GoToSymbol': 'Go to Symbol...',
  'GoToSymbol1': 'Go to Symbol',
  'GradientGradient': 'Gradient Gradient',
  'GreaterThan': 'greater than',
  'GreaterThanOrEqualTo': 'greater than or equal to',
  'Green': 'Green',
  'Green01': 'Green 01',
  'Green02': 'Green 02',
  'Green03': 'Green 03',
  'Green04': 'Green 04',
  'Green05': 'Green 05',
  'GridAlignment': 'Grid Alignment ',
  'GridDisplay': 'Grid Display',
  'GridSize': 'Grid Size',
  'Ground': 'Ground',
  'GroundAreaAndEncircledBox': 'Ground area and encircled box',
  'GroundGeneration': 'Ground Generation',
  'GroundTemplate': 'Ground Template',
  'Group': 'Group',
  'GroupAs': 'Group As',
  'GroupedComponentsNotSupportedForAdditions': 'Grouped components not supported for additions',
  'Grouping': 'Grouping',
  'GroupingFailed': 'Grouping failed.',
  'GroupingSuccessful': 'Grouping successful',
  'GroupName': 'Group Name',
  'GroupName2': 'Group Name',
  'GroupNameCannotBeUsed': 'Group name cannot be used',
  'GroupNameRepeat': 'Group Name Repeat',
  'GroupRename': 'Group Rename',
  'Guide': 'Guide',
  'HandleEach': 'Handle each.',
  'Has': 'Has ',
  'Head': 'Head',
  'Header': 'Header',
  'Header2': 'Header',
  'HeaderBackground': 'Header Background',
  'Heating': 'Heating',
  'Height': 'Height',
  'Height2': 'Height',
  'HeightChanges': 'Height changes.',
  'HeightFill': 'Height Fill',
  'Help': 'Help',
  'HelpDocument': 'Help Document',
  'Hide': 'Hide',
  'HideDate': 'Hide Date',
  'HideGrid': 'Hide Grid',
  'High': 'High',
  'HighSpeed': 'High Speed',
  'HighWind': 'High wind',
  'Hint': 'Hint',
  'History': 'History',
  'HitCache': 'Hit Cache',
  'HomePage': 'Home Page',
  'HomePage2': 'Home Page',
  'HomepageNotCurrentlySet': 'Homepage not currently set',
  'HomepageNotCurrentlySetCannotBePublished': 'Homepage not currently set, cannot be published',
  'Horizontal': 'Horizontal',
  'Horizontal2': 'Horizontal',
  'HorizontalDispersal': 'Horizontal Dispersal',
  'HorizontalPosition': 'Horizontal Position',
  'HorizontalTiling': 'Horizontal Tiling',
  'Hours': 'Hours',
  'Hours2': 'Hours',
  'Hue': 'Hue',
  'HueBand': 'Hue band',
  'Huge': 'Huge',
  'Icon': 'Icon',
  'IconButton': 'Icon Button',
  'IconLibrary': 'Icon Library',
  'IfParametersAreNotSetProperly': 'If parameters are not set properly',
  'IfTheValueIs': 'If the value is',
  'IgnoreMap': 'Ignore Map',
  'IgnoreProcess': 'Ignore Process',
  'Image': 'Image',
  'Image2': 'Image',
  'Import': 'Import',
  'ImportComponent': 'Import Component',
  'ImportDirectly': 'Import directly',
  'ImportModel': 'Import Model',
  'Index': 'Index',
  'IndicatorCard': 'Indicator card',
  'IndicatorCard1': 'Indicator card 1',
  'IndicatorCard2': 'Indicator card 2',
  'IndicatorCard3': 'Indicator card 3',
  'Indicators': 'Indicators',
  'IndividualIndicators': 'Individual indicators',
  'Information': 'Information',
  'InformationAnomaly': 'Information anomaly',
  'InformationError': 'Information Error',
  'InitialCamera': 'Initial Camera',
  'InitialCamera2': 'Initial camera',
  'InitialEvent': 'Initial Event',
  'Initialization': 'Initialization',
  'InitializeComponent': 'Initialize Component',
  'InitializeScriptManager': 'Initialize Script Manager',
  'InitializeTo': 'Initialize To',
  'InitializeToComponentEditorMode': 'Initialize to Component Editor Mode',
  'InitializeToHomepageMode': 'Initialize to Homepage Mode',
  'InitializeToSceneEditorMode': 'Initialize to scene editor mode',
  'InitializeToSpaceMode': 'Initialize to Space Mode',
  'InitialPosition': 'Initial Position',
  'InitialPosition2': 'Initial Position',
  'Inkgreen': 'InkGreen',
  'InplaceCopy': 'In-Place Copy',
  'InsideShadows': 'Inside Shadows',
  'InsortTheGrid': 'Insort the grid',
  'InsufficientPermissions': 'Insufficient Permissions',
  'Integer': 'Integer',
  'Integer2': 'Integer',
  'Interaction': 'Interaction',
  'IntercolumnLines': 'Inter-column Lines',
  'Interface': 'Interface',
  'InterfaceCreatedSuccessfully': 'Interface created successfully',
  'InterfaceDocument': 'Interface Document',
  'InterfaceSubscriptions': 'Interface Subscriptions',
  'Interline': 'Interline',
  'Internal': 'Internal',
  'Interval': 'Interval',
  'InTheProcessOfBeingReleased': 'In the process of being released',
  'InvokeRequestQueueSuccessCallback': 'Invoke request queue success callback ',
  'IsItTheHomePage': 'Is it the home page?',
  'IssuanceOfConnectionAccessIssues': 'Issuance of connection access issues',
  'IsThereUnsavedContentIsItSaved': 'Is there unsaved content, is it saved?',
  'Italicize': 'Italicize',
  'Italics': 'italics',
  'ItCanOnlyBeOpenedOnTheHomepage': 'It can only be opened on the homepage.',
  'Item': 'Item',
  'ItemModelList': 'Item Model List',
  'ItllIncreaseYourWaitingTimeConsiderably': 'It\'ll increase your waiting time considerably.',
  'ItsAlreadyTheBottomLayerItCantMoveDown': 'It\'s already the bottom layer. It can\'t move down.',
  'ItsBeenModifiedPleaseReland': 'It\'s been modified. Please re-land.',
  'ItsFromTheStation': 'It\'s from the station.',
  'ItsTheTopLayerItCantBeMovedUp': 'It\'s the top layer. It can\'t be moved up.',
  'ItsTimeForTheFloor': 'It\'s time for the floor.',
  'ItsYours': 'It\'s yours.',
  'IveGotAHeartbeat': 'I\'ve got a heartbeat.',
  'JqueryKit': 'JQuery Kit',
  'Jump': 'Jump',
  'Jump2': 'Jump',
  'JumpAddress': 'Jump Address',
  'JumpToDatamapPage': 'Jump to DataMap Page',
  'KeepAnEyeOnYourMailbox': 'Keep an eye on your mailbox.',
  'KeepDecimals': 'Keep Decimals',
  'KeySeries': 'Key series',
  'Kingfisher': 'Kingfisher',
  'KingfisherCloud': 'Kingfisher Cloud',
  'Label': 'Label',
  'Label1': 'Label 1',
  'LabelHeight': 'Label height',
  'LadderDiagram': 'Ladder Diagram',
  'Large': 'Large',
  'LargeDiagrams': 'Large diagrams',
  'LastModified': 'Last Modified',
  'LastPage': 'Last Page',
  'Layout': 'Layout',
  'Layout01': 'Layout 01',
  'Layout02': 'Layout 02',
  'Layout03': 'Layout 03',
  'Layout04': 'Layout 04',
  'Layout05': 'Layout 05',
  'LayoutPreset': 'Layout Preset',
  'LayoutType': 'Layout Type',
  'Left': 'Left',
  'Left2': 'Left',
  'Left3': 'Left',
  'Left4': 'Left',
  'LeftAlign': 'Left Align',
  'LeftAndRight': 'Left and right',
  'LeftAndRightAdjustments': 'Left and right adjustments',
  'LeftAndRightExternalBorder': 'Left and right external border',
  'LeftAndRightMargin': 'Left and right margin',
  'LeftBackground': 'Left Background',
  'LeftBottom': 'Left Bottom',
  'LeftBox': 'Left Box',
  'LeftExternalBorder': 'Left External Border',
  'LeftList': 'Left List',
  'LeftSlantOverAdjustment': 'Left slant over adjustment',
  'LeftToRight': 'Left to Right',
  'Legend': 'Legend',
  'LegendTowards': 'Legend Towards',
  'Length': 'Length',
  'LengthGreaterThan': 'Length greater than',
  'LessThan': 'Less Than',
  'LessThanOrEqualTo': 'Less Than or Equal To',
  'Level': 'Level',
  'Level2': 'Level',
  'LevelOfTheComponent': 'Level of the component',
  'Library': 'Library',
  'LibraryClassificationSelection': 'Library Classification Selection',
  'LibraryFailed': 'Library Failed',
  'LibraryGroupSelection': 'Library Group Selection',
  'LibraryType': 'Library type',
  'Light': 'Light',
  'Light001': 'Light 001',
  'LightBlue': 'Light Blue',
  'LightColourSet': 'Light Colour Set',
  'LightGrey01': 'Light Grey 01',
  'LightGrey02': 'Light Grey 02',
  'LightGrey03': 'Light Grey 03',
  'LightGrey04': 'Light Grey 04',
  'LightGrey05': 'Light Grey 05',
  'LightQuantifiedList': 'Light Quantified List',
  'LightQueue': 'Light Queue',
  'Lights': 'Lights',
  'Lights2': 'Lights',
  'Lights3': 'Lights',
  'LightsOffMode': 'Lights off mode',
  'LimitedNumberOfProjects': 'Limited number of projects',
  'Linear': 'Linear',
  'LinearGradient': 'Linear Gradient',
  'LineChart': 'Line Chart',
  'LinesBetweenColumns': 'Lines between Columns',
  'LineSpacing': 'Line Spacing',
  'LineWidth': 'Width',
  'List': 'List',
  'ListLayout': 'List Layout',
  'ListOfButtons': 'List of buttons',
  'ListOfCommands': 'List of Commands',
  'ListOfItems': 'List of items',
  'ListOfPermissions': 'List of Permissions',
  'ListOfServiceProviders': 'List of service providers',
  'ListOfWorkorder': 'List of workorder',
  'Load': 'Load',
  'Load3dScenes': 'Load 3D scenes',
  'LoadComplete': 'Load complete.',
  'Loaded': 'Loaded',
  'LoadedAllComponents': 'Loaded all components.',
  'LoadHomePage': 'Load Home Page',
  'LoadIconLibrary': 'Load Icon Library',
  'Loading': 'Loading',
  'LoadingFiles': 'Loading files...',
  'LoadingFiles1': 'Loading files',
  'LoadingModel': 'Loading model...',
  'LoadingModel1': 'Loading model',
  'LoadingOfTubeModels': 'Loading of tube models...',
  'LoadingOfTubeModels1': 'Loading of tube models',
  'LoadingPageScripts': 'Loading Page Scripts',
  'LoadingProjectScripts': 'Loading Project Scripts',
  'Loading…': 'Loading…',
  'LoadPage': 'Load Page',
  'LoadPages': 'Load Pages',
  'LoadServiceScripts': 'Load service scripts',
  'Lock': 'Lock',
  'LockInTheDirection': 'Lock in the direction.',
  'LodashKit': 'Lodash Kit',
  'Login': 'Login',
  'LoginClosed': 'Login closed',
  'LoginFailed': 'Login failed',
  'LoginIsInvalid': 'Login is invalid',
  'LoginServerRequired': 'Login server required',
  'LoginSuccessful': 'Login successful',
  'LoginTimedOut': 'Login timed out',
  'LoginWithAccount': 'Login with Account ',
  'LoginWithAccountNumberOtherPerson': 'Login with account number other person',
  'LookAtTheHeightOfTheBoard': 'Look at the height of the board.',
  'Loop': 'Loop',
  'LoopScroll': 'Loop Scroll',
  'LowercaseLettersAndSpecialCharacters': 'Lowercase letters and special characters',
  'LowerRight': 'Lower Right ',
  'LowerView': 'Lower View ',
  'LowSpeed': 'Low speed',
  'LowWind': 'Low wind',
  'MainBody': 'Main Body ',
  'MainLightSource': 'Main Light Source',
  'MainLightSource2': 'Main light source',
  'MaintainDataSources': 'Maintain data sources',
  'Maintenance': 'Maintenance',
  'Mandatory': 'Mandatory',
  'Manual': 'Manual',
  'ManualStatus': 'Manual Status',
  'MapIsDisabled': 'Map is disabled.',
  'Material': 'Material',
  'Material1': 'Material1',
  'Max': 'Max',
  'MaybeItsBecauseSomeoneElseLogsIntoYourAccount': 'Maybe it\'s because someone else logs into your account.',
  'MediaResourceDataFormatError': 'Media resource data format error',
  'Medium': 'Medium',
  'MediumBold': 'Medium Bold',
  'MediumDisplay': 'Medium Display',
  'MediumLeft': 'Medium Left',
  'MediumSpeed': 'Medium Speed',
  'MemoryUsageClosesToCriticality': 'Memory usage closes to criticality',
  'MemoryUse': 'Memory Use',
  'MemoryUseBeyondCriticality': 'Memory use beyond criticality',
  'Message': 'Message',
  'MessageList': 'Message List',
  'MessageLog': 'Message Log',
  'MessageReceived': 'Message received',
  'Methodology': 'Methodology',
  'Microgram': 'Microgram',
  'Middle': 'Middle',
  'MiddleDown': 'Middle Down ',
  'Midline': 'Midline',
  'Min': 'min',
  'Minimum': 'Minimum',
  'Mirror': 'Mirror',
  'Mode': 'Mode',
  'Model': 'Model',
  'ModelImport': 'Model Import',
  'ModelImportComplete': 'Model import complete',
  'ModelImportFailed': 'Model import failed',
  'ModelImportFailedGltfWith': 'Model import failed. .gltf with',
  'ModelImportFailedTheSelectionNeedsToBeMadeAtTheSame': 'Model import failed. The selection needs to be made at the same time.',
  'Modeling': 'Modeling',
  'ModelSaving': 'Model saving...',
  'ModelsLightQuantifiedLevels': 'Models Light Quantified Levels',
  'ModelsOfThings': 'Models of things',
  'ModelType': 'Model Type',
  'ModifiedSuccessfully': 'Modified successfully',
  'Modify': 'Modify',
  'ModifyGroupName': 'Modify Group Name',
  'ModifyingModesWillClearOriginalData': 'Modifying modes will clear original data',
  'Monday': 'Monday',
  'MonitorSecuritySystem': 'Monitor security system ',
  'Monochrome': 'Monochrome',
  'Month': 'Month',
  'MonthlyScope': 'Monthly Scope',
  'More': 'More',
  'Moveable': 'Moveable',
  'MoveDown': 'Move Down',
  'MovedSuccessfullyTo': 'Moved successfully to',
  'MoveFailPleaseContactAdministrator': 'move fail, please contact administrator',
  'MoveGroup': 'Move Group',
  'MoveLeft': 'Move Left',
  'MoveOneLayerDown': 'Move one layer down',
  'MoveOneLayerUp': 'Move one layer up',
  'MoveRight': 'Move Right',
  'MoveSuccessfully': 'Move Successfully',
  'MoveSuccessfullyToViewUnderThisItem': 'Move successfully to view under this item',
  'MoveToBottom': 'Move to Bottom',
  'MoveToBottom2': 'Move to Bottom',
  'MoveToGroup': 'Move to Group',
  'MoveToItem': 'Move to Item',
  'MoveToMyFile': 'Move to my file',
  'MoveToMyFile2': 'Move to my file ',
  'MoveToTop': 'Move to Top',
  'MoveUp': 'Move Up',
  'MoveUpOneLayer': 'Move up one layer',
  'MovingFailed': 'Moving Failed',
  'MsYahei': 'MS Yahei',
  'MultipleBoxes': 'Multiple Boxes',
  'MultipleDates': 'Multiple dates',
  'MultiscreenSynchronization': 'Multiscreen Synchronization',
  'MustBeFilledIn': 'Must be filled in',
  'Mute': 'Mute',
  'MyFiles': 'My files',
  'Name': 'Name',
  'NameCannotBeEmpty': 'Name cannot be empty',
  'NameCannotBeEmpty2': 'Name cannot be empty',
  'NamedRepeated': 'Named Repeated',
  'NameLength': 'Name Length',
  'NameMayNotBeLongerThan': 'Name may not be longer than',
  'NameMayNotBeLongerThan50Bytes': 'Name may not be longer than 50 bytes',
  'NameOfComponent': 'Name of component',
  'NameRepeat': 'Name Repeat',
  'NavigationBar': 'Navigation Bar',
  'NearbyMonitoringScreen': 'Nearby monitoring screen ',
  'NeedToSelectSimultaneously': 'Need to select simultaneously ',
  'NetworkError': 'Network Error',
  'New': 'New',
  'NewFloor': 'New floor',
  'NewFolder': 'New Folder',
  'NewGroup': 'New Group',
  'NewGroupName': 'New Group Name',
  'NewPage': 'New Page',
  'NewPage2': 'New Page',
  'NewPasswordFormatError': 'New password format error',
  'NewPasswordToIncludeNumbers': 'New password to include numbers',
  'NewRomanFonts': 'New Roman Fonts',
  'NewStatus': 'New Status',
  'NewWindowOpensWebSite': 'New window opens web site',
  'Next': 'Next',
  'NextPage': 'Next Page',
  'NextStep': 'Next Step',
  'NineCameras': 'Nine Cameras',
  'No': 'No',
  'NoBackgroundSelected': 'No background selected',
  'NoChangeInData': 'No change in data',
  'NoColourSelected': 'No colour selected',
  'NoComponentsFound': 'No components found',
  'NoDefinedAttributeGroupFound': 'No defined attribute group found',
  'NoDefinedAttributeTypeFound': 'No defined attribute type found',
  'Nodes': 'Nodes',
  'NoDeviceBindingDataDiagram': 'No Device Binding Data Diagram',
  'NoEditingAtPresent': 'No editing at present',
  'NoFill': 'No Fill',
  'NoFlash': 'No flash',
  'NoGrouping': 'No grouping',
  'NoHomePage': 'No Home Page',
  'NoHomepagePleaseSetTheHomePageFirst': 'No homepage, please set the home page first',
  'NoLoginDocumentsFound': 'No login documents found',
  'NoModelOfTheDataSourceFound': 'No model of the data source found',
  'NoneditableModeDoesNotAllowDeletion': 'Non-editable mode does not allow deletion',
  'Nonparticipant': 'Non-participant',
  'NoReduction': 'No reduction',
  'NoRegisteredDataSourceOr': 'No registered data source or',
  'NoRegisteredDataSourceOrProjectid': 'No registered data source or ProjectId',
  'Normal': 'Normal',
  'Normal2': 'Normal',
  'NormalThickness': 'Normal Thickness ',
  'NoRoundCornerSelected': 'No round corner selected',
  'NoScriptExecution': 'No script execution',
  'NoStatusSet': 'No status set',
  'NotAchieved': 'Not achieved',
  'NotAdded': 'Not Added',
  'NotAllowed': 'Not allowed',
  'NotBold': 'Not Bold',
  'NotClassified': 'Not classified',
  'NotConfigured': 'Not Configured',
  'NotEqualTo': 'Not equal to',
  'NoTiling': 'No Tiling ',
  'NotLoadedSuccessfully': 'Not Loaded Successfully',
  'NotOnboard': 'Not onboard',
  'NotSelected': 'Not Selected',
  'NoVisibilitySetup': 'No Visibility Setup',
  'NumberOfDataSources': 'Number of data sources',
  'NumberOfSides': 'Number of Sides',
  'Numbers': 'Numbers',
  'NumbersToBoolean100': 'Numbers to Boolean 100',
  'NumericalTurnToBoolean': 'Numerical turn to Boolean.',
  'NumericalTypeTransferBoolean': 'Numerical Type Transfer Boolean',
  'NumericColours': 'numeric colours',
  'Object': 'Object',
  'Objective': 'Objective',
  'ObliqueView': 'Oblique View',
  'ObtainModelData': 'Obtain model data',
  'Of': 'Of ',
  'Off': 'Off',
  'Office': 'Office',
  'Official': 'Official',
  'OfficialComponent': 'Official Component',
  'OfficialContent': 'Official content',
  'Offline': 'Offline',
  'Offline2': 'Offline',
  'Offset': 'Offset',
  'OnBoard': 'On Board',
  'OnBoard2': 'On board',
  'One': 'One',
  'OneOf': 'One of',
  'Online': 'Online',
  'Online2': 'Online',
  'OnlineHelp': 'Online Help',
  'OnlineStatus': 'Online Status',
  'Opacity': 'Opacity',
  'Open': 'Open',
  'Open2': 'Open',
  'Open3': 'Open',
  'OpenIt': 'Open it.',
  'OpenLink': 'Open Link',
  'OpenText': 'Open Text',
  'OperateFailPleaseContactAdministrator': 'Operate fail, please contact administrator',
  'Operation': 'Operation',
  'Operation2': 'Operation',
  'OperationFailed': 'Operation failed',
  'Operations': 'Operations',
  'OperationsPerformedOnComponentList': 'Operations performed on component list',
  'OperationStatus': 'Operation Status',
  'OperationSuccessful': 'Operation successful',
  'OptimalPerformance': 'Optimal Performance',
  'Optional': 'Optional',
  'Orange': 'Orange.',
  'Orange01': 'Orange 01',
  'Orange02': 'Orange 02',
  'Orange03': 'Orange 03',
  'Orange04': 'Orange 04',
  'Orange05': 'Orange 05',
  'Orientation': 'Orientation',
  'OriginalGroupName': 'Original Group Name',
  'OriginalSize': 'Original Size',
  'OrUpdateDataAnomaly': 'or update data anomaly',
  'Other': 'Other',
  'OurContactInformation': 'Our contact information.',
  'Outcome': 'Outcome',
  'Overline': 'Overline',
  'Overwrite': 'Overwrite',
  'Page': 'Page',
  'Page2': 'Page',
  'PageCopyFailed': 'Page Copy Failed',
  'PageCopySuccessful': 'Page Copy Successful',
  'PageCreatedSuccessfully': 'Page created successfully',
  'PageDeleteSuccessful': 'Page delete successful',
  'PageDeletingFailed': 'Page Deleting Failed',
  'PageFillMode': 'Page Fill Mode',
  'PageIsTimed': 'Page is timed',
  'PageList': 'Page List',
  'PageMenu': 'Page Menu',
  'PageName': 'Page Name',
  'PageName2': 'Page Name',
  'PageSaveCompleted': 'Page Save Completed',
  'PageSaveFailed': 'Page Save Failed',
  'PageScript': 'Page Script',
  'PageServiceModelNotFound': 'Page service model not found',
  'Panel': 'Panel',
  'PanelName': 'Panel name',
  'PanelPackagingType': 'Panel packaging type',
  'ParameterError': 'Parameter Error',
  'ParameterIs': 'Parameter is',
  'ParameterIsEmpty': 'Parameter is empty',
  'Parameters': 'Parameters',
  'ParametersForNonarrayType': 'Parameters for non-array type',
  'ParameterUndefined': 'Parameter Undefined',
  'Park': 'Park ',
  'Password': 'Password',
  'PasswordCannotBeEmpty': 'Password cannot be empty.',
  'Paste': 'Paste',
  'PasteComponent': 'Paste Component',
  'Path': 'Path',
  'Pattern': 'Pattern',
  'Pause': 'Pause',
  'PauseStatus': 'Pause Status',
  'Percentage': 'Percentage',
  'PerformSaveAs': 'Perform Save As',
  'Permanent': 'Permanent',
  'PermanentlyValid': 'Permanently valid.',
  'Permissions': 'Permissions',
  'Perspective': 'Perspective',
  'Perspective2': 'Perspective',
  'PerspectiveAddSuccess': 'Perspective Add Success',
  'PerspectiveDeleted': 'Perspective deleted',
  'PerspectiveMode': 'Perspective Mode',
  'PerspectiveView': 'Perspective View',
  'PhoneNumber': 'Phone Number ',
  'PhoneNumber2': 'Phone Number',
  'PictureAdaptation': 'Picture Adaptation',
  'PictureAddress': 'Picture Address',
  'PictureComponent': 'Picture Component',
  'PictureName': 'Picture Name',
  'PicturePath': 'Picture Path',
  'Pictures': 'Pictures',
  'PictureScale': 'Picture Scale',
  'Pipe': 'Pipe',
  'Pipe2': 'Pipe',
  'PipeDoesntSupportRotationForAWhile': 'Pipe doesn\'t support rotation for a while.',
  'PipeRadius': 'Pipe Radius',
  'Play': 'Play',
  'PlayQuick': 'Play Quick',
  'PleaseAddAnExecutoryFunction': 'Please add an executory function',
  'PleaseCheckThatTheReleaseLinkIsCorrect': 'Please check that the release link is correct',
  'PleaseCheckTheApplicationInformation': 'Please check the application information.',
  'PleaseCheckYourEmail': 'Please check your Email.',
  'PleaseChoose': 'Please choose.',
  'PleaseConfigureDataServices': 'Please configure data services',
  'PleaseConfirmIfYouWantToContinue': 'Please confirm if you want to continue.',
  'PleaseContactTheAdministrator': 'Please contact the administrator.',
  'PleaseContactTheAdministratorToImproveAccess': 'Please contact the administrator to improve access.',
  'PleaseContactThePublisherOfTheConnection': 'Please contact the publisher of the connection',
  'PleaseContactThePublisherToUpdateThePublisherConnection': 'Please contact the publisher to update the publisher connection',
  'PleaseEnter': 'Please enter',
  'PleaseEnterADescriptionOfTheModel': 'Please enter a description of the model',
  'PleaseEnterAnAccountNumber': 'Please enter an account number.',
  'PleaseEnterAName': 'Please enter a name',
  'PleaseEnterSearchContent': 'Please enter search content',
  'PleaseEnterTheAuthor': 'Please enter the author',
  'PleaseEnterTheCompany': 'Please enter the company.',
  'PleaseEnterTheGroupName': 'Please enter the group name',
  'PleaseEnterTheJob': 'Please enter the job.',
  'PleaseEnterTheMailbox': 'Please enter the mailbox.',
  'PleaseEnterTheNameOfTheComponent': 'Please enter the name of the component',
  'PleaseEnterTheNameOfTheItem': 'Please enter the name of the item',
  'PleaseEnterTheOption': 'Please enter the option.',
  'PleaseEnterThePassword': 'Please enter the password.',
  'PleaseEnterThePhone': 'Please enter the phone.',
  'PleaseEnterTheUser': 'Please enter the user',
  'PleaseEnterYourName': 'Please enter your name.',
  'PleaseFillIn': 'Please fill in.',
  'PleaseFillInTheProjectName': 'Please fill in the project name.',
  'PleaseFillInTheSceneName': 'Please fill in the scene name.',
  'PleaseFollowYourMailboxAsSoonAsPossible': 'Please follow your mailbox as soon as possible.',
  'PleaseLogBackIn': 'Please Log Back In. ',
  'PleaseMoveTo': 'Please move to',
  'PleaseMoveToLibrary': 'Please move to library',
  'PleaseReenter': 'Please re-enter',
  'PleaseReenterAndTryAgain': 'Please re-enter and try again.',
  'PleaseRelogIn': 'Please re-log in.',
  'PleaseReturnLogin': 'Please return login',
  'PleaseSelectAComponentFirst': 'Please select a component first.',
  'PleaseSelectADate': 'Please select a date',
  'PleaseSelectAFileFirst': 'Please select a file first.',
  'PleaseSelectAPageFirst': 'Please select a page first.',
  'PleaseSelectAPicture': 'Please select a picture',
  'PleaseSelectTheComponentsThatHaveNotBeenGroupedFirst': 'Please select the components that have not been grouped first.',
  'PleaseSelectTheDataSourceNodeThatNeedsToBeSwitched': 'Please select the data source node that needs to be switched',
  'PleaseSelectTheItemFirst': 'Please select the item first.',
  'PleaseSelectTheVideo': 'Please select the video',
  'PleaseSetGroup': 'Please set group',
  'PleaseSetTheHomePageFirst': 'Please set the home page first.',
  'PleaseSetTheMainPage': 'Please set the main page',
  'PleaseTryAgainLater': 'Please try again later.',
  'PleaseUploadTheFileFirst': 'Please upload the file first.',
  'PleaseWait': 'Please wait.',
  'PleaseWaitForTheFileToBeProcessed': 'Please wait for the file to be processed.',
  'Point': 'Point',
  'PointLink': 'Point Link',
  'PointsheetDataUploadSuccessfully': 'Pointsheet data upload successfully',
  'PointTableData': 'Point Table Data',
  'PopChart': 'Pop chart',
  'Position': 'Position ',
  'Positioning': 'Positioning',
  'PositioningToBindingDevice': 'Positioning to binding device',
  'PositioningToComponent': 'Positioning to Component',
  'PostedSuccessfully': 'Posted successfully.',
  'PostEffect': 'Post Effect',
  'Posteffect2': 'Post-Effect',
  'PostLinksCopiedToClipboard': 'Post links copied to clipboard',
  'Preset': 'Preset',
  'Preview': 'Preview',
  'PreviewMode': 'Preview Mode',
  'PreviousPage': 'Previous Page',
  'PreviousStep': 'Previous Step',
  'Primary': 'Primary',
  'PriorTo': 'Prior to',
  'Private': 'Private',
  'Processing': 'Processing',
  'ProductName': 'Product Name',
  'ProgressChart': 'Progress Chart',
  'ProjectDescription': 'Project description',
  'ProjectInformationRetrievalFailed': 'Project Information Retrieval Failed',
  'ProjectInitializationCompleted': 'Project initialization completed',
  'ProjectName': 'Project name',
  'ProjectScript': 'Project Script',
  'ProjectScriptInitializationError': 'Project Script Initialization Error',
  'ProjectScriptLoadFailed': 'Project Script Load Failed',
  'ProjectSettings': 'Project Settings',
  'Properties': 'Properties',
  'Properties1': 'Properties1',
  'PropertiesFields': 'Properties Fields',
  'PropertiesSettings': 'Properties Settings',
  'ProvisionalVersion': 'Provisional version',
  'PublicLayer': 'Public layer',
  'PublishConnectionDownedOrTimeDue': 'Publish connection downed or time due',
  'PublishFail': 'Publish Fail',
  'PublishFailPleaseContactAdministrator': 'Publish fail, please contact administrator',
  'Publishing': 'Publishing...',
  'Purple': 'Purple',
  'Purple01': 'Purple 01',
  'Purple02': 'Purple 02',
  'Purple03': 'Purple 03',
  'Purple04': 'Purple 04',
  'Purple05': 'Purple 05',
  'Query': 'Query',
  'Question?': '?',
  'Quick': 'Quick',
  'Quit': 'Quit',
  'QuitEditingMode': 'Quit editing mode',
  'ReadFile': 'Read File',
  'Received': 'Received',
  'RecentlyDeleted': 'Recently Deleted',
  'RecentlyEdited': 'Recently edited',
  'RecentlyUsed': 'Recently Used',
  'ReconfirmTheNewPassword': 'Reconfirm the new password.',
  'Rectangle': 'Rectangle',
  'Red': 'Red',
  'Red01': 'Red 01',
  'Red02': 'Red 02',
  'Red03': 'Red 03',
  'Red04': 'Red 04',
  'Red05': 'Red 05',
  'Redo': 'Redo',
  'ReducedToGlobal': 'Reduced to global',
  'Refresh': 'Refresh',
  'RefreshSuccess': 'Refresh Success',
  'Refrigeration': 'Refrigeration',
  'Region': 'Region',
  'Regional': 'Regional',
  'Release': 'Release',
  'ReleaseStatus': 'Release Status',
  'RemainingQuantityToday': 'Remaining Quantity Today ',
  'RememberPassword': 'Remember Password',
  'RemoveCollection': 'Remove Collection',
  'RemoveModel': 'Remove Model',
  'RemovePanel': 'Remove Panel',
  'RemoveProperties': 'Remove Properties',
  'Rename': 'Rename',
  'RenderInformation': 'Render Information',
  'RenderingEvent': 'Rendering Event',
  'Replace': 'Replace',
  'ReplaceAll': 'Replace All',
  'Reposition': 'Reposition',
  'Request': 'Request',
  'RequestFailed': 'Request Failed',
  'RequestSendFailed': 'Request Send Failed',
  'RequestSuccessful': 'Request successful.',
  'Reset': 'Reset',
  'Resolution': 'Resolution',
  'ResourceName': 'Resource Name',
  'Resources': 'Resources',
  'Restore': 'Restore',
  'RetrievalFailed': 'Retrieval Failed',
  'Retrieved': 'Retrieved',
  'RetrieveReturnedComponentFilterValues': 'Retrieve returned component filter values',
  'RetrieveReturnedDashboardFilterValues': 'Retrieve returned dashboard filter values',
  'Returns3dScene': 'Returns 3D scene',
  'ReturnsDataMap': 'Returns Data Map',
  'ReturnToHomePage': 'Return to Home Page',
  'Right': 'Right',
  'Right2': 'Right',
  'Right3': 'Right',
  'Right4': 'Right',
  'RightAllignment': 'Right Allignment ',
  'RightBackground': 'Right Background',
  'RightBottom': 'Right Bottom',
  'RightClick': 'Right Click',
  'RightInTheMiddle': 'Right in the middle.',
  'RightKeyMenu': 'Right Key Menu',
  'RightLeft': 'Right Left',
  'RightOutsideBorder': 'Right Outside Border',
  'RightToLeft': 'Right to Left',
  'Ring': 'Ring',
  'Rotable': 'Rotable',
  'Rotate': 'Rotate',
  'RotateAngle': 'Rotate Angle',
  'RotateClockwise': 'Rotate Clockwise',
  'RotateCounterclockwise': 'Rotate Counterclockwise',
  'RoundCorner': 'Round Corner',
  'RoundedRectangle': 'Rounded Rectangle',
  'RouteConfiguration': 'Route Configuration',
  'RowSelection': 'Row Selection',
  'RulemapView': 'RuleMap View',
  'Run': 'Run',
  'RunModeSwitch': 'Run Mode Switch',
  'Running': 'Running',
  'SameOrder': 'Same order',
  'SatisfactoryValue': 'Satisfactory Value',
  'Saturation': 'Saturation',
  'Saturday': 'Saturday',
  'Save': 'Save',
  'Save2': 'Save',
  'SaveAs': 'Save As',
  'SaveAs2': 'Save As',
  'SaveAsName': 'Save as Name',
  'SaveBlueprints': 'Save blueprints',
  'SaveFailed': 'Save Failed',
  'SaveProject': 'Save Project',
  'SaveSceneAs': 'Save Scene As',
  'SaveSuccessful': 'Save Successful',
  'Scale': 'Scale',
  'Scale2': 'Scale',
  'ScaleBefore': 'Scale Before',
  'ScaleFill': 'Scale Fill',
  'Scene': 'Scene',
  'Scene2': 'Scene.',
  'SceneCreatedSuccessfully': 'Scene created successfully',
  'SceneDownloading': 'Scene Downloading',
  'SceneExport': 'Scene Export',
  'SceneFiles': 'Scene Files',
  'SceneInformation': 'Scene Information',
  'SceneList': 'Scene List',
  'SceneSavedSuccessfully': 'Scene saved successfully.',
  'SceneSelection': 'Scene Selection',
  'ScintillationEffect': 'Scintillation Effect',
  'Scope': 'Scope',
  'ScreenGroup': 'Screen Group',
  'ScreenRoles': 'Screen Roles',
  'Script': 'Script',
  'ScriptData': 'Script Data',
  'ScriptExecutionError': 'Script Execution Error',
  'ScriptLoadingFailed': 'Script loading failed',
  'ScriptOrEventError': 'Script or Event Error',
  'Scrollable': 'Scrollable',
  'ScrollAlways': 'Scroll Always',
  'ScrollBackAndForth': 'Scroll Back and forth',
  'Scrollbar': 'Scrollbar',
  'ScrollOnDemand': 'Scroll on demand',
  'Search': 'Search',
  'Search2': 'Search',
  'Sec': 'sec',
  'SecondAgo': ' second ago',
  'Sector': 'Sector',
  'SelectAGroup': 'Select a group',
  'SelectAll': 'Select All',
  'SelectAllComponents': 'Select all components',
  'SelectAnAttribute': 'Select an attribute',
  'SelectAnObjectForASpaceComponent': 'Select an object for a space component',
  'SelectARoundCorner': 'Select a round corner',
  'SelectColour': 'Select Colour',
  'Selected': 'Selected',
  'SelectedDevices': 'Selected Devices',
  'SelectedState': 'Selected State',
  'SelectedValue': 'Selected value',
  'SelectFile': 'Select File',
  'Selection': 'Selection',
  'SelectLocalFile': 'Select Local File',
  'SelectModel': 'Select Model',
  'SelectModelGroups': 'Select Model Groups',
  'Selector': 'Selector',
  'SelectTheGroupOnTheLeft': 'Select the group on the left',
  'Selfadaptation': 'Self-adaptation',
  'SendAHeartbeat': 'Send a heartbeat.',
  'SendGlobalEvents': 'Send Global Events',
  'SendGlobalEventToAddress': 'Send global event to address',
  'SendTestEvent': 'Send Test Event',
  'Sensors': 'Sensors',
  'SentenceEnd': '.',
  'Sequence': 'Sequence',
  'SerialNumber': 'Serial number',
  'Series': 'Series',
  'Serious': 'Serious',
  'ServersNormal': 'Server\'s normal.',
  'ServiceInitializationFailed': 'Service initialization failed',
  'ServiceList': 'Service List',
  'ServicePathNotFound': 'Service path not found',
  'Services': 'Services',
  'SessionCreationFailed': 'Session Creation Failed',
  'Set': 'Set',
  'SetAllowedToSpecificLocation': 'Set allowed to specific location',
  'SetAsDefaultStatus': 'Set as Default Status',
  'SetComponent': 'Set Component',
  'SetComponentProperties': 'Set Component Properties',
  'SetGlobalContext': 'Set global context',
  'SetPage': 'Set Page',
  'SetParameters': 'Set parameters',
  'SetScene': 'Set scene',
  'SetSpace': 'Set Space',
  'SetTemperature': 'Set Temperature',
  'SetTexture': 'Set Texture',
  'SetTheCamera': 'Set the camera',
  'Settings': 'Settings',
  'SetupDisplay': 'Setup Display',
  'SetupVideoComponent': 'Setup Video Component',
  'Shadow': 'Shadow',
  'Share': 'Share',
  'ShiftRightUp': 'Shift right up',
  'ShortchainInformation': 'Short-chain information',
  'Shortcut': 'Shortcut',
  'ShortcutKeys': 'Shortcut Keys',
  'Show': 'Show',
  'ShowAll': 'Show All',
  'ShowAll2': 'Show All',
  'ShowAllGroups': 'Show all groups',
  'ShowBottom': 'Show Bottom',
  'ShowContents': 'Show Contents',
  'ShowHead': 'Show head',
  'ShowImmediately': 'Show immediately',
  'ShowLeft': 'Show Left',
  'ShowMessage': 'Show Message',
  'ShowOnlyEncircledBoxes': 'Show only encircled boxes',
  'ShowOnlyTheGroundArea': 'Show only the ground area',
  'ShowRight': 'Show Right',
  'ShowTheBottomToolbarOfTheSpaceComponent': 'Show the bottom toolbar of the space component',
  'ShowTitle': 'Show title',
  'ShowToolbar': 'Show Toolbar',
  'ShowViewBar': 'Show View Bar',
  'Side': 'Side',
  'SimulationData': 'Simulation Data',
  'Single': 'Single',
  'SingleSelectionWithinTheGroup': 'Single selection within the group',
  'Six': 'Six',
  'SixCameras': 'Six Cameras',
  'SixteenCameras': 'Sixteen Cameras',
  'Size': 'Size',
  'SizeInUnit': 'Size in Unit',
  'SkipThisGuide': 'Skip this guide',
  'Sky': 'Sky',
  'Skyblue': 'Skyblue',
  'Skyblue01': 'SkyBlue 01',
  'Skyblue02': 'SkyBlue 02',
  'Skyblue03': 'SkyBlue 03',
  'Skyblue04': 'SkyBlue 04',
  'Skyblue05': 'SkyBlue 05',
  'SkyBox': 'Sky Box',
  'Slider': 'Slider',
  'Slider2': 'Slider',
  'SlightlyLarger': 'Slightly Larger',
  'SlowSpeed': 'Slow Speed',
  'Small': 'Small',
  'SmallListLayout': 'Small List Layout',
  'SmallSize': 'Small Size',
  'SmartCharts': 'Smart Charts',
  'Snippets': 'Snippets',
  'Solid': 'Solid',
  'SomeoneElseHasPossiblyLoggedIntoYourAccount!': 'Someone else has possibly logged into your account!',
  'SortAlphabetically': 'Sort Alphabetically',
  'SortBy': 'Sort By',
  'SortComponent': 'Sort Component',
  'Source': 'Source',
  'Space': 'Space',
  'SpaceComponentAutofocus': 'Space Component AutoFocus',
  'SpaceComponentFullScreen': 'Space Component Full Screen',
  'SpaceComponentSearch': 'Space Component Search',
  'SpaceComponentSwitchesToPerspective': 'Space Component Switches to Perspective',
  'SpaceGovernance': 'Space governance',
  'SpaceSurplus': 'Space surplus',
  'Spacing': 'Spacing',
  'SpecialBold': 'Special Bold',
  'SplitVertically': 'Split Vertically',
  'SqlIs': 'SQL is...',
  'Square': 'Square',
  'Square2': 'Square',
  'SsoLoginClosed': 'SSO login closed',
  'SsoUserNotFound': 'SSO user not found',
  'SsoUserOrPasswordInputError': 'SSO user or password input error',
  'StackLayout': 'Stack Layout',
  'StandardBlue': 'Standard Blue',
  'StandardColor': 'Standard Color',
  'StandardDeepBlue': 'Standard Deep Blue',
  'StandardDeepRed': 'Standard Deep Red',
  'StandardGreen': 'Standard Green',
  'StandardLightBlue': 'Standard Light Blue',
  'StandardLightGreen': 'Standard Light Green',
  'StandardOrange': 'Standard Orange',
  'StandardPurple': 'Standard Purple',
  'StandardRed': 'Standard red',
  'Standards': 'Standards',
  'StandardYellow': 'Standard Yellow',
  'StartEditMode': 'Start Edit Mode',
  'StartingPointAlignment': 'starting point alignment',
  'StartProcessing': 'Start processing',
  'StartProcessingData': 'Start processing data',
  'StartRequestingData': 'Start Requesting Data',
  'StartSurveillanceEquipment': 'Start surveillance equipment.',
  'StateName': 'State Name',
  'StateSwitch': 'State Switch',
  'Status': 'Status',
  'StatusAsTime': 'Status as Time',
  'StatusConfiguration': 'Status Configuration',
  'StatusIs': 'Status is',
  'StatusPoint': 'Status point',
  'StatusSelection': 'Status Selection',
  'StatusSettings': 'Status Settings',
  'StatusText': 'Status Text',
  'StatusText01': 'Status text 01',
  'StatusText02': 'Status text 02',
  'StatusText03': 'Status text 03',
  'StatusText04': 'Status text 04',
  'Step': 'Step',
  'StepperControl': 'Stepper Control ',
  'StepValue': 'Step value',
  'Stop': 'Stop',
  'StopSurveillanceEquipment': 'Stop surveillance equipment.',
  'StopTheSurveillanceEquipment': 'Stop the surveillance equipment.',
  'StreamingServerAddressNeedsToBeSet': 'Streaming Server Address Needs to Be Set',
  'String': 'String',
  'StringingNumerals': 'Stringing numerals',
  'ToolsNumberFormat': 'Tools number format',
  'numberFormat': 'Tools number format',
  'ToolNumberToBoolean': 'Tools number to boolean',
  'numberToBoolean': 'Tools number to boolean',
  'ToolBooleanToNumber': 'Tools boolean to number',
  'booleanToNumber': 'Tools boolean to number',
  'StringTypeTurnsNumbers': 'String type turns numbers',
  'StyleDefinition': 'Style Definition',
  'StyleReference': 'Style Reference',
  'Styles': 'Styles',
  'Subject': 'Subject',
  'Submit': 'Submit',
  'SubmittedWithSuccess': 'Submitted with success',
  'SubmittingSuccessfully': 'Submitting successfully',
  'Subparagraph': 'Subparagraph',
  'Subscriptions': 'Subscriptions',
  'Success': 'Success',
  'Succession': 'Succession',
  'SucessfullySavedResource': 'Sucessfully saved resource',
  'Suitable': 'Suitable',
  'Sunday': 'Sunday',
  'Sunggi!SongTypeface': 'Sung-gi! (Song Typeface) ',
  'SupplyTemperature': 'Supply temperature',
  'SupportedModelFormat': 'Supported model format',
  'Surveillance': 'Surveillance',
  'SurveillanceSystem': 'Surveillance system',
  'Suspend': 'Suspend',
  'SuspendColour': 'Suspend Colour',
  'Switch': 'Switch',
  'SwitchedTo': 'Switched to',
  'SwitchingDataSources': 'Switching data sources',
  'SwitchStatus': 'Switch Status',
  'SwitchValue': 'Switch Value',
  'SynchronizeChanges': 'Synchronize Changes',
  'SystemAnomaly': 'System anomaly',
  'SystemName': 'System Name',
  'SystemSubtitles': 'System subtitles',
  'Tab': 'tab',
  'TabDisplay': 'Tab Display',
  'Table': 'Table',
  'Table2': 'Table',
  'TablesOf': 'Tables of',
  'TabText': 'Tab Text',
  'Take': 'Take',
  'TargetValue': 'Target value',
  'Telephone': 'Telephone',
  'Temperature': 'Temperature',
  'Templates': 'Templates',
  'TenSecondsAgo': 'Ten seconds ago.',
  'TerminateComponent': 'Terminate Component ',
  'TerminateScript': 'Terminate Script',
  'Text': 'Text',
  'Text2': 'Text',
  'TextColour': 'Text Colour',
  'TextContents': 'Text Contents',
  'TextContents2': 'Text Contents',
  'TextOrientation': 'Text Orientation',
  'TextShadow': 'Text Shadow',
  'TextSize': 'Text Size',
  'TextZoom': 'Text Zoom',
  'ThankYouForYourApplication': 'Thank you for your application.',
  'TheBinFileNameIsDifferent': 'The .bin file name is different.',
  'TheBuildingsInTime': 'The building\'s in time.',
  'TheCodeHasBeenDestroyedAndCannotBeDeconstructed': 'The code has been destroyed and cannot be deconstructed.',
  'TheCompanyCantBeEmpty': 'The company can\'t be empty.',
  'TheComponentsHaveBeenDestroyed': 'The components have been destroyed.',
  'TheContentsWillBePermanentlyRemovedFromTheWastebin': 'The contents will be permanently removed from the wastebin.',
  'TheContentWillBePermanentlyDeleted': 'The content will be permanently deleted.',
  'TheContentWillBeRestoredToItsOriginalPosition': 'The content will be restored to its original position.',
  'TheCurrentlySelectedDataSource': 'The currently selected data source',
  'TheDatabaseDataUpdateFailed': 'The database data update failed',
  'TheDatabaseDataUpdateWasSuccessful': 'The database data update was successful.',
  'TheDatabaseHasBeenUpgradedSuccessfully': 'The database has been upgraded successfully.',
  'TheDatabaseWasOpenedWrongly': 'The database was opened wrongly.',
  'TheDataFormatMatchesSuccessfully': 'The data format matches successfully,',
  'TheDataIsMissing': 'The data is missing.',
  'TheDigitalManipulative': 'The digital manipulative.',
  'TheDimensionIs': 'The dimension is ',
  'TheDocumentsAreStillBeingProcessed': 'The documents are still being processed.',
  'TheEmailHasBeenRegistered': 'The Email has been registered.',
  'TheEngineHasBeenDestroyed': 'The engine has been destroyed.',
  'TheFileNamesAreDifferent': 'The file names are different.',
  'TheFileTypeIsIncorrect': 'The file type is incorrect',
  'TheGroupAlreadyExists': 'The group already exists',
  'TheIndicatorsAre:': 'The indicators are:',
  'TheLibraryCantBeEmpty': 'The library can\'t be empty.',
  'TheLightOfTheDirection': 'The light of the direction.',
  'TheLinkIsDisabled': 'The link is disabled.',
  'TheMailboxCantBeEmpty': 'The mailbox can\'t be empty.',
  'TheMaximumNumberOfUploadsOfLightquantifiedModelsToday': 'The maximum number of uploads of light-quantified models today has been reached.',
  'Theme': 'Theme',
  'ThemeColor': 'Theme Color',
  'ThemeColour': 'Theme Colour',
  'ThemeConfigurationList': 'Theme Configuration List',
  'ThemeSide': 'Theme Side',
  'ThemeSwitch': 'Theme Switch',
  'TheModelLimitsTheSizeOfTheFile': 'The model limits the size of the file',
  'TheModelsTimeRangedFromAFewDozenSecondsToAFewMinutes': 'The model\'s time ranged from a few dozen seconds to a few minutes.',
  'TheNameCantBeEmpty': 'The name can\'t be empty.',
  'TheNameOfTheFileIsDifferentFromThatOfBin': 'The name of the file is different from that of .bin',
  'TheNewPasswordCannotBeEmpty': 'The new password cannot be empty.',
  'TheNumberCantBeEmpty': 'The number can\'t be empty',
  'TheObjectModelConnectsToThe3dModel': 'The object model connects to the 3D model.',
  'TheOldPasswordCantBeEmpty': 'The old password can\'t be empty.',
  'TheOldVersionOfThePipeCouldNotBeEdited': 'The old version of the pipe could not be edited ',
  'TheOnlyKeyOfTheComponents': 'The only key of the components',
  'TheOnlyOneOfTheComponents': 'The only one of the components.',
  'TheParameterIsEmptyPleaseCheckTheApplicationInformation': 'The parameter is empty. Please check the application information.',
  'ThePlatform': 'The platform',
  'ThePublicLayerCanOnlyBeOpenedOnTheHomePage': 'The public layer can only be opened on the home page.',
  'ThePuzzleHasBeenLoaded': 'The puzzle has been loaded.',
  'ThereIsNoChangeInTheData': 'There is no change in the data,',
  'ThereIsNoHomePageForTheCurrentItem': 'There is no home page for the current item',
  'TheRequestFailed': 'The request failed,',
  'TheresAnAnomalyInTheSystemTryAgainLaterPlease': 'There\'s an anomaly in the system. Try again later, please.',
  'TheresAProblemWithTheServiceConfiguration': 'There\'s a problem with the service configuration.',
  'TheresNoWayToFindIt': 'There\'s no way to find it.',
  'TheSceneHasNotYetBeenInitializedPleaseWait': 'The scene has not yet been initialized. Please wait.',
  'TheSceneHasntBeenInitializedYet': 'The scene hasn\'t been initialized yet.',
  'TheSceneIsBeingDownloadedPleaseWait': 'The scene is being downloaded... please wait.',
  'TheWatchboardDrags': 'The watchboard drags.',
  'TheWatchboardSpreadsOut': 'The watchboard spreads out.',
  'Thick': 'Thick',
  'ThirtyDays': 'Thirty days.',
  'ThisEmailHasCompletedItsApplication': 'This email has completed its application',
  'ThisEmailIsAlreadyRegistered': 'This email is already registered',
  'ThisMailboxHasCompletedItsApplicationPleaseCheckThe': 'This mailbox has completed its application. Please check the mail.',
  'ThisMailboxIsRegisteredPleaseReturnToLogin': 'This mailbox is registered. Please return to login.',
  'ThisOperationCannotBeRestored': 'This operation cannot be restored.',
  'ThousandbitComma': 'Thousand-bit comma',
  'ThousandsOfSpaces': 'Thousands of Spaces',
  'Three': 'Three',
  'ThroatSlider': 'Throat Slider',
  'Thursday': 'Thursday',
  'TieItToTheCamera': 'Tie it to the camera.',
  'Tiled': 'Tiled',
  'Tilt': 'Tilt ',
  'Time': 'Time',
  'Timeconsuming': 'Time-consuming',
  'Timeout': 'Timeout',
  'TimePointDate': 'Time point date',
  'TimePointDateRange': 'Time point date range',
  'Title': 'Title',
  'TitleColour': 'Title Colour',
  'TitleDisplay': 'Title Display',
  'TitleSize': 'Title Size',
  'TitleText': 'Title Text',
  'To': 'To',
  'ToCurrentTab': 'To Current Tab',
  'Today': 'Today',
  'Toggle': 'Toggle',
  'ToggleDisplayModelType': 'Toggle display model type',
  'ToggleDisplayStatus': 'Toggle display status',
  'ToggleRunningMode': 'Toggle running mode',
  'ToggleSpaceComponentSpace': 'Toggle Space Component Space',
  'TokenValueObtained': 'Token value obtained',
  'ToNewTab': 'To New Tab',
  'Toolbar': 'Toolbar',
  'Toolbars': 'Toolbars',
  'Toolkit': 'Toolkit',
  'ToOverwriteRawRectangleData': 'To overwrite raw rectangle data',
  'Top': 'Top',
  'Top&bottomBorder': 'Top&Bottom Border',
  'Top2': 'Top',
  'Top3': 'Top',
  'Top4': 'Top',
  'Top5': 'Top',
  'TopBorder': 'Top Border',
  'TopDown': 'Top Down ',
  'TopdownMargin': 'Top-down margin',
  'TopLeft': 'Top Left',
  'TopOuterBorder': 'Top Outer Border',
  'TopRight': 'Top Right',
  'TopView': 'Top View',
  'TotalMaterial': 'Total material',
  'TotalNumberOfPolygons': 'Total number of polygons',
  'TotalTexture': 'Total Texture',
  'ToTheRight': 'To the Right ',
  'Transparency': 'Transparency',
  'Triangle': 'Triangle',
  'TriggerEvent': 'Trigger event',
  'TrinityDatabaseDataDeleteSuccessfully': 'Trinity database data delete successfully',
  'TrinityDatabaseDataWriteSuccessfully': 'Trinity database data write successfully',
  'TrinityDatabaseDataWritingFailed': 'Trinity database data writing failed',
  'TrinityDatabaseOpenSuccessfully': 'Trinity database open successfully.',
  'TrinityPuzzleUnmounted': 'Trinity puzzle unmounted',
  'TronV4': 'Tron V4',
  'TryIt': 'Try it',
  'TryMap': 'Try Map',
  'Tuesday': 'Tuesday',
  'TurnOnTheDataLink': 'Turn on the data link',
  'TurnOnTheFocus': 'Turn on the focus',
  'TurnOnTheGrid': 'Turn on the grid',
  'TurnOnThePanel': 'Turn on the panel',
  'Tutorial': 'Tutorial ',
  'TutorialVideo': 'Tutorial Video',
  'Two': 'Two',
  'Type': 'Type',
  'Type2': 'Type',
  'TypeConversion': 'Type Conversion',
  'TypeOfPumpEquipment': 'Type of pump equipment',
  'UnableToConnectToServer': 'Unable to Connect to Server',
  'UnableToLoadModule': 'Unable to load module',
  'UnableToMoveDown': 'Unable to move down',
  'UnableToObtainRelatedDataExplanationInformation': 'Unable to Obtain Related Data Explanation Information',
  'UnableToParse': 'Unable to Parse',
  'UnableToPublish': 'Unable to Publish',
  'UnableToQueryRelatedData': 'Unable to Query Related Data',
  'Undefined': 'Undefined',
  'UndefinedGlobalEvent': 'Undefined global event',
  'Underline': 'Underline',
  'UndoOperation': 'Undo Operation',
  'Ungrouped': 'Ungrouped',
  'UniInterfaceSubscriptions': 'UNI interface subscriptions',
  'Units': 'Units',
  'UnitText': 'Unit Text',
  'Unknown': 'Unknown',
  'UnknownDevice': 'Unknown device',
  'UnknownService': 'Unknown service',
  'UnloadedScene': 'Unloaded Scene',
  'UnmountPage': 'Unmount Page',
  'UnmountPageEventListening': 'Unmount page event listening',
  'Unnamed': 'Unnamed',
  'UnsavedContents': 'Unsaved Contents',
  'Unselect': 'Unselect',
  'Up': 'Up',
  'Up2': 'Up',
  'UpAndDown': 'Up and Down',
  'UpAndDownAdjustment': 'Up and down adjustment',
  'Update': 'Update',
  'UpdateComponent': 'Update Component',
  'Updated': 'Updated',
  'UpdateDataSources': 'Update Data Sources',
  'UpdateDate': 'Update Date',
  'UpdateFailed': 'Update Failed',
  'UpdateFailPleaseContactAdministrator': 'update fail, please contact administrator',
  'UpdateOfComponentSuccessfully': 'Update of component successfully',
  'UpdateSuccessful': 'Update successful',
  'UpdateTime': 'Update Time',
  'UpdatingOfThePublicWebSite': 'Updating of the public web site',
  'UpgradeServiceRequestProcessingToVersion': 'Upgrade service request processing to version',
  'Upload': 'Upload',
  'UploadDevicePointTable': 'Upload Device Point Table ',
  'UploadedByMe': 'Uploaded by me ',
  'UploadFailed': 'Upload Failed',
  'UploadFile': 'Upload File',
  'UploadingFailedFileTypeIsIncorrect': 'Uploading failed, file type is incorrect',
  'UploadPointTableData': 'Upload Point Table Data',
  'UploadSuccessful': 'Upload Successful ',
  'UpperLeft': 'Upper Left',
  'UpperMiddle': 'Upper Middle ',
  'UpperRight': 'Upper Right',
  'User': 'User',
  'UserAccountExpired': 'User account expired',
  'UserComponent': 'User Component',
  'UserContents': 'User Contents',
  'UserDoesNotExist': 'User does not exist',
  'UserGroup': 'User Group',
  'UserHasNoPrivilegePleaseContactAdministrator': 'User has no privilege, please contact administrator',
  'UserIdentity': 'User Identity',
  'UserManual': 'User Manual ',
  'UserManual2': 'User Manual',
  'Username': 'Username',
  'UserName2': 'User Name',
  'UsernameCannotBeEmpty': 'Username Cannot Be Empty',
  'UsernameOrPasswordCannotBeEmpty': 'Username or password cannot be empty',
  'UsernameOrPasswordError': 'Username or password error',
  'UserNotFound': 'User not found',
  'UserNotRegistered': 'User not registered',
  'UserOrPasswordInputError': 'User or password input error',
  'UserPermissionInsufficient': 'User permission insufficient',
  'UserSettings': 'User Settings',
  'UserSRemainingSpaceIsNotEnough': 'User \'s remaining space is not enough',
  'ValidateFail': 'Validate Fail',
  'Validator': 'Validator',
  'Value': 'Value',
  'Value2': 'Value',
  'ValueAs': 'Value as',
  'ValueData': 'Value Data',
  'ValueDisplay': 'Value Display',
  'ValueSize': 'Value Size',
  'ValueWhen': 'Value when',
  'Vendors': 'Vendors',
  'VerificationFailed': 'Verification Failed',
  'Version': 'Version',
  'VersionNumber': 'Version Number',
  'Vertical': 'Vertical',
  'Vertical2': 'Vertical',
  'Vertical3': 'Vertical',
  'VerticalAlignment': 'Vertical Alignment',
  'VerticalFlatten': 'Vertical flatten',
  'VerticalLayout': 'Vertical Layout ',
  'VerticalPosition': 'Vertical Position',
  'VerticalText': 'Vertical Text',
  'Video': 'Video',
  'VideoFiles': 'Video Files',
  'VideoLibrary': 'Video library',
  'VideoName': 'Video Name',
  'VideoPath': 'Video Path',
  'VideoSource': 'Video Source',
  'VideoTutor': 'Video Tutor',
  'VideoWall': 'Video wall',
  'View': 'View',
  'ViewDown': 'View Down',
  'Viewer': 'Viewer',
  'ViewingInTheProject': 'Viewing in the project',
  'ViewList': 'View List',
  'ViewOperations': 'View Operations',
  'ViewSceneInformation': 'View scene information',
  'ViewScenes': 'View scenes',
  'ViewVersion': 'View Version',
  'Visibility': 'Visibility',
  'VisibleSettings': 'Visible Settings',
  'Voltage': 'Voltage',
  'Volume': 'Volume',
  'VueToolkit': 'Vue Toolkit',
  'Wait': 'Wait',
  'Wall': 'Wall',
  'Waltung': 'Wal-Tung.',
  'Warm': 'Warm',
  'WarningStatus': 'Warning status',
  'Wastebin': 'Wastebin',
  'WastebinEmptiedSuccessfully': 'Wastebin emptied successfully',
  'WastebinResourceListNetworkRequestError': 'Wastebin Resource List Network Request Error',
  'WatchTheBoard': 'Watch the board.',
  'WaterTanks': 'Water tanks',
  'Weather': 'Weather',
  'WebAddress': 'Web Address',
  'WebJump': 'Web Jump',
  'WebPage': 'Web page',
  'Website': 'Website',
  'WechatLoginFailed': 'WeChat Login Failed',
  'WechatLoginFailUnknowService': 'Wechat login fail, unknow service',
  'Wednesday': 'Wednesday',
  'Week': 'Week',
  'Week2': 'Week',
  'WellKeepYouFor30Days': 'We\'ll keep you for 30 days.',
  'WellKeepYouFor30Days1': 'We\'ll keep you for 30 days.',
  'WellReserveItForYou': 'We\'ll reserve it for you.',
  'WereStartingToWatchTheEquipment': 'We\'re starting to watch the equipment.',
  'WhatAreTheParameters': 'What are the parameters?',
  'When': ',',
  'When1': 'When ',
  'WhenTheObjectModelDoesntConnectToThe3dComponent': 'When the object model doesn\'t connect to the 3D component,',
  'WhetherOrNotFullscreen': 'Whether or not fullscreen',
  'WhetherOrNotToSave': 'Whether or not to save',
  'WhetherOrNotToShowScrollbars': 'Whether or not to show scrollbars',
  'WhetherToDeleteThisFile': 'Whether to delete this file',
  'WhetherToShowToolbars': 'Whether to show toolbars',
  'White': 'White',
  'White2': 'White',
  'WhiteLight': 'White light',
  'Width': 'Width',
  'WidthCanChange': 'Width can change',
  'WidthFill': 'Width Fill',
  'Window': 'Window',
  'WindSpeed': 'Wind speed',
  'Within': 'Within',
  'WordSpacing': 'Word Spacing',
  'WordWeights': 'Word Weights',
  'Workorder': 'Workorder',
  'WorkorderInformation': 'Workorder Information',
  'WorkorderName': 'Workorder Name',
  'Wrap': 'Wrap',
  'WrongNumber': 'Wrong number',
  'Year': 'Year',
  'Yellow': 'Yellow',
  'Yellow01': 'Yellow 01',
  'Yellow02': 'Yellow 02',
  'Yellow03': 'Yellow 03',
  'Yellow04': 'Yellow 04',
  'Yellow05': 'Yellow 05',
  'Yes': 'Yes',
  'YouAlreadyLogOutOfTheSystem': 'You already log out of the system,',
  'YouAreAlreadyOnTheSystem': 'You are already on the system.',
  'YouCanOnlyUploadIt': 'You can only upload it.',
  'YouHaveBeenLoggedOut': 'You have been logged out. ',
  'YouHaveNotObtainedAProductAuthorization': 'You have not obtained a product authorization',
  'YouHaventOperatedForTooLong': 'You haven\'t operated for too long,',
  'YouHaventOperatedForTooLong1': 'You haven\'t operated for too long.',
  'YouHaveToChooseAProject': 'You have to choose a project.',
  'YourAccountHasBeenEnteredElsewhere': 'Your account has been entered elsewhere.',
  'YourAccountHasBeenEnteredElsewhereAndYouAreForcedOffline': 'Your account has been entered elsewhere, and you are forced offline.',
  'YourAccountHasBeenEnteredInAnotherDevice': 'Your account has been entered in another device',
  'YourAccountHasBeenEnteredInAnotherDeviceAndYouAreForced': 'Your account has been entered in another device and you are forced offline.',
  'Zoom': 'Zoom',
  'ZoomIn': 'Zoom In',
  'ZoomOut': 'Zoom Out',
  '[Script]': '[ Script ]',
  'LanguageSwitching':'Language switching',
  'Context':'Context',
  'MousePenetration':'Mouse penetration',
  'CannotExitLogin':'Cannot Exit Login',
  'DevicePointTable': 'Device Point Table',
  'Add1': 'Add',
  'Programme': 'Programme',
  'ProgrammeList': 'Programme List',
  'CreateProgramme': 'Create Programme',
  'ImportProgramme': 'Import Programme',
  'UnbindObjectModel': 'Unbind Object Model',
  'DataEnumerationConversion':'Data Enumeration Conversion',
  'Furniture':'Furniture',
  'AreYouSureYouWantToSwitchLanguagesAndReload': 'Are you sure you want to switch languages and reload',
  'DialogList': 'Dialog List',
  'AddDialog': 'Add Dialog',
  'DialogCreatedSuccessfully': 'Dialog Created Successfully',
  'DialogCopySuccess': 'Dialog Copy Successful',
  'DialogCopyFailed':'Dialog Copy Failed',
  'EditDialog': 'Edit Dialog',
  'Modal':'Modal',
  'IsDraggable':'Is Draggable',
  'Hyperlink':'Hyperlink',
  'ProgressBar':'Progress Bar',
  'ProgressLoop':'Progress Loop',
  'ApplicationPointTableDeviceName':'Application Point Table Device Name',
  'Connector':'Connector',
  'PanelEvent':'Panel Event',
  'PanelSelect':'Panel Select',
  'IsConnector':'Is Connector',
  'D1': 'D1',
  'D7': 'D7',
  'D30': 'D30',
  'Configuration':'Configuration',
  'Artist':'Artist',
  'CreateSpatialAssets':'Create Spatial Assets',
  'CreateConfigurationAssets':'Create Configuration Assets',
  'ClericalScript':'Clerical Script',
  'MidWind': 'Middle Wind',
  'Chart':'Chart',
  'CouldNotUpload': 'Could not upload',
  'SpaceComponentSwitchingFloors':'Space Component Switching Floors',
  'NextFloor':'Next Floor',
  'PreFloor':'Pre Floor',
  'ArtistScene':'Artist Scene',
  'ImportSuccess':'Import Success',
  'ImportError':'Import Error',
  'EnglishName':'English Name',
  'AreYouSureWantToEmptyTheObjectModel':'Are you sure want to empty the object model?',
  'BlueprintMappingSuccessful':'Blueprint Mapping Successful',
  'TheBlueprintForTheObjectModelAlreadyExists':'The blueprint for the object model already exists',
  'PlanName':'Plan Name',
  'UnbindObjectModelSuccessfully':'Unbind Object Model Successfully',
  'AreYouSureToUseThisPlan':'Are you sure to use this plan?',
  'OperationWillResultInThisObjectModelBeingOverwrittenByTheSelectedScheme':'Operation will result in this object model being overwritten by the selected scheme',
  'FillInTheName':'Fill in the name',
  'Canceled':'Canceled',
  'Use':'Use',
  'ConfirmTheDeletion': 'Confirm the deletion',
  'DragFilesHere': 'Drag files here',
  'OrClickToSelect':'Or click to select',
  "ThePlanDoesNotExist":'The plan does not exist',
  'ExportSuccess':'Export Success',
  'ExportError':'Export Error',
  'IAdded':'I Added',
  'ImportTheme':'Import Theme',
  'AddSuccess':'Add Success',
  'UploadTime':'Upload Time',
  'ThemeType':'Theme Type',
  'ThemeUploaded':'Theme Uploaded',
  'ThemeNotUploaded':'Theme Not Uploaded',
  'Batch':'Batch',
  'Selected1':'Selected',
  'NotSelected1':'Not Selected',
  'SpaceComponent':'Space Component',
  'ExportPointTableData':'Export PointTable Data',
  'ExportSpaceData':'Export Space Data',
  'TronV5': 'Tron V5',
  'ConfigurationComponent':'Configuration Component',
  'shoppingCart':'shopping Cart',
  'DownloadAll': 'Download All',
  'AllParts':'All Parts',
  'Latest':'Latest',
  'DownloadNumber':'Download Number',
  'Invert':'Invert',
  'PleaseDoNotAddAgain':'Please Do Not Add Again',
  'AreYouSureToClearTheShoppingCart':'Are You Sure To Clear The ShoppingCart?',
  'AddToLibrary':'Add To Library',
  'Step1':'Step 1',
  'Step2':'Step 2',
  'Step3':'Step 3',
  'Step4':'Step 4',
  'Step5':'Step 5',
  'Step6':'Step 6',
  'SelectTheSpaceComponentsYouNeed':'Select The Space Components You Need',
  'SelectTheConfigurationComponentsYouNeed':'Select The Configuration Components You Need',
  'Tip':'Tip',
  'TheSpatialComponentIsAComponentUsedUnderTheSpatialModule,WhichHasRealProportionalRelationshipsAndDataStateChanges,MeetingYourSpatialDataDisplayNeeds':'The Spatial Component Is A Component Used Under The Spatial Module, Which Has Real Proportional Relationships And DataState Changes, Meeting Your Spatial Data Display Needs',
  'ClickOnTheCategoryListToFilterTheRequiredComponents':'Click On The Category List To Filter The Required Components',
  'TheClassificationListContainsRelevantComponentsForDifferentIndustries,Categories,AndPurposesClickingOnThemWillLoadTheCorrespondingContentOfTheClassificationInTheRightHandView':'The Classification List Contains Relevant Components For Different Industries, Categories, And Purposes Clicking On Them Will Load The Corresponding Content Of The Classification In The Right Hand View',
  'SelectTheCorrespondingThemeToRenderTheSceneApplicationUnderTheCorrespondingTheme':'Select The Corresponding Theme To Render The Scene Application Under The Corresponding Theme',
  'ClickOnTheCategorylistToFilterTheRequiredComponents':'Click On The Category list To Filter The Required Components',
  'ChooseTheThemeCategoryYouNeed':'Choose The Theme Category You Need',
  'AddComponentsToShoppingCart':'Add Components To ShoppingCart',
  'AddToLibraryOrDownloadToLocal':'Add To Library Or Download To Local',
  'TheComponentsInTheShoppingCartCanBeAddedToTheLibraryOfTheOnlineVersionOfGongchuang,OrImportedIntoTheLocalVersionOfGongchuangByDownloadingThemLocally':'The Components In The Shopping Cart Can Be Added To The Library Of The Online Version Of Gongchuang,Or Imported Into The Local Version Of Gongchuang By Downloading Them Locally',
  'ClickTheAddButtonOnTheComponentToAddTheSelectedComponentToTheShoppingCart,AndClickTheBatchButtonInTheUpperRightCornerToBatchAddItToTheShoppingCart':'Click The Add Button On The Component To Add The Selected Component To The ShoppingCart, And Click The Batch Button In The Upper Right Corner To Batch Add It To The Shopping Cart',
  'TheConfigurationComponentIsADeviceComponentInTheEquipmentSystemDiagram,SuitableForCreating3DConfigurationEquipmentSystemDiagramScenes,WithAppropriateSizeRatiosAndRichStateParametersForTheScreen':'The Configuration Component Is A Device Component In The Equipment System Diagram,Suitable For Creating 3D Configuration Equipment System Diagram Scenes, With AppropriateSize Ratios And Rich State Parameters For The Screen',
  'Skip':'Skip',
  'CouldNotAdd': 'Could Not Add',
};
export default en;
